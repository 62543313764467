import {Subject} from 'rxjs';
import {filter, map} from 'rxjs/operators';

const KEY = {
  LOGIN_STATE_CHANGED: 'LOGIN_STATE_CHANGED',
  SNACKBAR: 'SNACKBAR'
};

class EventBus {

  constructor() {
    this.subject = new Subject();
  }

  getKey() {
    return KEY;
  }

  subscribe(key, action) {
    return this.subject
      .pipe(filter(event => event.key === key))
      .pipe(map(event => event.value))
      .subscribe(action);
  }

  post(event) {
    this.subject.next(event);
  }

}


let _instance = new EventBus();

export default _instance;


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import TextField from '@material-ui/core/TextField';

class UsernameLoginDialog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {username: '', password: ''};
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('username_dialog_title')}</DialogTitle>
          <DialogContent>
            <div>
              <form noValidate autoComplete="off">
                <div style={styles.fieldsContainer}>
                  <TextField style={styles.inputField} id="username" label={t('username_dialog_username')}
                             value={this.state.username}
                             onChange={(event) => {
                               this.setState({username: event.target.value});
                             }}/>
                  <TextField style={styles.inputField} id="password" label={t('username_dialog_password')}
                             value={this.state.password} type={'password'}
                             onChange={(event) => {
                               this.setState({password: event.target.value});
                             }}/>
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel.bind(this)} color="primary" autoFocus>
              {t('username_dialog_cancel')}
            </Button>
            <Button onClick={this.onConfirm.bind(this)} color="primary" autoFocus>
              {t('username_dialog_confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  onCancel() {
    this.setState({username: '', password: ''});
    this.props.onCancel();
  }

  onConfirm() {
    this.props.onConfirm(this.state.username, this.state.password);
  }

}

const styles = {
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputField: {
    marginBottom: 15,
    maxWidth: 400,
    width: '70vw',
  }
};

export default withTranslation()(UsernameLoginDialog);
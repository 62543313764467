import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class GeneralDialog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {t} = this.props;
    return (
      <Dialog open={this.props.isShowDialog || false}>
        <DialogTitle>{this.props.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div dangerouslySetInnerHTML={{__html: this.props.dialogContent}}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.renderCancelButton()}
          <Button onClick={() => {
            if (this.props.onClick) {
              this.props.onClick();
            }
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderCancelButton() {
    if (!this.props.onCancelClick) {
      return;
    }
    const {t} = this.props;
    return (
      <Button onClick={() => {
        if (this.props.onCancelClick) {
          this.props.onCancelClick();
        }
      }} color="primary" autoFocus>
        {t('general_cancel')}
      </Button>
    );
  }

}

const styles = {};

export default withTranslation()(GeneralDialog);
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import TosEn from '../res/terms/TosEn';
import TosTc from '../res/terms/TosTc';
import PrivacyEn from '../res/terms/PrivacyEn';
import PrivacyTc from '../res/terms/PrivacyTc';

class TermsDialog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.getTitle()}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div dangerouslySetInnerHTML={{__html: this.getTermsContent()}}/>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onConfirm} color="primary" autoFocus>
              {this.getConfirm()}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  getTitle() {
    if (this.props.isPrivacy) {
      return this.props.t('privacy_title');
    } else {
      return this.props.t('terms_title');
    }
  }

  getConfirm() {
    if (this.props.isPrivacy) {
      return this.props.t('privacy_ok');
    } else {
      return this.props.t('terms_ok');
    }
  }

  getTermsContent() {
    if (this.props.isPrivacy) {
      let file = this.props.t('privacy_file');
      if (file === 'PrivacyEn') {
        return PrivacyEn.getContent();
      } else if (file === 'PrivacyTc') {
        return PrivacyTc.getContent();
      }
    } else {
      let file = this.props.t('terms_file');
      if (file === 'TosEn') {
        return TosEn.getContent();
      } else if (file === 'TosTc') {
        return TosTc.getContent();
      }
    }
  }

}

export default withTranslation()(TermsDialog);
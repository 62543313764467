export default {
  translation: {
    'login_app_name': 'EventX',
    'login_app_name_public': 'GoGoEasy',
    'login_title': '活動管理系統',
    'login_register_login': '登錄 / 註冊',
    'login_or': '或',
    'login_username': '用戶名稱',
    'login_t_and_c': '服務條款',
    'login_privacy': '隱私政策',
    'login_error_username_password': '登錄失敗，請檢查您的用戶名稱和密碼',
    'login_error_try_again': '登錄失敗，請重試',
    'login_register': '使用電郵註冊',

    'terms_title': '服務條款',
    'terms_ok': '確定',
    'terms_file': 'TosTc',
    'privacy_title': '隱私政策',
    'privacy_ok': '確定',
    'privacy_file': 'PrivacyTc',

    'lang_code_en': '英文',
    'lang_code_zh': '中文',
    'lang_code_ja': '日文',
    'lang_code_ko': '韓文',
    'lang_code_fr': '法文',
    'lang_code_de': '德文',
    'lang_code_local': '當地語言',

    'general_input_please_select': '請選擇',
    'general_ok': '確定',
    'general_cancel': '取消',
    'general_error': '錯誤',
    'general_loading': '正在加載...請稍候',
    'general_add_item_fail': '添加項目失敗，請重試',
    'general_edit_item_fail': '修改項目失敗，請重試',
    'general_operation_success': '操作成功',
    'general_operation_fail': '操作失敗，請重試',
    'general_clear': '清除',

    'username_dialog_title': '登錄',
    'username_dialog_username': '用戶名稱',
    'username_dialog_password': '密碼',
    'username_dialog_cancel': '取消',
    'username_dialog_confirm': '確認',

    'register_dialog_email': '電子郵件',
    'register_dialog_password': '密碼',
    'register_dialog_password_confirm': '確認密碼',
    'register_dialog_cancel': '取消',
    'register_dialog_register': '註冊',

    'home_title': '主頁',
    'home_event_management_system': '活動管理系統',
    'home_super_admin_functions': '超級管理員功能',
    'home_item_new_event': '新增活動 (正常版)',
    'home_item_view_my_event': '查看我的活動 (正常版)',
    'home_item_view_category': '查看/編輯類別',
    'home_item_view_provinces': '查看/編輯省份',
    'home_item_view_area': '查看/編輯區域',
    'home_item_approve_event': '審批活動 (正常版)',
    'home_item_view_all_events': '查看所有活動 (正常版)',
    'home_item_new_ads': '新增活動 (簡易版)',
    'home_item_view_my_ads': '查看我的活動 (簡易版)',
    'home_item_approve_ads': '審批活動 (簡易版)',
    'home_logout': '登出',
    'home_welcome':'歡迎，',
    'home_register_email': '設定/更新電子郵件以接收審批通知',
    'home_email': '電子郵件',
    'home_email_empty': '未設定',
    'home_email_invalid': '電子郵件格式不正確',
    'home_item_new_event_desc': '*輸入活動的資料比較詳盡\n*活動受"智能行程推薦"系統所支援',
    'home_item_new_ads_desc': '*活動的資料比較簡單\n*活動不受"智能行程推薦"系統所支援',

    'admin_area_title': '查看/編輯區域',
    'admin_area_current': '現有區域',
    'admin_area_table_name': '名稱',
    'admin_area_table_country': '國家 / 地區',
    'admin_area_table_coordinate': '坐標',
    'admin_area_table_action': '動作',
    'admin_area_table_action_edit': '編輯',
    'admin_area_add_new': '新增',

    'admin_add_area_title': '新增區域',
    'admin_add_area_edit_title': '編輯區域',
    'admin_add_area_name': '名稱',
    'admin_add_area_country': '國家 / 地區',
    'admin_add_area_lat_lng': '區域位置經緯度',
    'admin_add_area_lat': '緯度',
    'admin_add_area_lng': '經度',
    'admin_add_area_get_lat_lng': '自動從位置獲取經緯度...',
    'admin_add_area_submit': '提交',
    'admin_add_area_missing_name': '請輸入至少一個名字',
    'admin_add_area_missing_country': '請選擇國家 / 地區',
    'admin_add_area_missing_lat_lng': '請輸入經緯度',

    'admin_province_title': '查看/編輯省份',
    'admin_province_current': '現有省份',
    'admin_province_table_name': '名稱',
    'admin_province_table_country': '國家 / 地區',
    'admin_province_table_area': '區域',
    'admin_province_table_action': '動作',
    'admin_province_table_action_edit': '編輯',
    'admin_province_table_action_delete': '刪除',
    'admin_province_add_new': '新增',

    'admin_add_province_title': '新增省份',
    'admin_add_province_edit_title': '編輯省份',
    'admin_add_province_name': '名稱',
    'admin_add_province_country': '國家 / 地區',
    'admin_add_province_area': '區域',
    'admin_add_province_submit': '提交',
    'admin_add_province_missing_name': '請輸入至少一個名字',
    'admin_add_province_missing_country': '請選擇國家 / 地區',
    'admin_add_province_missing_area': '請選擇區域',

    'admin_category_title': '查看/編輯類別',
    'admin_category_current': '現有類別',
    'admin_category_table_name': '名稱',
    'admin_category_table_icon': '圖片',
    'admin_category_table_order': '排序',
    'admin_category_table_action': '動作',
    'admin_category_table_action_edit': '編輯',
    'admin_category_add_new': '新增',

    'admin_add_category_title': '新增類別',
    'admin_add_category_edit_title': '修改類別',
    'admin_add_category_name': '名稱',
    'admin_add_category_order': '排序',
    'admin_add_category_icon': '圖片',
    'admin_add_category_icon_upload': '上載圖片',
    'admin_add_category_submit': '提交',
    'admin_add_category_missing_name': '請輸入至少一個名字',
    'admin_add_category_missing_order': '請輸入排序',
    'admin_add_category_missing_icon': '請輸入圖片',

    'add_ads_title': '新增活動 (簡易版)',
    'add_ads_input_info': '請輸入活動資料',
    'add_ads_ads_title': '活動標題 (最多100字)',
    'add_ads_link': '活動連結',
    'add_ads_link_supported': '支持的網站',
    'add_ads_image': '活動圖片',
    'add_ads_description': '宣傳文字（選填，最多500字）',
    'add_ads_location_desc': '活動將在以下國家/地區展示',
    'add_ads_location_country': '活動國家/地區',
    'add_ads_image_upload': '上載活動圖片',
    'add_ads_submit': '提交',
    'add_ads_missing_title': '請輸入活動標題',
    'add_ads_missing_link': '請輸入網站連結',
    'add_ads_missing_image': '請輸入圖片',
    'add_ads_missing_country': '請輸入國家/地區',
    'add_ads_invalid_link': '請輸入有效網站連結',

    'add_event_title': '新增活動',
    'add_event_edit_title': '編輯活動',
    'add_event_check_exist_title': '檢查活動是否已經存在',
    'add_event_check_exist_name': '名稱（任何語言）',
    'add_event_check_button': '檢查',
    'add_event_step_name': '名稱',
    'add_event_step_location': '位置',
    'add_event_step_date_info': '日期資料',
    'add_event_step_content_info': '內容資料',
    'add_event_name': '名稱',
    'add_event_name_title': '請輸入至少一個活動 / 地點名稱',
    'add_event_next_step': '下一步',
    'add_event_prev_step': '上一步',
    'add_event_finish_submit': '完成並提交',
    'add_event_name_too_short': '輸入名稱太短，中文，日文和韓文至少2個字符，其他語言至少4個字符',
    'add_event_no_similar_event_dialog_title': '找不到類似活動',
    'add_event_no_similar_event_dialog_desc': '很好！ 您可以繼續添加此活動',
    'add_event_similar_event_dialog_title': '發現類似活動',
    'add_event_similar_event_dialog_desc': '我們發現以下現有的類似活動，請不要將同一活動提交給系統。<br/>如果您認為自己的活動與下面列出的活動不同，則可以繼續進行操作。',
    'add_event_similar_event_dialog_continue': '繼續',
    'add_event_similar_event_dialog_cancel': '取消',
    'add_event_start_location_address': '起始位置地址',
    'add_event_end_location_address': '結束位置地址',
    'add_event_start_location_address_lat_lng': '開始位置緯度和經度',
    'add_event_end_location_address_lat_lng': '結束位置緯度和經度',
    'add_event_location_latitude': '緯度',
    'add_event_location_longitude': '經度',
    'add_event_address_lat_lng_auto_get': '自動獲取經緯度...',
    'add_event_end_location_same': '與起始相同',
    'add_event_address_missing_name': '請輸入所有地址',
    'add_event_address_too_short': '地址太短，至少5個字符',
    'add_event_address_missing_lat_lng': '請輸入經緯度',
    'add_event_location_missing_province': '請選擇國家/地區，地區和省份',
    'add_event_info_indoor_or_outdoor': '室內或室外活動',
    'add_event_info_indoor': '室內',
    'add_event_info_outdoor': '戶外',
    'add_event_info_include_meal': '活動地點是否提供餐點（活動中包括餐廳或餐點）',
    'add_event_info_no_meal': '沒有提供',
    'add_event_info_meal_breakfast': '提供早餐',
    'add_event_info_meal_lunch': '提供午餐',
    'add_event_info_meal_dinner': '提供晚餐',
    'add_event_info_meal_lunch_dinner': '提供午餐和晚餐',
    'add_event_info_meal_breakfast_lunch': '提供早餐和午餐',
    'add_event_info_meal_breakfast_dinner': '提供早餐和晚餐',
    'add_event_info_meal_breakfast_lunch_dinner': '提供早餐，午餐和晚餐',
    'add_event_info_min_stay_time': '最短停留時間',
    'add_event_info_max_stay_time': '最長停留時間',
    'add_event_info_min_stay_time_na': '沒有最短停留時間',
    'add_event_info_max_stay_time_na': '沒有最長停留時間',
    'add_event_info_event_start_time': '活動開始時間（當地時間，24小時制）',
    'add_event_info_event_end_time': '活動結束時間（當地時間，24小時制）',
    'add_event_info_event_start_time_na': '沒有特定的開始時間',
    'add_event_info_event_end_time_na': '沒有特定的結束時間',
    'add_event_info_category': '類別（可以多選）',
    'add_event_info_event_start_date': '活動開始日期（當地時間，如果不適用，請留空）',
    'add_event_info_event_end_date': '活動結束日期（當地時間，如果不適用，請留空）',
    'add_event_info_stamina_factor': '活動體能需求',
    'add_event_info_stamina_large': '大',
    'add_event_info_stamina_medium': '中',
    'add_event_info_stamina_small': '小',
    'add_event_info_event_season': '活動季節',
    'add_event_info_event_season_na': '沒有特定的季節',
    'add_event_info_event_season_spring': '春季',
    'add_event_info_event_season_summer': '夏季',
    'add_event_info_event_season_autumn': '秋季',
    'add_event_info_event_season_winter': '冬季',
    'add_event_info_event_season_spring_summer': '春季和夏季',
    'add_event_info_event_season_summer_autumn': '夏季和秋季',
    'add_event_info_event_season_autumn_winter': '秋季和冬季',
    'add_event_info_event_season_winter_spring': '冬季和春季',
    'add_event_info_event_photo': '活動照片（png / jpg / jpeg），最多5張照片',
    'add_event_info_wiki': 'Wikipedia網頁連結（可以是任何語言，如果不適用，請留空）',
    'add_event_info_instagram_post': 'Instagram 貼文連結（單一貼文連結，不是帳戶連結，如有提供會取代照片顯示，如果不適用，請留空）',
    'add_event_info_event_desc': '活動說明（例如介紹，票務等，如果不適用，請留空）',
    'add_event_info_url_link': '參考網站（如果不適用，請留空，最多三個）',
    'add_event_info_url_link_title': '參考網站標題',
    'add_event_info_url_link_url': '參考網站網址',
    'add_event_location_province': '活動省份',
    'add_event_location_area': '活動區域',
    'add_event_location_country': '活動國家 / 地區',
    'add_event_time_hour': '小時',
    'add_event_time_hours': '小時',
    'add_event_stay_time_missing': '請選擇停留時間',
    'add_event_event_date_missing': '請選擇活動時間',
    'add_event_drop_files': '拖放或單擊以選擇文件',
    'add_event_drop_added_files': '已新增照片',
    'add_event_drop_clear_files': '清除所有照片',
    'add_event_item_missing': '請填寫所有必填項',
    'add_event_photo_missing': '請添加照片',
    'add_event_wiki_wrong_format': '請輸入有效的維基百科網址',
    'add_event_instagram_post_wrong_format': '請輸入有效的Instagram網址',
    'add_event_intro_dialog_title': '新增活動',
    'add_event_intro_dialog_desc': '請準備以下資料：<br/>-活動名稱和地址<br/>-活動照片（至少一張）',
    'add_event_save_success_title': '保存活動完成',
    'add_event_save_success_desc': '添加新活動成功<br/>新活動正在等待審批<br/>請稍後回來查看審批狀態',
    'add_event_save_success_edit_desc': '編輯活動成功',
    'add_event_save_fail': '保存活動失敗，請重試，確保您的互聯網連接良好',
    'add_event_info_min_time_larger_than_max_time': '最小時間不能大於最大時間',
    'add_event_info_start_time_larger_than_end_time': '開始時間不能大於結束時間',
    'add_event_info_start_date_larger_than_end_date': '開始日期不能大於結束日期',
    'add_event_link_missing_info': '參考網站請輸入標題和網址',
    'add_event_link_invalid': '無效的參考網站網址，網址必須是 http:// 或 https:// 開頭',

    'view_my_event_title': '查看我的活動',
    'view_my_event_approved': '已審批的活動',
    'view_my_event_pending': '待審批的活動',
    'view_my_event_add_new': '新增',
    'view_my_event_table_action': '動作',
    'view_my_event_table_action_edit': '編輯',
    'view_my_event_table_action_delete': '刪除',
    'view_my_event_table_action_preview': '預覽',
    'view_my_event_delete_title': '刪除活動',
    'view_my_event_delete_desc': '確認刪除此活動？',
    'view_my_event_edit_disapprove_warning': '編輯此活動將需要管理員再次審批',
    'view_my_event_disapprove_reason': '審批者訊息',

    'view_my_ads_title': '查看我的活動 (簡易版)',
    'view_my_ads_approved': '已審批的活動',
    'view_my_ads_pending': '待審批的活動',
    'view_my_ads_add_new': '新增',
    'view_my_ads_table_action': '動作',
    'view_my_ads_table_action_edit': '編輯',
    'view_my_ads_table_action_delete': '刪除',
    'view_my_ads_table_action_preview': '預覽',
    'view_my_ads_delete_title': '刪除活動',
    'view_my_ads_delete_desc': '確認刪除此活動？',
    'view_my_ads_disapprove_reason': '審批者訊息',
    'view_my_ads_expiry_date': '到期日',

    'admin_approve_event_title': '審批活動',
    'admin_approve_event_pending': '待審批的活動',
    'admin_approve_event_table_action_preview': '預覽',
    'admin_approve_event_table_action': '動作',
    'admin_approve_event_table_action_edit': '編輯',
    'admin_approve_event_table_action_delete': '刪除',
    'admin_approve_event_table_submitted_by': '提交者',
    'admin_approve_event_table_create_date': '建立日期',
    'admin_approve_event_delete_title': '刪除活動',
    'admin_approve_event_delete_desc': '確認刪除此活動？',
    'admin_approve_event_filter_user_id': '按用戶ID過濾',
    'admin_approve_event_filter': '過濾',
    'admin_approve_event_filter_count': '該用戶的待審批總數: ',
    'admin_approve_event_filter_count_approved': '已審批總數: ',
    'admin_approve_event_disapprove_reason': '審批者訊息',
    'admin_approve_event_only_self_publisher': '只顯示自助發佈活動',

    'admin_approve_ads_title': '審批活動 (簡易版)',
    'admin_approve_ads_pending': '待審批的活動',
    'admin_approve_ads_table_action_preview': '預覽',
    'admin_approve_ads_table_action': '動作',
    'admin_approve_ads_table_action_edit': '編輯',
    'admin_approve_ads_table_action_delete': '刪除',
    'admin_approve_ads_table_submitted_by': '提交者',
    'admin_approve_ads_table_create_date': '建立日期',
    'admin_approve_ads_delete_title': '刪除活動',
    'admin_approve_ads_delete_desc': '確認刪除此活動？',
    'admin_approve_ads_filter_user_id': '按用戶ID過濾',
    'admin_approve_ads_filter': '過濾',
    'admin_approve_ads_filter_count': '該用戶的待審批總數: ',
    'admin_approve_ads_filter_count_approved': '已審批總數: ',
    'admin_approve_ads_disapprove_reason': '審批者訊息',
    'admin_approve_ads_only_self_publisher': '只顯示自助發佈活動',

    'event_preview_start_time': '開始時間',
    'event_preview_end_time': '結束時間',
    'event_preview_max_stay_time': '最長停留時間',
    'event_preview_min_stay_time': '最短停留時間',
    'event_preview_hours': '小時',
    'event_preview_indoor': '室內活動',
    'event_preview_outdoor': '戶外活動',
    'event_preview_start_date': '開始日期',
    'event_preview_end_date': '結束日期',
    'event_preview_energy': '體能',
    'event_preview_energy_large': '大',
    'event_preview_energy_medium': '中',
    'event_preview_energy_small': '小',
    'event_preview_season': '季節',
    'event_preview_close': '關閉',
    'event_preview_approve': '批准',
    'event_preview_disapprove': '不批准',
    'event_preview_disapprove_reason': '不批准理由',
    'event_preview_start_location':'起始位置',
    'event_preview_end_location':'結束位置',

    'ads_preview_close': '關閉',
    'ads_preview_approve': '批准',
    'ads_preview_disapprove': '不批准',
    'ads_preview_disapprove_reason': '不批准理由',

    'admin_event_title': '查看所有活動',
    'admin_event_current': '現有活動',
    'admin_event_add_new': '新增'
  }
};
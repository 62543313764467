class TosEn {

  getContent() {
    return `<h2>
	Terms of Service
</h2>

<h3>
	1. Terms
</h3>

<p>
	By accessing this application, you are agreeing to be bound by these 
	application Terms of Service, all applicable laws and regulations, 
	and agree that you are responsible for compliance with any applicable local 
	laws. If you do not agree with any of these terms, you are prohibited from 
	using or accessing this app. The materials contained in this application are 
	protected by applicable copyright and trade mark law.
</p>

<h3>
	2. Use License
</h3>

<ol type="a">
	<li>
		You may not do any of the following while accessing or using the application:
		
		<ol type="i">
			<li>copy the application(except for backup purposes);</li>
			<li>modify the application;</li>
			<li>attempt to decompile or reverse engineer the application;</li>
			<li>remove any copyright or other proprietary notations from the application; or</li>
			<li>transfer the application to another person or "mirror" the application on any other server.</li>
		</ol>
	</li>
	<li>
		This license shall automatically terminate if you violate any of these restrictions and may be terminated by This Website at any time. Upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.
	</li>
</ol>

<h3>
	3. Disclaimer
</h3>

<ol type="a">
	<li>
		The materials in This Website's application are provided "as is". This Website makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties, including without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, This Website does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials in the application or otherwise relating to such materials or on any sites linked to this app.
	</li>
</ol>

<h3>
	4. Limitations
</h3>

<p>
	In no event shall This Website or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use This Website's application, even if This Website or a This Website authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
</p>
			
<h3>
	5. Revisions and Errata
</h3>

<p>
	The materials appearing on This Website's application could include technical, typographical, or photographic errors. This Website does not warrant that any of the materials on its application are accurate, complete, or current. This Website may make changes to the materials contained on its application at any time without notice. This Website does not, however, make any commitment to update the materials.
</p>

<h3>
	6. Links
</h3>

<p>
	This Website has not reviewed all of the sites linked to This Website's application and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by This Website of the site. Use of any such linked web site is at the user's own risk.
</p>

<h3>
	7. Terms of Service Modifications
</h3>

<p>
	This Website may revise these Terms of Service for its application at any time without notice. By using this application you are agreeing to be bound by the then current version of these Terms of Service.
</p>

<h3>
	8. Governing Law
</h3>

<p>
	Any claim relating to This Website's application shall be governed by the laws of the Hong Kong Special Administrative Region without regard to its conflict of law provisions.
</p>
`;
  }

}

let _instance = new TosEn();

export default _instance;

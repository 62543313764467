import Axios from 'axios';
import AppLog from './AppLog';
import ParseUtil from './ParseUtil';

const FACEBOOK_APP_ID_DEV = '645474779695117';
const FACEBOOK_APP_ID_PROD = '284207808799888';

// always using prod login now
const FACEBOOK_APP_ID = FACEBOOK_APP_ID_PROD;

const GOOGLE_CLIENT_ID = '760463852521-cnn4v11lj19r5m17g485ickp6a4cg343.apps.googleusercontent.com';

const USER_LINK_SERVICE = {
  facebook: 'facebook',
  google: 'google',
  apple: 'apple'
};

class AccountUtil {

  initGoogleSdk() {
    AppLog.log("Loading google api");

    window.googleAsyncInit = function () {
      // eslint-disable-next-line no-undef
      gapi.load('auth2', function () {
        // eslint-disable-next-line no-undef
        gapi.auth2.init();
      });
    };

    (function (document, script, id) {
      var js, fjs = document.getElementsByTagName(script)[0];
      if (document.getElementById(id)) return;
      js = document.createElement(script);
      js.id = id;
      js.name = "google-signin-client_id";
      js.content = GOOGLE_CLIENT_ID;
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'meta', 'google-jssdk-meta'));

    (function (document, script, id) {
      var js, fjs = document.getElementsByTagName(script)[0];
      if (document.getElementById(id)) return;
      js = document.createElement(script);
      js.id = id;
      js.src = "//apis.google.com/js/platform.js?onload=googleAsyncInit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));

  }

  initFacebookSdk() {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: FACEBOOK_APP_ID,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v8.0'
      });
    };

    AppLog.log("Loading facebook api");
    // Load the SDK asynchronously
    (function (document, script, id) {
      var js, fjs = document.getElementsByTagName(script)[0];
      if (document.getElementById(id)) return;
      js = document.createElement(script);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  async loginToParseByGoogle() {
    let googleUser = await this._loginGoogle();
    let authResponse = googleUser.getAuthResponse(true);
    let displayName = googleUser.getBasicProfile().getName();
    let base64Pic = await this._getBase64ImageFromUrl(googleUser.getBasicProfile().getImageUrl());

    let authData = {
      'id': googleUser.getId(),
      'access_token': authResponse.access_token,
      'expiration_date': new Date(authResponse.expires_at).toISOString()
    };
    AppLog.log(authData);
    let response = await this._loginToParse(USER_LINK_SERVICE.google, authData, displayName, base64Pic);
    return response;
  }

  async _loginGoogle() {
    // eslint-disable-next-line no-undef
    let auth2 = gapi.auth2.getAuthInstance();
    let response = await auth2.signIn();
    AppLog.log(response);
    return response;
  }

  async loginToParseByFacebook() {
    let loginResponse = await this._loginFacebook();
    let meResponse = await this._getFacebookMe();
    let picResponse = await this._getFacebookProfilePic(meResponse.id);
    let picUrl = picResponse.data?.url;
    let base64Pic = await this._getBase64ImageFromUrl(picUrl);
    let displayName = meResponse.name;

    let authData = {
      'id': loginResponse.authResponse.userID,
      'access_token': loginResponse.authResponse.accessToken,
      'expiration_date': new Date(loginResponse.authResponse.data_access_expiration_time * 1000).toISOString()
    };
    AppLog.log(authData);
    let response = await this._loginToParse(USER_LINK_SERVICE.facebook, authData, displayName, base64Pic);
    return response;
  }

  _loginFacebook() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      FB.login((response) => {
        if (response.authResponse && !response.error) {
          AppLog.log(response);
          resolve(response);
        } else {
          reject('User cancelled Facebook login or did not fully authorize.');
        }
      }, {scope: 'public_profile', auth_type: 'rerequest'});
    });
  }

  _getFacebookMe() {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      FB.api('/me', (response) => {
        AppLog.log(response);
        if (!response.error) {
          resolve(response);
        } else {
          reject('Get /me error');
        }
      });
    });
  }

  _getFacebookProfilePic(userId) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      FB.api(`/${userId}/picture?type=large&redirect=0`, (response) => {
        AppLog.log(response);
        if (!response.error) {
          resolve(response);
        } else {
          reject('Get /picture error');
        }
      });
    });
  }

  async _getBase64ImageFromUrl(url) {
    if (!url) {
      return;
    }
    let response = await Axios({
      url: url,
      method: 'GET',
      timeout: 8000,
      responseType: 'blob'
    });
    if (response.status !== 200) {
      return;
    }
    let blob = await response.data;
    return await this._convertBlobToBase64(blob);
  }

  _convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async _loginToParse(service, authData, displayName, avatarBase64Image) {
    let response = await ParseUtil.linkUser(service, {authData}, displayName, avatarBase64Image);
    if (response) {
      await ParseUtil.refreshLoginForLinkUser(response);
    }
    AppLog.log(response);
    return response;
  }

  async _handleParseLoginResponse(response, linkService) {
    // todo
    let user = response.user;
    let publicData = response.publicData;
    let sessionToken = user.getSessionToken();
    let avatarUrl = publicData.get('avatarImage') ? publicData.get('avatarImage').url() : '';
    let displayName = publicData.get('displayName');
  }

}

let _instance = new AccountUtil();

export default _instance;
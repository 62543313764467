class TosTc {

  getContent() {
    return `<h2>
	服務條款
</h2>

<h3>
	1. 條款
</h3>

<p>
	通過使用這個應用程序，您同意受此應用程序的服務條款，所有適用法律及法規，並同意，您有責任遵守所有適用的地方法律。如果你不同意任何這些條款，你不得使用或訪問這個應用程序。這個應用程序受適用的版權和商標法律保護。
</p>

<h3>
	2. 使用許可
</h3>

<ol type="a">
	<li>
		通過使用這個應用程序，你不可以做以下的事情：
		
		<ol type="i">
			<li>複製這個應用程序（備份目的除外）;</li>
			<li>修改這個應用程序;</li>
			<li>試圖反編譯或反向工程這個應用程序;</li>
			<li>移除這個應用程序的任何版權或其他所有權的標示;或</li>
			<li>將這個應用程序轉移到任何其他服務器上作為“鏡像”或將這個應用程序轉發他人。</li>
		</ol>
	</li>
	<li>
		如果您違反上述任何限制，本網站可以隨時終止本許可協議。一旦此許可終止，您必須銷毀任何已下載的資料不論電子或印刷形式。
	</li>
</ol>

<h3>
	3. 免責聲明
</h3>

<ol type="a">
	<li>
		本網站的應用程序“按現狀”提供。 本網站不作任何保證，明示或暗示，並特此聲明，否認所有其他保證，包括但不限於，暗示保證或適銷性條件，適合特定目的或不侵犯知識產權或違反權利。此外，本網站不保證在這個應用程序中使用的資料的準確性，可能的結果，或可靠性作出任何陳述或以其他方式與該等資料或有關鏈接到這個應用程序的任何站點。
	</li>
</ol>

<h3>
	4. 限制
</h3>

<p>
	在任何情況下本網站或其供應商對任何損害（包括但不限於損失數據或利潤損失，或因此業務中斷，）因使用或無法使用本網站的應用程序，不承擔法律責任，即使本網站或本網站授權代表已被通知以口頭或書面形式提出這種損害的可能性。因為某些司法管轄區不允許對暗示的保證，或必然或偶然損害的賠償責任限制的限制，這些限制可能不適用於您。
</p>
			
<h3>
	5. 修訂和勘誤
</h3>

<p>
	出現在本網站的應用程序中的資料可能包括技術，印刷或攝影錯誤。 本網站不會保證任何在其應用程序的資料是準確的，完整的，或最新的。 本網站可能隨時更改，恕不另行通知其內含的應用程序的資料。 本網站不作出任何承諾更新資料。
</p>

<h3>
	6. 鏈接
</h3>

<p>
	本網站沒有審查所有鏈接到本網站的應用程序的網站，也不對任何此類鏈接網站的內容負責。包含任何鏈接並不表示該網站和本網站有任何關係。使用任何此類鏈接的網站用戶必須自己承擔風險。
</p>

<h3>
	7. 服務條款的修改
</h3>

<p>
	本網站可能在不另行通知的任何時候修改這些服務條款。通過使用這動應用程序，您同意遵守這些服務條款的約束。
</p>

<h3>
	8. 管轄法律
</h3>

<p>
	任何有關本網站的應用程序索賠應受香港特別行政區法律管轄，不受衝突發生的法律管轄地區影響。
</p>

`;
  }

}

let _instance = new TosTc();

export default _instance;

import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Delete, Filter} from 'mdi-material-ui';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import LoadingDialog from '../ui/LoadingDialog';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import EventPreviewView from '../ui/EventPreviewView';
import Dialog from '@material-ui/core/Dialog';
import {REDIRECT_TO_APPROVE} from './AddEventScreen';
import GeneralDialog from '../ui/GeneralDialog';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const BG_IMAGE = require('../res/img/blur_bg.png');
const EVENT_PAGE_ITEM = 20;

class AdminApproveEventScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowLoadingDialog: false, totalPendingCount: 0, currentPendingPage: 0, isShowDeleteDialog: false,
      filterUserId: null
    };
    this.applyFilterUserId = null;
    this.isSelfPublisherOnly = undefined;
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft />
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t('admin_approve_event_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <div style={styles.titleContainer}>
            <span style={styles.contentTitle}>{t('admin_approve_event_pending')}</span>
          </div>
          {this.renderFilterFields()}

          {this.renderTable()}
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
        {this.renderDeleteDialog()}
        {this.renderPreview()}
      </div>
    );
  }

  async componentDidMount() {
    await this.loadPendingData(0);
  }

  async loadPendingData(skip) {
    await this.setStateAsync({isShowLoadingDialog: true});
    let count;
    let approvedCount = 0;
    if (this.isSelfPublisherOnly) {
      count = await ParseUtil.getEventPendingCountForSelfPublisher();
    } else if (this.applyFilterUserId) {
      count = await ParseUtil.getEventPendingCountForUser(this.applyFilterUserId);
      approvedCount = await ParseUtil.getEventCountForUser(this.applyFilterUserId);
    } else {
      count = await ParseUtil.getEventPendingCount(false);
    }

    let results;
    if (this.isSelfPublisherOnly) {
      results = await ParseUtil.getAllPendingEvent(EVENT_PAGE_ITEM, skip, true, 1);
    } else if (this.applyFilterUserId) {
      results = await ParseUtil.getAllPendingEventForUser(EVENT_PAGE_ITEM, skip, this.applyFilterUserId);
    } else {
      results = await ParseUtil.getAllPendingEvent(EVENT_PAGE_ITEM, skip, true);
    }
    // get user data
    let userData = {};
    if (results && results.length > 0) {
      for (let i = 0; i < results.length; i++) {
        let item = results[i];
        let user = item.get('createdBy');
        if (!userData[user.id]) {
          userData[user.id] = await ParseUtil.getUserPublicData(user.id);
        }
      }
    }
    this.setState({
      pendingData: results, totalPendingCount: count, pendingUserData: userData, totalApprovedCount: approvedCount
    });
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  renderFilterFields() {
    const {t} = this.props;
    let countText;
    if (this.applyFilterUserId && this.state.filterUserId) {
      countText = (
        <div>
          <br />
          <span style={styles.filterCountText}>
            {t('admin_approve_event_filter_count') + this.state.totalPendingCount + ', ' +
              t('admin_approve_event_filter_count_approved') + this.state.totalApprovedCount}
          </span>
        </div>
      );
    }
    if (this.isSelfPublisherOnly) {
      countText = (
        <div>
          <br />
          <span style={styles.filterCountText}>
            {t('admin_approve_event_only_self_publisher')}
          </span>
        </div>
      );
    }
    return (
      <div style={styles.filterContainer}>
        <TextField style={styles.filterTextField}
          id={'filter_user_id'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.filterUserId}
          onChange={event => {
            let value = event.target.value;
            this.setState({filterUserId: value});
          }}
          label={t('admin_approve_event_filter_user_id')} />

        <div style={styles.filterBtnContainer}>
          <Button
            variant="contained"
            color="primary"
            onClick={this.filterEventByUserId.bind(this)}>
            <div style={styles.filterBtnContent}>
              <Filter />
              {t('admin_approve_event_filter')}
            </div>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            variant="contained"
            color="primary"
            onClick={this.filterEventBySelfPublisher.bind(this)}>
            <div style={styles.filterBtnContent}>
              <Filter />
              {t('admin_approve_event_only_self_publisher')}
            </div>
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button
            style={{backgroundColor: '#ff0000'}}
            variant="contained"
            color="delete"
            onClick={async () => {
              this.isSelfPublisherOnly = undefined;
              await this.setStateAsync({filterUserId: ''});
              this.filterEventByUserId();
            }}>
            <div style={styles.filterBtnContent}>
              <Delete />
              {t('general_clear')}
            </div>
          </Button>
        </div>
        {countText}
      </div>
    );
  }

  async filterEventBySelfPublisher() {
    await this.setStateAsync({
      isShowLoadingDialog: true, currentPendingPage: 0, totalPendingCount: 0,
      filterUserId: ''
    });
    this.applyFilterUserId = undefined;
    this.isSelfPublisherOnly = true;
    await this.loadPendingData(this.state.currentPendingPage * EVENT_PAGE_ITEM);
    await this.setStateAsync({isShowLoadingDialog: false});
    window.scrollTo(0, 0);
  }

  async filterEventByUserId() {
    await this.setStateAsync({isShowLoadingDialog: true, currentPendingPage: 0, totalPendingCount: 0});
    this.applyFilterUserId = this.state.filterUserId;
    await this.loadPendingData(this.state.currentPendingPage * EVENT_PAGE_ITEM);
    await this.setStateAsync({isShowLoadingDialog: false});
    window.scrollTo(0, 0);
  }

  renderDeleteDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowDeleteDialog}
        dialogContent={t('admin_approve_event_delete_desc')}
        dialogTitle={t('admin_approve_event_delete_title')}
        onCancelClick={() => {
          this.setState({isShowDeleteDialog: false});
        }}
        onClick={() => {
          this.setState({isShowDeleteDialog: false});
          this.deleteEvent();
        }} />
    );
  }

  async deleteEvent() {
    await this.setStateAsync({isShowLoadingDialog: true});
    if (this.deletePendingEventId) {
      await ParseUtil.deletePendingEvent(this.deletePendingEventId);
      await this.loadPendingData(this.state.currentPendingPage * EVENT_PAGE_ITEM);
    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  renderTable() {
    if (!this.state.pendingData) {
      return;
    }
    const {t} = this.props;
    let dataContentCells = [];
    let data = this.state.pendingData;
    for (let i = 0; i < data.length; i++) {
      let rowStyle = i % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;
      let object = data[i];

      let names = ParseUtil.getNameValuesForDisplay(object, 'eventName', LANG_CODES);
      let startAddresses = ParseUtil.getNameValuesForDisplay(object, 'startAddress', LANG_CODES);
      let endAddresses = ParseUtil.getNameValuesForDisplay(object, 'endAddress', LANG_CODES);
      let provinceLocation = object.get('provinceLocation');
      let province = provinceLocation ? ParseUtil.getNameValuesForDisplay(provinceLocation, 'name', LANG_CODES) : '';
      let user = object.get('createdBy');
      let disapproveReason = object.get('disapproveReason');
      let userData = this.state.pendingUserData[user.id];
      let userDisplayName = '';
      if (userData) {
        userDisplayName = userData.get('displayName');
      }
      let createDate = object.get('createdAt') + '';

      dataContentCells.push(
        <TableRow key={dataContentCells.length} style={rowStyle}>
          <TableCell style={styles.tableCell}>{createDate}</TableCell>
          <TableCell style={styles.tableCell}>
            <span dangerouslySetInnerHTML={{__html: userDisplayName}} />
            <br />
            <span style={styles.fakeLink} onClick={async () => {
              this.isSelfPublisherOnly = undefined;
              await this.setStateAsync({filterUserId: user.id});
              this.filterEventByUserId();
            }}>
              {user.id}
            </span>
          </TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: names}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: startAddresses}} /></TableCell>
          {/* <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: endAddresses}} /></TableCell> */}
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: province}} /></TableCell>
          <TableCell style={{...styles.tableCell, color: '#ff4714'}}>{disapproveReason}</TableCell>

          <TableCell style={styles.tableCell}>
            <div>
              <span style={styles.fakeLink} onClick={() => {
                this.setState({previewPendingEventId: object.id});
              }}>
                {t('admin_approve_event_table_action_preview')}
              </span>
              <br /><br />
              <Link to={'/event/edit/1/' + object.id + '/' + REDIRECT_TO_APPROVE}>
                {t('admin_approve_event_table_action_edit')}
              </Link>
              <br /><br />
              <span style={styles.fakeLink} onClick={() => {
                this.deletePendingEventId = object.id;
                this.setState({isShowDeleteDialog: true});
              }}>
                {t('admin_approve_event_table_action_delete')}
              </span>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableContainer component={Paper} style={styles.table}>
        <Table stickyHeader aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>{t('admin_approve_event_table_create_date')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_approve_event_table_submitted_by')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_name')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_start_location_address')}</TableCell>
              {/* <TableCell style={styles.tableCell}>{t('add_event_end_location_address')}</TableCell> */}
              <TableCell style={styles.tableCell}>{t('add_event_location_province')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_approve_event_disapprove_reason')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_approve_event_table_action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContentCells}
          </TableBody>
          {this.renderEventTableFooter()}
        </Table>
      </TableContainer>
    );
  }

  renderPreview() {
    let open = false;
    if (this.state.previewPendingEventId && this.state.previewPendingEventId.length > 0) {
      open = true;
    }
    return (
      <div>
        <Dialog open={open}>
          <EventPreviewView pendingEventId={this.state.previewPendingEventId}
            onApproved={async () => {
              await this.setStateAsync({isShowLoadingDialog: true});
              await new Promise(r => setTimeout(r, 1500));
              await this.loadPendingData(this.state.currentPendingPage * EVENT_PAGE_ITEM);
              await this.setStateAsync({isShowLoadingDialog: false});
            }}
            onCloseClick={() => {
              this.setState({previewPendingEventId: null});
            }} />
        </Dialog>
      </div>
    );
  }

  renderEventTableFooter() {
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[EVENT_PAGE_ITEM]}
            colSpan={3}
            count={this.state.totalPendingCount}
            rowsPerPage={EVENT_PAGE_ITEM}
            page={this.state.currentPendingPage}
            onChangePage={(event, page) => {
              this.setState({currentPendingPage: page});
              this.loadPendingData(page * EVENT_PAGE_ITEM);
            }}
          />
        </TableRow>
      </TableFooter>
    );
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  tableRowOdd: {
    backgroundColor: '#f5f5f5',
  },
  tableRowEven: {
    backgroundColor: '#f6ffff'
  },
  tableCell: {
    borderWidth: 1,
    borderColor: '#efefef',
    borderStyle: 'solid',
    minWidth: 35
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  addNewBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  addNewBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  fakeLink: {
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  filterContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  filterBtnContainer: {
    marginLeft: 16,
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  filterBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  filterCountText: {
    marginLeft: 8
  },
  filterTextField: {
    marginLeft: 16,
    marginRight: 16,
    flexGrow: 1,
  }
};

export default withTranslation()(AdminApproveEventScreen);
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

class UsernameRegisterDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      confirmPassword: ''
    };
  }

  render() {
    const {t} = this.props;
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={() => {
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t('login_register')}</DialogTitle>
          <DialogContent>
            <div>
              <form noValidate autoComplete="off">
                <div style={styles.fieldsContainer}>
                  <TextField style={styles.inputField} id="email"
                    label={t('register_dialog_email')}
                    value={this.state.email}
                    onChange={(event) => {
                      this.setState({email: event.target.value});
                    }} />
                  <TextField style={styles.inputField} id="password"
                    label={t('register_dialog_password')}
                    value={this.state.password} type={'password'}
                    onChange={(event) => {
                      this.setState({password: event.target.value});
                    }} />
                  <TextField style={styles.inputField} id="confirmPassword"
                    label={t('register_dialog_password_confirm')}
                    value={this.state.confirmPassword} type={'password'}
                    onChange={(event) => {
                      this.setState({confirmPassword: event.target.value});
                    }} />
                </div>
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onCancel.bind(this)} color="primary" autoFocus>
              {t('register_dialog_cancel')}
            </Button>
            <Button onClick={this.onConfirm.bind(this)} color="primary" autoFocus>
              {t('register_dialog_register')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  onCancel() {
    this.setState({email: '', password: '', confirmPassword: ''});
    this.props.onCancel();
  }

  onConfirm() {
    // todo validate input
    this.props.onConfirm(this.state.email, this.state.password);
  }

}

const styles = {
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputField: {
    marginBottom: 15,
    maxWidth: 400,
    width: '70vw',
  }
};

export default withTranslation()(UsernameRegisterDialog);
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import MenuItem from '@material-ui/core/MenuItem';
import ParseUtil from '../util/ParseUtil';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

class FormSelect extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {labelWidth: 0, selectedValue: ''};
  }

  render() {
    const {t} = this.props;
    let menuItems = [];
    if (this.props.selectParseData) {
      for (let i = 0; i < this.props.selectParseData.length; i++) {
        let object = this.props.selectParseData[i];
        menuItems.push(
          <MenuItem key={object.id} value={object.id}>
            {ParseUtil.getNameForCurrentLocale(object, this.props.fieldName)}
          </MenuItem>
        );
      }
    }
    if (this.props.menuItems) {
      menuItems = this.props.menuItems;
    }

    let value = this.state.selectedValue;
    if (value.length <= 0 && (this.props.selectedValue || this.props.selectedValue === 0)) {
      value = this.props.selectedValue;
    }

    return (
      <FormControl variant="outlined" style={styles.formControl}>
        <InputLabel ref={async input => {
          await new Promise(r => setTimeout(r, 1000));
          if (input != null && !this.state.labelWidth) {
            this.setState({labelWidth: input.offsetWidth});
          }
        }} id="label">{this.props.labelTitle}</InputLabel>
        <Select
          id={this.props.selectId}
          labelWidth={this.state.labelWidth}
          labelId="label"
          value={value}
          onChange={event => {
            this.setState({selectedValue: event.target.value});
            if (this.props.onChange) {
              this.props.onChange(event.target.value);
            }
          }}>
          <MenuItem value="" disabled>{t('general_input_please_select')}</MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }

}

const styles = {
  formControl: {
    width: '100%'
  },
};

export default withTranslation()(FormSelect);
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './en';
import zh from './zh';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: en,
  zh: zh
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },

    debug: true,
  });

export default i18n;
export default {
  translation: {
    'login_app_name': 'EventX',
    'login_app_name_public': 'GoGoEasy',
    'login_title': 'Events Management System',
    'login_register_login': 'Login / Register',
    'login_or': 'OR',
    'login_username': 'Username',
    'login_t_and_c': 'Terms of Service',
    'login_privacy': 'Privacy Policy',
    'login_error_username_password': 'Login failed, please check your username and password',
    'login_error_try_again': 'Login failed, please try again',
    'login_register': 'Register by email',

    'terms_title': 'Terms of Service',
    'terms_ok': 'OK',
    'terms_file': 'TosEn',
    'privacy_title': 'Privacy Policy',
    'privacy_ok': 'OK',
    'privacy_file': 'PrivacyEn',

    'lang_code_en': 'English',
    'lang_code_zh': 'Chinese',
    'lang_code_ja': 'Japanese',
    'lang_code_ko': 'Korean',
    'lang_code_fr': 'French',
    'lang_code_de': 'German',
    'lang_code_local': 'Local language',

    'general_input_please_select': 'Please select',
    'general_ok': 'OK',
    'general_cancel': 'Cancel',
    'general_error': 'Error',
    'general_loading': 'Loading... Please wait a moment',
    'general_add_item_fail': 'Add item failed, please try again',
    'general_edit_item_fail': 'Edit item failed, please try again',
    'general_operation_success': 'Operation success',
    'general_operation_fail': 'Operation failed, please try again',
    'general_clear': 'Clear',

    'username_dialog_title': 'Login',
    'username_dialog_username': 'Username',
    'username_dialog_password': 'Password',
    'username_dialog_cancel': 'Cancel',
    'username_dialog_confirm': 'Confirm',

    'register_dialog_email': 'Email',
    'register_dialog_password': 'Password',
    'register_dialog_password_confirm': 'Confirm password',
    'register_dialog_cancel': 'Cancel',
    'register_dialog_register': 'Register',

    'home_title': 'Home',
    'home_event_management_system': 'Events Management System',
    'home_super_admin_functions': 'Super Admin Functions',
    'home_item_new_event': 'Add new event (Normal version)',
    'home_item_view_my_event': 'View my events (Normal version)',
    'home_item_view_category': 'View / Edit categories',
    'home_item_view_provinces': 'View / Edit provinces',
    'home_item_view_area': 'View / Edit area',
    'home_item_approve_event': 'Approve events (Normal version)',
    'home_item_view_all_events': 'View all events (Normal version)',
    'home_item_new_ads': 'Add new event (Simple version)',
    'home_item_view_my_ads': 'View my events (Simple version)',
    'home_item_approve_ads': 'Approve events (Simple version)',
    'home_logout': 'Logout',
    'home_welcome': 'Welcome, ',
    'home_register_email': 'Add/Update email to receive approval notifications',
    'home_email': 'Email',
    'home_email_empty': 'Not set',
    'home_email_invalid': 'Email address format invalid',
    'home_item_new_event_desc': '*Enter more event details\n*Event supported by "Automatic Route Generation" function',
    'home_item_new_ads_desc': '*Enter less event details\n*Event NOT supported by "Automatic Route Generation" function',

    'admin_area_title': 'View / Edit area',
    'admin_area_current': 'Current areas',
    'admin_area_table_name': 'Name',
    'admin_area_table_country': 'Country / Region',
    'admin_area_table_coordinate': 'Coordinate',
    'admin_area_table_action': 'Action',
    'admin_area_table_action_edit': 'Edit',
    'admin_area_add_new': 'Add New',

    'admin_add_area_title': 'Add new area',
    'admin_add_area_edit_title': 'Edit area',
    'admin_add_area_name': 'Name',
    'admin_add_area_country': 'Country / Region',
    'admin_add_area_lat_lng': 'Area Location Lat Lng',
    'admin_add_area_lat': 'Lat',
    'admin_add_area_lng': 'Lng',
    'admin_add_area_get_lat_lng': 'Auto get location Lat Lng from...',
    'admin_add_area_submit': 'Submit',
    'admin_add_area_missing_name': 'Please input at least one name',
    'admin_add_area_missing_country': 'Please select country / region',
    'admin_add_area_missing_lat_lng': 'Please input Lat Lng',

    'admin_province_title': 'View / Edit province',
    'admin_province_current': 'Current provinces',
    'admin_province_table_name': 'Name',
    'admin_province_table_country': 'Country / Region',
    'admin_province_table_area': 'Area',
    'admin_province_table_action': 'Action',
    'admin_province_table_action_edit': 'Edit',
    'admin_province_table_action_delete': 'Delete',
    'admin_province_add_new': 'Add New',

    'admin_add_province_title': 'Add new province',
    'admin_add_province_edit_title': 'Edit province',
    'admin_add_province_name': 'Name',
    'admin_add_province_country': 'Country / Region',
    'admin_add_province_area': 'Area',
    'admin_add_province_submit': 'Submit',
    'admin_add_province_missing_name': 'Please input at least one name',
    'admin_add_province_missing_country': 'Please select country / region',
    'admin_add_province_missing_area': 'Please select area',

    'admin_category_title': 'View / Edit categories',
    'admin_category_current': 'Current categories',
    'admin_category_table_name': 'Name',
    'admin_category_table_icon': 'Icon',
    'admin_category_table_order': 'Order',
    'admin_category_table_action': 'Action',
    'admin_category_table_action_edit': 'Edit',
    'admin_category_add_new': 'Add New',

    'admin_add_category_title': 'Add new category',
    'admin_add_category_edit_title': 'Edit category',
    'admin_add_category_name': 'Name',
    'admin_add_category_order': 'Order',
    'admin_add_category_icon': 'Icon',
    'admin_add_category_icon_upload': 'UPLOAD ICON',
    'admin_add_category_submit': 'Submit',
    'admin_add_category_missing_name': 'Please input at least one name',
    'admin_add_category_missing_order': 'Please input order',
    'admin_add_category_missing_icon': 'Please input icon',

    'add_ads_title': 'Add new event (Simple version)',
    'add_ads_input_info': 'Please input event information',
    'add_ads_ads_title': 'Title (Max 100 characters)',
    'add_ads_link': 'Event link',
    'add_ads_link_supported': 'Supported link website',
    'add_ads_image': 'Event image',
    'add_ads_description': 'Promotional text (optional, max 500 characters)',
    'add_ads_location_desc': 'Event will be displaying in the following country/region',
    'add_ads_location_country': 'Event country / region',
    'add_ads_image_upload': 'Upload Event image',
    'add_ads_submit': 'Submit',
    'add_ads_missing_title': 'Please input title',
    'add_ads_missing_link': 'Please input link',
    'add_ads_missing_image': 'Please input image',
    'add_ads_missing_country': 'Please input country / region',
    'add_ads_invalid_link': 'Please input valid link',

    'add_event_title': 'Add new event',
    'add_event_edit_title': 'Edit event',
    'add_event_check_exist_title': 'Check if event already exists',
    'add_event_check_exist_name': 'Name (any language)',
    'add_event_check_button': 'Check',
    'add_event_step_name': 'Name',
    'add_event_step_location': 'Location',
    'add_event_step_date_info': 'Date information',
    'add_event_step_content_info': 'Content information',
    'add_event_name': 'Name',
    'add_event_name_title': 'Please input as least one event / place name',
    'add_event_next_step': 'Next',
    'add_event_prev_step': 'Previous',
    'add_event_finish_submit': 'Finish and submit',
    'add_event_name_too_short': 'Input name too short, at least 2 characters for Chinese, Japanese and Korean, 4 characters for other languages',
    'add_event_no_similar_event_dialog_title': 'No Similar events found',
    'add_event_no_similar_event_dialog_desc': 'Good! You can continue to add this event',
    'add_event_similar_event_dialog_title': 'Similar events found',
    'add_event_similar_event_dialog_desc': 'We have found the following existing similar events, please do not submit same event to the system.<br/>If you think your event is different from the one listed below, you can continue the process.',
    'add_event_similar_event_dialog_continue': 'Continue',
    'add_event_similar_event_dialog_cancel': 'Cancel',
    'add_event_start_location_address': 'Start location address',
    'add_event_end_location_address': 'End location address',
    'add_event_start_location_address_lat_lng': 'Start Location Latitude and Longitude',
    'add_event_end_location_address_lat_lng': 'End Location Latitude and Longitude',
    'add_event_location_latitude': 'Latitude',
    'add_event_location_longitude': 'Longitude',
    'add_event_address_lat_lng_auto_get': 'Auto get Lat Lng from...',
    'add_event_end_location_same': 'Same as start',
    'add_event_address_missing_name': 'Please input all addresses',
    'add_event_address_too_short': 'Address too short, at least 5 characters',
    'add_event_address_missing_lat_lng': 'Please input Latitude and Longitude',
    'add_event_location_missing_province': 'Please select country / region, area and province',
    'add_event_info_indoor_or_outdoor': 'Indoor or outdoor event',
    'add_event_info_indoor': 'Indoor',
    'add_event_info_outdoor': 'Outdoor',
    'add_event_info_include_meal': 'Is event location provide meal (has restaurant or meal included in event)',
    'add_event_info_no_meal': 'No meal',
    'add_event_info_meal_breakfast': 'Provide breakfast',
    'add_event_info_meal_lunch': 'Provide lunch',
    'add_event_info_meal_dinner': 'Provide dinner',
    'add_event_info_meal_lunch_dinner': 'Provide lunch and dinner',
    'add_event_info_meal_breakfast_lunch': 'Provide breakfast and lunch',
    'add_event_info_meal_breakfast_dinner': 'Provide breakfast and dinner',
    'add_event_info_meal_breakfast_lunch_dinner': 'Provide breakfast, lunch and dinner',
    'add_event_info_min_stay_time': 'Minimum stay time',
    'add_event_info_max_stay_time': 'Maximum stay time',
    'add_event_info_min_stay_time_na': 'No minimum stay time',
    'add_event_info_max_stay_time_na': 'No maximum stay time',
    'add_event_info_event_start_time': 'Event start time (local time, 24-hour clock)',
    'add_event_info_event_end_time': 'Event end time (local time, 24-hour clock)',
    'add_event_info_event_start_time_na': 'No specific start time',
    'add_event_info_event_end_time_na': 'No specific end time',
    'add_event_info_category': 'Category (can multiple select)',
    'add_event_info_event_start_date': 'Event start date (local time, leave blank if not applicable)',
    'add_event_info_event_end_date': 'Event end date (local time, leave blank if not applicable)',
    'add_event_info_stamina_factor': 'Activity body energy requirement',
    'add_event_info_stamina_large': 'Large',
    'add_event_info_stamina_medium': 'Medium',
    'add_event_info_stamina_small': 'Small',
    'add_event_info_event_season': 'Event season',
    'add_event_info_event_season_na': 'No specific season',
    'add_event_info_event_season_spring': 'Spring',
    'add_event_info_event_season_summer': 'Summer',
    'add_event_info_event_season_autumn': 'Autumn',
    'add_event_info_event_season_winter': 'Winter',
    'add_event_info_event_season_spring_summer': 'Spring and Summer',
    'add_event_info_event_season_summer_autumn': 'Summer and Autumn',
    'add_event_info_event_season_autumn_winter': 'Autumn and Winter',
    'add_event_info_event_season_winter_spring': 'Winter and Spring',
    'add_event_info_event_photo': 'Event photos (png / jpg / jpeg), max 5 photos',
    'add_event_info_wiki': 'Wikipedia link (can be any language, leave blank if not applicable)',
    'add_event_info_instagram_post': 'Instagram post link (post link, not profile page link, will replace image display, leave blank if not applicable)',
    'add_event_info_event_desc': 'Event description (e.g. introduction, ticketing etc, leave blank if not applicable)',
    'add_event_info_url_link': 'Reference website (leave blank if not applicable, max three links)',
    'add_event_info_url_link_title': 'Reference website title',
    'add_event_info_url_link_url': 'Reference website url',
    'add_event_location_province': 'Event Province',
    'add_event_location_area': 'Event Area',
    'add_event_location_country': 'Event Country / Region',
    'add_event_time_hour': 'hour',
    'add_event_time_hours': 'hours',
    'add_event_stay_time_missing': 'Please select stay time',
    'add_event_event_date_missing': 'Please select event time',
    'add_event_drop_files': 'Drag \'n\' drop some files here, or click to select files',
    'add_event_drop_added_files': 'Added photos',
    'add_event_drop_clear_files': 'Clear all photos',
    'add_event_item_missing': 'Please fill in all mandatory items',
    'add_event_photo_missing': 'Please add photos',
    'add_event_wiki_wrong_format': 'Please input valid Wikipedia url',
    'add_event_instagram_post_wrong_format': 'Please input valid Instagram url',
    'add_event_intro_dialog_title': 'Add new event',
    'add_event_intro_dialog_desc': 'Please prepare the following information:<br/>- Event name and address<br/>- Event photo (at least one)',
    'add_event_save_success_title': 'Save event completed',
    'add_event_save_success_desc': 'Add new event success<br/>The new event is pending approval now<br/>Please come back and check approval status later',
    'add_event_save_success_edit_desc': 'Edit event success',
    'add_event_save_fail': 'Save event failed, please try again, make sure you have good internet connection',
    'add_event_info_min_time_larger_than_max_time': 'Minimum time cannot be larger than maximum time',
    'add_event_info_start_time_larger_than_end_time': 'Start time cannot be larger than end time',
    'add_event_info_start_date_larger_than_end_date': 'Start date cannot be larger than end date',
    'add_event_link_missing_info': 'Please input both title and url for links',
    'add_event_link_invalid': 'Invalid url for reference website, url need to be in http:// or https://',

    'view_my_event_title': 'View my events',
    'view_my_event_approved': 'Approved events',
    'view_my_event_pending': 'Pending approval events',
    'view_my_event_add_new': 'Add new',
    'view_my_event_table_action': 'Action',
    'view_my_event_table_action_edit': 'Edit',
    'view_my_event_table_action_delete': 'Delete',
    'view_my_event_table_action_preview': 'Preview',
    'view_my_event_delete_title': 'Delete event',
    'view_my_event_delete_desc': 'Confirm delete this event?',
    'view_my_event_edit_disapprove_warning': 'Editing this event will require admin approval again',
    'view_my_event_disapprove_reason': 'Approver comment',

    'view_my_ads_title': 'View my events (Simple Version)',
    'view_my_ads_approved': 'Approved event',
    'view_my_ads_pending': 'Pending approval event',
    'view_my_ads_add_new': 'Add new',
    'view_my_ads_table_action': 'Action',
    'view_my_ads_table_action_edit': 'Edit',
    'view_my_ads_table_action_delete': 'Delete',
    'view_my_ads_table_action_preview': 'Preview',
    'view_my_ads_delete_title': 'Delete event',
    'view_my_ads_delete_desc': 'Confirm delete this event?',
    'view_my_ads_disapprove_reason': 'Approver comment',
    'view_my_ads_expiry_date': 'Expiry date',

    'admin_approve_event_title': 'Approve events',
    'admin_approve_event_pending': 'Pending approval events',
    'admin_approve_event_table_action_preview': 'Preview',
    'admin_approve_event_table_action': 'Action',
    'admin_approve_event_table_action_edit': 'Edit',
    'admin_approve_event_table_action_delete': 'Delete',
    'admin_approve_event_table_submitted_by': 'Submitted by',
    'admin_approve_event_table_create_date': 'Create date',
    'admin_approve_event_delete_title': 'Delete event',
    'admin_approve_event_delete_desc': 'Confirm delete this event?',
    'admin_approve_event_filter_user_id': 'Filter by user id',
    'admin_approve_event_filter': 'Filter',
    'admin_approve_event_filter_count': 'Total pending approval count of this user: ',
    'admin_approve_event_filter_count_approved': 'Approved count: ',
    'admin_approve_event_disapprove_reason': 'Approver comment',
    'admin_approve_event_only_self_publisher': 'Self publisher only',

    'admin_approve_ads_title': 'Approve event (Simple Version)',
    'admin_approve_ads_pending': 'Pending approval event',
    'admin_approve_ads_table_action_preview': 'Preview',
    'admin_approve_ads_table_action': 'Action',
    'admin_approve_ads_table_action_edit': 'Edit',
    'admin_approve_ads_table_action_delete': 'Delete',
    'admin_approve_ads_table_submitted_by': 'Submitted by',
    'admin_approve_ads_table_create_date': 'Create date',
    'admin_approve_ads_delete_title': 'Delete event',
    'admin_approve_ads_delete_desc': 'Confirm delete this event?',
    'admin_approve_ads_filter_user_id': 'Filter by user id',
    'admin_approve_ads_filter': 'Filter',
    'admin_approve_ads_filter_count': 'Total pending approval count of this user: ',
    'admin_approve_ads_filter_count_approved': 'Approved count: ',
    'admin_approve_ads_disapprove_reason': 'Approver comment',
    'admin_approve_ads_only_self_publisher': 'Self publisher only',

    'event_preview_start_time': 'Start time',
    'event_preview_end_time': 'End time',
    'event_preview_max_stay_time': 'Max stay time',
    'event_preview_min_stay_time': 'Min stay time',
    'event_preview_hours': 'hour(s)',
    'event_preview_indoor': 'Indoor event',
    'event_preview_outdoor': 'Outdoor event',
    'event_preview_start_date': 'Start date',
    'event_preview_end_date': 'End date',
    'event_preview_energy': 'Energy',
    'event_preview_energy_large': 'Large',
    'event_preview_energy_medium': 'Medium',
    'event_preview_energy_small': 'Small',
    'event_preview_season': 'Season',
    'event_preview_close': 'Close',
    'event_preview_approve': 'Approve',
    'event_preview_disapprove': 'Disapprove',
    'event_preview_disapprove_reason': 'Disapprove reason',
    'event_preview_start_location': 'Start location',
    'event_preview_end_location': 'End location',

    'ads_preview_close': 'Close',
    'ads_preview_approve': 'Approve',
    'ads_preview_disapprove': 'Disapprove',
    'ads_preview_disapprove_reason': 'Disapprove reason',

    'admin_event_title': 'View all events',
    'admin_event_current': 'Current events',
    'admin_event_add_new': 'Add new'
  }
};
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link, Redirect} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import TextField from '@material-ui/core/TextField';
import ParseUtil, {LANG_CODES, LANG_CODES_DESC} from '../util/ParseUtil';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MapsUtil from '../util/MapsUtil';
import AppLog from '../util/AppLog';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import EventBus from '../util/EventBus';

const BG_IMAGE = require('../res/img/blur_bg.png');

class AdminAddAreaScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countryLabelWidth: 0, countryData: null, nameValues: {}, isShowLoadingDialog: false,
      areaLat: null, areaLng: null, isShowErrorDialog: false, errorDialogContent: null,
      redirectToArea: false, selectedCountryValue: ''
    };
    this.countryLabel = null;
    this.autoLatLngLabel = null;
    this.editAreaId = this.props.match.params.id;
  }

  async componentDidMount() {
    if (this.countryLabel) {
      this.setState({countryLabelWidth: this.countryLabel.offsetWidth});
    }
    if (this.autoLatLngLabel) {
      this.setState({autoLatLngLabelWidth: this.autoLatLngLabel.offsetWidth});
    }
    await this.loadCountry();
    await this.loadArea();
  }

  async loadArea() {
    let areaId = this.editAreaId;
    if (!areaId) {
      return;
    }
    let areaObject = await ParseUtil.getArea(areaId);
    if (areaObject) {
      // pre fill items
      let nameValues = {};
      for (let i = 0; i < LANG_CODES.length; i++) {
        let field = 'name' + LANG_CODES[i];
        nameValues[field] = areaObject.get(field);
      }

      let geoPoint = areaObject.get('geoPoint') || {};
      let countryLocation = areaObject.get('countryLocation');

      this.setState({
        nameValues: nameValues,
        areaLat: geoPoint.latitude,
        areaLng: geoPoint.longitude,
        selectedCountryValue: countryLocation.id
      });
    }
  }

  async loadCountry() {
    let results = await ParseUtil.getAllCountry();
    this.setState({countryData: results});
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/superadmin/area" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft/>
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t(this.editAreaId ? 'admin_add_area_edit_title' : 'admin_add_area_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <span style={styles.contentTitle}>
            {t(this.editAreaId ? 'admin_add_area_edit_title' : 'admin_add_area_title')}
          </span>
          {this.renderForm()}
        </div>

        {this.renderErrorDialog()}
        {this.renderLoadingDialog()}
        {this.renderRedirectToArea()}
      </div>
    );
  }

  renderForm() {
    return (
      <form noValidate autoComplete="off" style={styles.form}>
        {this.renderNameTextFields()}
        <br/>
        {this.renderCountrySelect()}
        <br/>
        {this.renderLatLngFields()}
        <br/>
        <br/>
        <br/>
        {this.renderSubmitButton()}
      </form>
    );
  }

  renderSubmitButton() {
    const {t} = this.props;
    return (
      <Button
        style={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={this.validateInputAndSubmit.bind(this)}>
        <div style={styles.submitBtnContent}>
          <Plus/>
          {t('admin_add_area_submit')}
        </div>
      </Button>
    );
  }

  validateInputAndSubmit() {
    const {t} = this.props;
    // check name values
    let keys = Object.keys(this.state.nameValues);
    let isMissingName = true;
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.state.nameValues[key]) {
          isMissingName = false;
          break;
        }
      }
    }
    if (isMissingName) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_area_missing_name')});
      return;
    }
    // check country
    if (!this.state.selectedCountryValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_area_missing_country')});
      return;
    }
    // check lat lng
    if (!this.state.areaLat || !this.state.areaLng) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_area_missing_lat_lng')});
      return;
    }

    this.setState({isShowLoadingDialog: true}, async () => {
      // submit to server
      let area = await ParseUtil.saveArea(this.state.nameValues, this.state.selectedCountryValue,
        this.state.areaLat, this.state.areaLng, this.editAreaId);
      if (area) {
        // submit success
        EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
        this.setState({isShowLoadingDialog: false, redirectToArea: true});
      } else {
        // submit failed
        this.setState({
          isShowLoadingDialog: false, isShowErrorDialog: true,
          errorDialogContent: t('general_add_item_fail')
        });
      }
    });
  }

  renderRedirectToArea() {
    if (this.state.redirectToArea) {
      return (
        <Redirect to="/superadmin/area"/>
      );
    }
  }

  renderLoadingDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowLoadingDialog}>
        <DialogContent>
          <DialogContentText>
            {t('general_loading')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowErrorDialog}>
        <DialogTitle>{t('general_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.errorDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderLatLngFields() {
    const {t} = this.props;
    return (
      <div>
        <br/>
        <br/>
        {this.renderLatLngTitle()}
        <br/>
        {this.renderGoogleMaps()}
        <br/>
        <div style={styles.latLngFieldContainer}>
          <TextField style={styles.inputField} id={'areaLat'}
                     InputLabelProps={{shrink: true}} variant="outlined"
                     label={t('admin_add_area_lat')}
                     value={this.state.areaLat}
                     onChange={event => {
                       this.setState({areaLat: event.target.value});
                     }}/>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <TextField style={styles.inputField} id={'areaLng'}
                     InputLabelProps={{shrink: true}} variant="outlined"
                     label={t('admin_add_area_lng')}
                     value={this.state.areaLng}
                     onChange={event => {
                       this.setState({areaLng: event.target.value});
                     }}/>
        </div>
      </div>
    );
  }

  renderLatLngTitle() {
    const {t} = this.props;
    let menuItem = [];
    let keys = Object.keys(this.state.nameValues);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      menuItem.push(
        <MenuItem value={this.state.nameValues[key]}>{this.state.nameValues[key]}</MenuItem>
      );
    }

    let select = (
      <FormControl variant="outlined" style={styles.formControl}>
        <InputLabel ref={input => {
          this.autoLatLngLabel = input;
        }} id="auto_lat_lng_label">{t('admin_add_area_get_lat_lng')}</InputLabel>
        <Select
          labelWidth={this.state.autoLatLngLabelWidth}
          labelId="auto_lat_lng_label"
          onChange={event => {
            let value = event.target.value;
            this.getLatLngFromAddress(value);
          }}>
          <MenuItem value="" disabled>{t('general_input_please_select')}</MenuItem>
          {menuItem}
        </Select>
      </FormControl>
    );

    return (
      <div>
        <InputLabel>{t('admin_add_area_lat_lng')}</InputLabel>
        <br/>
        {select}
      </div>
    );
  }

  async getLatLngFromAddress(address) {
    try {
      let latLng = await MapsUtil.getGoogleLatLng(address);
      this.setState({areaLat: latLng.lat, areaLng: latLng.lng});
    } catch (e) {
      AppLog.log(e);
    }
  }

  renderGoogleMaps() {
    let lat = 22.396428;
    let lng = 114.109497;

    if (this.state.areaLat && this.state.areaLng) {
      lat = this.state.areaLat;
      lng = this.state.areaLng;
    }

    let src = 'https://www.google.com/maps/embed/v1/place?q=' + lat + '%2C' + lng +
      '&key=AIzaSyDMNoNm0VKMp9LI-hrBOQEFbhTILN2QM1M';
    return (
      <div>
        <iframe width="100%" height="300" frameBorder="0" src={src}></iframe>
      </div>
    );
  }

  renderCountrySelect() {
    const {t} = this.props;
    let menuItems = [];
    if (this.state.countryData) {
      for (let i = 0; i < this.state.countryData.length; i++) {
        let object = this.state.countryData[i];
        menuItems.push(
          <MenuItem value={object.id}>{ParseUtil.getNameForCurrentLocale(object, 'name')}</MenuItem>
        );
      }
    }
    return (
      <FormControl variant="outlined" style={styles.formControl}>
        <InputLabel ref={input => {
          this.countryLabel = input;
        }} id="country_label">{t('admin_add_area_country')}</InputLabel>
        <Select
          labelWidth={this.state.countryLabelWidth}
          labelId="country_label"
          value={this.state.selectedCountryValue}
          onChange={event => {
            this.setState({selectedCountryValue: event.target.value});
          }}>
          <MenuItem value="" disabled>{t('general_input_please_select')}</MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }

  renderNameTextFields() {
    const {t} = this.props;
    let nameTextFields = [];
    for (let i = 0; i < LANG_CODES.length; i++) {
      let key = 'name' + LANG_CODES[i];
      nameTextFields.push(
        <TextField style={styles.inputField} id={key}
                   InputLabelProps={{shrink: true}} variant="outlined"
                   value={this.state.nameValues[key]}
                   onChange={event => {
                     let values = this.state.nameValues;
                     values[key] = event.target.value;
                     this.setState({nameValues: values});
                   }}
                   label={t('admin_add_area_name') + ' (' + t(LANG_CODES_DESC[i]) + ')'}/>
      );
      nameTextFields.push(<br/>);
      nameTextFields.push(<br/>);
    }
    return nameTextFields;
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  form: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30
  },
  inputField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  latLngFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  }
};

export default withTranslation()(AdminAddAreaScreen);
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import Button from '@material-ui/core/Button';
import LoadingDialog from '../ui/LoadingDialog';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import EventPreviewView from '../ui/EventPreviewView';
import GeneralDialog from '../ui/GeneralDialog';

const BG_IMAGE = require('../res/img/blur_bg.png');
const EVENT_PAGE_ITEM = 10;

class AdminEventScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowDeleteDialog: false, isShowLoadingDialog: false, totalCount: 0, currentPage: 0
    };
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft/>
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t('admin_event_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <div style={styles.titleContainer}>
            <span style={styles.contentTitle}>{t('admin_event_current')}</span>

            <Button
              style={styles.addNewBtn}
              variant="contained"
              color="primary">
              <Link to="/event/add" style={styles.link}>
                <div style={styles.addNewBtnContent}>
                  <Plus/>
                  {t('admin_event_add_new')}
                </div>
              </Link>
            </Button>
          </div>

          {this.renderEvents()}
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog}/>
        {this.renderDeleteDialog()}
        {this.renderEventPreview()}
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData(0);
  }

  async loadData(skip) {
    let count = await ParseUtil.getEventCount(false);
    let results = await ParseUtil.getAllEvent(EVENT_PAGE_ITEM, skip, true);
    this.setState({eventData: results, totalCount: count}, () => {
      window.scrollTo(0, 0);
    });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  renderEvents() {
    let data = this.state.eventData;
    if (!data) {
      return;
    }
    const {t} = this.props;
    let dataContentCells = [];

    for (let i = 0; i < data.length; i++) {
      let rowStyle = i % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;
      let object = data[i];

      let names = ParseUtil.getNameValuesForDisplay(object, 'eventName', LANG_CODES);
      let startAddresses = ParseUtil.getNameValuesForDisplay(object, 'startAddress', LANG_CODES);
      let endAddresses = ParseUtil.getNameValuesForDisplay(object, 'endAddress', LANG_CODES);
      let provinceLocation = object.get('provinceLocation');
      let province = provinceLocation ? ParseUtil.getNameValuesForDisplay(provinceLocation, 'name', LANG_CODES) : '';

      dataContentCells.push(
        <TableRow style={rowStyle}>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: names}}/></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: startAddresses}}/></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: endAddresses}}/></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: province}}/></TableCell>

          <TableCell style={styles.tableCell}>
            <div>
              <Link onClick={() => {
                this.setState({previewEventId: object.id});
              }}>
                {t('view_my_event_table_action_preview')}
              </Link>
              <br/><br/>
              {/*<Link to={'/event/edit/' + (isPending ? 1 : 0) + '/' + object.id}>*/}
              {/*  {t('view_my_event_table_action_edit')}*/}
              {/*</Link>*/}
              {/*<br/><br/>*/}
              <Link onClick={() => {
                this.deleteEventId = object.id;
                this.setState({isShowDeleteDialog: true});
              }}>
                {t('view_my_event_table_action_delete')}
              </Link>
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableContainer component={Paper} style={styles.table}>
        <Table stickyHeader aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>{t('add_event_name')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_start_location_address')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_end_location_address')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_location_province')}</TableCell>
              <TableCell style={styles.tableCell}>{t('view_my_event_table_action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContentCells}
          </TableBody>
          {this.renderEventTableFooter()}
        </Table>
      </TableContainer>
    );
  }

  renderEventTableFooter() {
    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[EVENT_PAGE_ITEM]}
            colSpan={3}
            count={this.state.totalCount}
            rowsPerPage={EVENT_PAGE_ITEM}
            page={this.state.currentPage}
            onChangePage={(event, page) => {
              this.setState({currentPage: page});
              this.loadData(page * EVENT_PAGE_ITEM);
            }}
          />
        </TableRow>
      </TableFooter>
    );
  }

  renderEventPreview() {
    return (
      <div>
        <Dialog open={this.state.previewEventId}>
          <EventPreviewView
            eventId={this.state.previewEventId}
            viewOnly={true}
            onCloseClick={() => {
              this.setState({previewEventId: null});
            }}/>
        </Dialog>
      </div>
    );
  }

  renderDeleteDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowDeleteDialog}
                     dialogContent={t('view_my_event_delete_desc')}
                     dialogTitle={t('view_my_event_delete_title')}
                     onCancelClick={() => {
                       this.setState({isShowDeleteDialog: false});
                     }}
                     onClick={() => {
                       this.setState({isShowDeleteDialog: false});
                       this.deleteEvent();
                     }}/>
    );
  }

  async deleteEvent() {
    await this.setStateAsync({isShowLoadingDialog: true});
    if (this.deleteEventId) {
      await ParseUtil.deleteEvent(this.deleteEventId);
      await this.loadData(this.state.currentPage * EVENT_PAGE_ITEM);
    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }
}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  addNewBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  addNewBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  tableRowOdd: {
    backgroundColor: '#f5f5f5',
  },
  tableRowEven: {
    backgroundColor: '#f6ffff'
  },
  tableCell: {
    borderWidth: 1,
    borderColor: '#efefef',
    borderStyle: 'solid',
    minWidth: 35
  },
};

export default withTranslation()(AdminEventScreen);
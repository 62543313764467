import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import AddAdsScreen from '../screen/AddAdsScreen';
import AddEventScreen from '../screen/AddEventScreen';
import AdminAddAreaScreen from '../screen/AdminAddAreaScreen';
import AdminAddCategoryScreen from '../screen/AdminAddCategoryScreen';
import AdminAddProvinceScreen from '../screen/AdminAddProvinceScreen';
import AdminApproveAdsScreen from '../screen/AdminApproveAdsScreen';
import AdminApproveEventScreen from '../screen/AdminApproveEventScreen';
import AdminAreaScreen from '../screen/AdminAreaScreen';
import AdminCategoryScreen from '../screen/AdminCategoryScreen';
import AdminEventScreen from '../screen/AdminEventScreen';
import AdminProvinceScreen from '../screen/AdminProvinceScreen';
import HomeScreen from '../screen/HomeScreen';
import LoginScreen from '../screen/LoginScreen';
import ViewMyAdsScreen from '../screen/ViewMyAdsScreen';
import ViewMyEventScreen from '../screen/ViewMyEventScreen';
import EventBus from '../util/EventBus';
import ParseUtil from '../util/ParseUtil';

export default class Routes extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {parseUser: ParseUtil.getUser(), isParseUserAdmin: ParseUtil.isUserAdmin()};
  }

  componentDidMount() {
    this.loginStateChangedSub = EventBus.subscribe(EventBus.getKey().LOGIN_STATE_CHANGED, (value) => {
      this.setState({parseUser: ParseUtil.getUser(), isParseUserAdmin: ParseUtil.isUserAdmin()});
    });
  }

  componentWillUnmount() {
    if (this.loginStateChangedSub) {
      this.loginStateChangedSub.unsubscribe();
    }
  }

  render() {
    if (this.state.parseUser) {
      return (
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/home" component={HomeScreen} />

          {this.renderSuperAdminRoutes()}

          <Route path="/event/add" component={AddEventScreen} />
          <Route path="/event/edit/:isPending/:id/:redirectTo" component={AddEventScreen} />
          <Route path="/event/view" component={ViewMyEventScreen} />
          <Route path="/ads/add" component={AddAdsScreen} />
          <Route path="/ads/view" component={ViewMyAdsScreen} />

          <Route component={LoginScreen} />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route component={LoginScreen} />
        </Switch>
      );
    }
  }

  renderSuperAdminRoutes() {
    if (!this.state.isParseUserAdmin) {
      return;
    }
    let routes = [];
    routes.push(<Route path="/superadmin/area/add" component={AdminAddAreaScreen} key={'areaAdd'} />);
    routes.push(<Route path="/superadmin/area/edit/:id" component={AdminAddAreaScreen} key={'areaEdit'} />);
    routes.push(<Route path="/superadmin/area" component={AdminAreaScreen} key={'area'} />);

    routes.push(<Route path="/superadmin/province/add" component={AdminAddProvinceScreen} key={'provinceAdd'} />);
    routes.push(<Route path="/superadmin/province/edit/:id" component={AdminAddProvinceScreen} key={'provinceEdit'} />);
    routes.push(<Route path="/superadmin/province" component={AdminProvinceScreen} key={'province'} />);

    routes.push(<Route path="/superadmin/category/add" component={AdminAddCategoryScreen} key={'cateAdd'} />);
    routes.push(<Route path="/superadmin/category/edit/:id" component={AdminAddCategoryScreen} key={'cateEdit'} />);
    routes.push(<Route path="/superadmin/category" component={AdminCategoryScreen} key={'cate'} />);

    routes.push(<Route path="/superadmin/approve" component={AdminApproveEventScreen} key={'approve'} />);

    routes.push(<Route path="/superadmin/event" component={AdminEventScreen} key={'event'} />);

    routes.push(<Route path="/superadmin/approveads" component={AdminApproveAdsScreen} key={'approveads'} />);

    return routes;
  }

}
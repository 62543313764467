import {TextField} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {ClockOutline, Domain, InformationOutline, LinkBoxOutline, MapMarker, Run, SilverwareForkKnife, Tag, WeatherWindy, Wikipedia, Instagram} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import AppLog from '../util/AppLog';
import EventBus from '../util/EventBus';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import LoadingDialog from './LoadingDialog';

class EventPreviewView extends Component {

  constructor(props) {
    super(props);
    this.state = {isShowLoadingDialog: false};
  }

  render() {
    return (
      <div>
        <div>
          {this.renderEventListItemPreview()}
          <br />
          {this.renderEventContentPreview()}
          <br />
          <br />
          {this.renderCloseBtn()}
          <br />
          <br />
          <br />
          {this.renderDisapprovalBtn()}
          <br />
          <br />
          <br />
          {this.renderApprovalBtn()}
          <br />
          <br />
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
      </div>
    );
  }

  async componentDidMount() {
    AppLog.log('pendingEventId: ' + this.props.pendingEventId);
    AppLog.log('eventId: ' + this.props.eventId);
    this.setStateAsync({isShowLoadingDialog: true});
    if (this.props.pendingEventId) {
      await this.loadPendingEventData();
    } else if (this.props.eventId) {
      await this.loadEventData();
    }
    this.setStateAsync({isShowLoadingDialog: false});
  }

  async loadPendingEventData() {
    let result = await ParseUtil.getPendingEvent(this.props.pendingEventId);
    let files = await ParseUtil.getEventPendingFiles(this.props.pendingEventId);
    this.setState({pendingEventData: result, pendingEventPhotoData: files});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async loadEventData() {
    let result = await ParseUtil.getEvent(this.props.eventId);
    let files = await ParseUtil.getEventFiles(this.props.eventId);
    this.setState({eventData: result, eventPhotoData: files});
  }

  getData() {
    let data;
    if (this.state.pendingEventData) {
      data = this.state.pendingEventData;
    } else if (this.state.eventData) {
      data = this.state.eventData;
    }
    return data;
  }

  getPhotoData() {
    let data;
    if (this.state.pendingEventPhotoData) {
      data = this.state.pendingEventPhotoData;
    } else if (this.state.eventPhotoData) {
      data = this.state.eventPhotoData;
    }
    return data;
  }

  renderCloseBtn() {
    const {t} = this.props;
    return (
      this.renderSubmitButton(t('event_preview_close'), () => {
        if (this.props.onCloseClick) {
          this.props.onCloseClick();
        }
      })
    );
  }

  renderDisapprovalBtn() {
    if (this.props.viewOnly) {
      return;
    }
    const {t} = this.props;
    return (
      <div>
        <TextField style={styles.disapproveReasonTextField}
          id={'disapprove_reason'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.disapproveReason || ''}
          multiline
          onChange={event => {
            let value = event.target.value;
            this.setState({disapproveReason: value});
          }}
          label={t('event_preview_disapprove_reason')} />
        {this.renderSubmitButton(t('event_preview_disapprove'), () => {
          this.disapproveEvent();
        }, 'delete', '#ff0000')}
      </div>
    );
  }

  async disapproveEvent() {
    let reason = this.state.disapproveReason?.trim();
    if (!reason) {
      return;
    }
    const {t} = this.props;
    await this.setStateAsync({isShowLoadingDialog: true});
    await ParseUtil.addDisapproveReason(this.state.pendingEventData, reason);
    // we don't send email anymore
    // await ParseUtil.sendApprovalEmail(this.state.pendingEventData.get('createdBy').id,
    //   this.state.pendingEventData.id, false, reason);
    await this.setStateAsync({isShowLoadingDialog: false});
    EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
    if (this.props.onApproved) {
      this.props.onApproved();
    }
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  renderApprovalBtn() {
    if (this.props.viewOnly) {
      return;
    }
    const {t} = this.props;
    return (
      this.renderSubmitButton(t('event_preview_approve'), () => {
        this.approveEvent();
      }, 'secondary')
    );
  }

  async approveEvent() {
    const {t} = this.props;
    await this.setStateAsync({isShowLoadingDialog: true});
    await ParseUtil.approveEvent(this.state.pendingEventData);
    // we don't send email anymore
    // await ParseUtil.sendApprovalEmail(this.state.pendingEventData.get('createdBy').id,
    //   this.state.pendingEventData.id, true);
    await this.setStateAsync({isShowLoadingDialog: false});
    EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
    if (this.props.onApproved) {
      this.props.onApproved();
    }
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  renderEventListItemPreview() {
    let data = this.getData();
    if (!data) {
      return;
    }

    let provinceLocation = data.get('provinceLocation');
    let areaLocation = provinceLocation.get('areaLocation');
    let cates = data.get('cates');

    let eventName = ParseUtil.getNameValuesForDisplay(data, 'eventName', LANG_CODES);
    let areaName = ParseUtil.getNameForCurrentLocale(areaLocation, 'name');
    let provinceName = ParseUtil.getNameForCurrentLocale(provinceLocation, 'name');
    let catesName = '';
    cates.forEach((item, index) => {
      if (catesName.length > 0) {
        catesName = catesName + ', ';
      }
      catesName = catesName + ParseUtil.getNameForCurrentLocale(item, 'name');
    });

    return (
      <div>
        <br />
        <div style={styles.title}><b><span dangerouslySetInnerHTML={{__html: eventName}} /></b></div>
        <br />
        {this.renderImagePreview()}
        <br />
        <div style={styles.listItemInfo}>{<MapMarker />}&nbsp;<span>{areaName + ' ' + provinceName}</span></div>
        <div style={styles.listItemInfo}>{<Tag />}&nbsp;<span>{catesName}</span></div>
      </div>
    );
  }

  renderImagePreview() {
    let data = this.getPhotoData();
    if (!data) {
      return;
    }
    let images = [];
    data.forEach((item, index) => {
      let url = item.get('image').url();
      if (window.location.hostname?.indexOf('gogoeasy') > -1) {
        // gogoeasy
        url = url.replace('trip.atomin', 'api.gogo');
        url = url.replace('vention', 'easy');
      } else {
        // wisely go
        url = url.replace('trip.atomin', 'api.wisely');
        url = url.replace('vention', 'go');
      }
      images.push(
        <img key={index} style={styles.coverImage} alt={''} src={url} />
      );
    });
    return images;
  }

  renderEventContentPreview() {
    let data = this.getData();
    if (!data) {
      return;
    }
    const {t} = this.props;

    let startLocation = data.get('startLocation');
    let startGeoPoint = startLocation.get('location');
    let startLat = startGeoPoint.latitude;
    let startLng = startGeoPoint.longitude;

    let endLocation = data.get('endLocation');
    let endGeoPoint = endLocation.get('location');
    let endLat = endGeoPoint.latitude;
    let endLng = endGeoPoint.longitude;

    let startAddress = ParseUtil.getNameValuesForDisplay(data, 'startAddress', LANG_CODES);
    let endAddress = ParseUtil.getNameValuesForDisplay(data, 'endAddress', LANG_CODES);

    let startTime = data.get('eventStartTime');
    let endTime = data.get('eventEndTime');
    let startTimeItem;
    let endTimeItem;
    if (startTime !== -1) {
      let startTimeDesc = t('event_preview_start_time') + ': ' + this.getTimeHourString(startTime);
      startTimeItem = (<span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;
        <span>{startTimeDesc}</span><br /></span>);
    }
    if (endTime !== -1) {
      let endTimeDesc = t('event_preview_end_time') + ': ' + this.getTimeHourString(endTime);
      endTimeItem = (<span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;
        <span>{endTimeDesc}</span><br /></span>);
    }

    let maxStayTime = data.get('maxStayTime');
    let minStayTime = data.get('minStayTime');
    let maxStayTimeItem;
    let minStayTimeItem;
    if (maxStayTime !== -1) {
      let timeDesc = t('event_preview_max_stay_time') + ': ' + this.getStayHourString(maxStayTime);
      maxStayTimeItem = (
        <span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;<span>{timeDesc}</span><br /></span>);
    }
    if (minStayTime !== -1) {
      let timeDesc = t('event_preview_min_stay_time') + ': ' + this.getStayHourString(minStayTime);
      minStayTimeItem = (
        <span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;<span>{timeDesc}</span><br /></span>);
    }

    let indoor = t(data.get('isIndoor') === 1 ? 'event_preview_indoor' : 'event_preview_outdoor');
    let meal = this.getMealString(data.get('isIncludeMeal'));

    let startDate = data.get('eventStartDate');
    let endDate = data.get('eventEndDate');
    let startDateItem;
    let endDateItem;
    if (startDate) {
      let desc = t('event_preview_start_date') + ': ' + this.getDateString(startDate);
      if (desc.indexOf('1988') === -1) {
        startDateItem = (
          <span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;<span>{desc}</span><br /></span>
        );
      }
    }
    if (endDate) {
      let desc = t('event_preview_end_date') + ': ' + this.getDateString(endDate);
      if (desc.indexOf('2199') === -1) {
        endDateItem = (
          <span><span style={styles.contentItemInfo}>{<ClockOutline />}</span>&nbsp;<span>{desc}</span><br /></span>
        );
      }
    }

    let energy = this.getEnergyString(data.get('stamina'));
    let season = this.getSeasonString(data.get('bestSeason'));
    let wiki = data.get('wikiLink');
    let wikiItem;
    if (wiki) {
      wikiItem = (
        <span>
          <span style={styles.contentItemInfo}>{<Wikipedia />}</span>
          &nbsp;
          <span><a href={wiki} rel="noopener noreferrer" target="_blank">{wiki}</a></span>
          <br />
        </span>
      );
    }

    let instagramPost = data.get('instagramPostLink');
    let instagramPostItem;
    if (instagramPost) {
      instagramPostItem = (
        <span>
          <span style={styles.contentItemInfo}>{<Instagram />}</span>
          &nbsp;
          <span><a href={instagramPost} rel="noopener noreferrer" target="_blank">{instagramPost}</a></span>
          <br />
        </span>
      );
    }

    let eventDescItem;
    let eventDesc = ParseUtil.getNameValuesForDisplay(data, 'eventDesc', LANG_CODES);
    if (eventDesc) {
      eventDescItem = (
        <span>
          <span style={styles.contentItemInfo}>{<InformationOutline />}</span>&nbsp;
          <span dangerouslySetInnerHTML={{__html: eventDesc}} />
          <br />
        </span>
      );
    }

    let linkItem;
    let linksValues = data.get('linksLocal');
    if (linksValues?.length > 0) {
      linkItem = [];
      linksValues.forEach(linksValue => {
        linkItem.push(
          <span>
            <span style={styles.contentItemInfo}>{<LinkBoxOutline />}</span>&nbsp;
            <span><b>{linksValue.title}</b></span>
            <br />
            <span><a href={linksValue.link} rel="noopener noreferrer" target="_blank">{linksValue.link}</a></span>
            <br />
          </span>
        );
      });
    }

    return (
      <div>
        <span><b>&nbsp;&nbsp;&nbsp;{t('event_preview_start_location')}</b></span>
        {this.renderGoogleMaps(startLat, startLng)}
        {<span style={styles.contentItemInfo}><MapMarker /></span>}&nbsp;
        <span dangerouslySetInnerHTML={{__html: startAddress}} />
        <br />
        <br />
        <br />
        <span><b>&nbsp;&nbsp;&nbsp;{t('event_preview_end_location')}</b></span>
        {this.renderGoogleMaps(endLat, endLng)}
        {<span style={styles.contentItemInfo}><MapMarker /></span>}&nbsp;
        <span dangerouslySetInnerHTML={{__html: endAddress}} />
        <br />
        <br />
        <br />
        {startTimeItem}
        {endTimeItem}
        {maxStayTimeItem}
        {minStayTimeItem}
        {<span style={styles.contentItemInfo}><Domain /></span>}&nbsp;<span>{indoor}</span>
        <br />
        {<span style={styles.contentItemInfo}><SilverwareForkKnife /></span>}&nbsp;<span>{meal}</span>
        <br />
        {startDateItem}
        {endDateItem}
        {<span style={styles.contentItemInfo}><Run /></span>}&nbsp;<span>{energy}</span>
        <br />
        {<span style={styles.contentItemInfo}><WeatherWindy /></span>}&nbsp;<span>{season}</span>
        <br />
        {eventDescItem}
        <br />
        {wikiItem}
        <br />
        {instagramPostItem}
        <br />
        {linkItem}
      </div>
    );
  }

  getSeasonString(season) {
    const {t} = this.props;
    let string;
    switch (season) {
      case 0:
        string = t('add_event_info_event_season_na');
        break;
      case 1:
        string = t('add_event_info_event_season_spring');
        break;
      case 2:
        string = t('add_event_info_event_season_summer');
        break;
      case 3:
        string = t('add_event_info_event_season_autumn');
        break;
      case 4:
        string = t('add_event_info_event_season_winter');
        break;
      case 5:
        string = t('add_event_info_event_season_spring_summer');
        break;
      case 6:
        string = t('add_event_info_event_season_summer_autumn');
        break;
      case 7:
        string = t('add_event_info_event_season_autumn_winter');
        break;
      case 8:
        string = t('add_event_info_event_season_winter_spring');
        break;
      default:
        break;
    }
    return t('event_preview_season') + ': ' + string;
  }

  getEnergyString(energy) {
    const {t} = this.props;
    let string;
    switch (energy) {
      case 10:
        string = t('event_preview_energy_small');
        break;
      case 50:
        string = t('event_preview_energy_medium');
        break;
      case 90:
        string = t('event_preview_energy_large');
        break;
      default:
        string = energy;
        break;
    }
    return t('event_preview_energy') + ': ' + string;
  }

  getDateString(date) {
    // ensure date comes as 01, 09 etc
    let DD = ('0' + date.getDate()).slice(-2);
    let MM = ('0' + (date.getMonth() + 1)).slice(-2);
    let YYYY = date.getFullYear();
    return YYYY + '-' + MM + '-' + DD;
  }

  getMealString(isIncludeMeal) {
    const {t} = this.props;
    let meal = '';
    switch (isIncludeMeal) {
      case 0:
        meal = t('add_event_info_no_meal');
        break;
      case 1:
        meal = t('add_event_info_meal_lunch');
        break;
      case 2:
        meal = t('add_event_info_meal_dinner');
        break;
      case 3:
        meal = t('add_event_info_meal_lunch_dinner');
        break;
      case 4:
        meal = t('add_event_info_meal_breakfast');
        break;
      case 5:
        meal = t('add_event_info_meal_breakfast_lunch');
        break;
      case 6:
        meal = t('add_event_info_meal_breakfast_dinner');
        break;
      case 7:
        meal = t('add_event_info_meal_breakfast_lunch_dinner');
        break;
      default:
        break;
    }
    return meal;
  }

  getStayHourString(time) {
    const {t} = this.props;
    return time / 3600 + ' ' + t('event_preview_hours');
  }

  getTimeHourString(time) {
    let timeHour = ((time / 3600) + '').replace('.5', ':30');
    if (!timeHour.endsWith(':30')) {
      timeHour += ':00';
    }
    if (timeHour.length === 4) {
      timeHour = '0' + timeHour;
    }
    return timeHour;
  }

  renderGoogleMaps(lat, lng) {
    if (!lat || !lng) {
      return;
    }
    let src = 'https://www.google.com/maps/embed/v1/place?q=' + lat + '%2C' + lng +
      '&key=AIzaSyDMNoNm0VKMp9LI-hrBOQEFbhTILN2QM1M';
    return (
      <div>
        <iframe width="100%" height="350" frameBorder="0" src={src}></iframe>
      </div>
    );
  }

  renderSubmitButton(text, submitFunction, color, backgroundColor) {
    let style = styles.submitBtn;
    if (backgroundColor) {
      style = {...style, backgroundColor: backgroundColor};
    }
    return (
      <Button
        style={style}
        variant="contained"
        color={color || 'primary'}
        onClick={submitFunction}>
        <div style={styles.submitBtnContent}>
          {text}
        </div>
      </Button>
    );
  }
}

const styles = {
  title: {
    marginLeft: 8
  },
  coverImage: {
    width: '100%',
    maxWidth: 600,
    resizeMode: 'cover',
    marginTop: 8,
  },
  listItemInfo: {
    color: '#3984ff',
    marginLeft: 4
  },
  contentItemInfo: {
    color: '#27c3b0',
    marginLeft: 4
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  disapproveReasonTextField: {
    width: '100%',
    marginBottom: 8
  }
};

export default withTranslation()(EventPreviewView);
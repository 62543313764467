class MapsUtil {

  getGoogleLatLng(address) {
    let url = "https://maps.googleapis.com/maps/api/geocode/json?address=" + address +
      "&key=AIzaSyCvBZUvqg61sbcqiq2yKVRox6BasmQtgf0";
    return new Promise((resolve, reject) => {
      let xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = () => {
        if (xmlHttp.readyState === 4 && xmlHttp.status === 200) {
          let responseJson = JSON.parse(xmlHttp.responseText);
          if (responseJson.status === "ZERO_RESULTS") {
            reject();
            return;
          }
          let location = responseJson.results[0].geometry.location;
          let lat = location.lat;
          let lng = location.lng;

          resolve({lat: lat, lng: lng});
        }
      };
      xmlHttp.open("GET", url, true);
      xmlHttp.send(null);
    });
  }


}

let _instance = new MapsUtil();

export default _instance;

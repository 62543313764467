import {AppBar, Toolbar, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import AdsPreviewView from '../ui/AdsPreviewView';
import GeneralDialog from '../ui/GeneralDialog';
import LoadingDialog from '../ui/LoadingDialog';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import {REDIRECT_TO_MY_EVENT} from './AddEventScreen';

const BG_IMAGE = require('../res/img/blur_bg.png');
const ADS_PAGE_ITEM = 10;

class ViewMyAdsScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowDeleteDialog: false,
      isShowLoadingDialog: false,
      totalPendingCount: 0,
      currentPendingPage: 0,
      totalCount: 0,
      currentPage: 0,
      isUserSelfPublisher: false,
      isShowEditDisapproveWarningDialog: false,
      redirectToPath: undefined
    };
    this.deleteAdsId = null;
    this.deletePendingAdsId = null;
    this.editPath = null;
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft />
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t('view_my_ads_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <div style={styles.titleContainer}>
            <span style={styles.contentTitle}>{t('view_my_ads_pending')}</span>

            <Button
              style={styles.addNewBtn}
              variant="contained"
              color="primary">
              <Link to="/ads/add" style={styles.link}>
                <div style={styles.addNewBtnContent}>
                  <Plus />
                  {t('view_my_ads_add_new')}
                </div>
              </Link>
            </Button>
          </div>

          {this.renderPendingAds()}
          <span style={styles.contentTitle}>{t('view_my_ads_approved')}</span>
          {this.renderApprovedAds()}
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
        {this.renderDeleteDialog()}
        {this.renderEditDisapproveWarningDialog()}
        {this.renderAdsPreview()}
        {this.renderRedirectTo()}
      </div>
    );
  }

  async componentDidMount() {
    this.setState({isUserSelfPublisher: await ParseUtil.isUserSelfPublisher()});
    await this.loadPendingData(0);
    await this.loadApprovedData(0);
  }

  async loadApprovedData(skip) {
    let count = await ParseUtil.getAdsCount(true);
    let results = await ParseUtil.getAllAds(ADS_PAGE_ITEM, skip);
    this.setState({approvedData: results, totalCount: count});
  }

  async loadPendingData(skip) {
    let count = await ParseUtil.getAdsPendingCount(true);
    console.log('pending count: ' + count);
    let results = await ParseUtil.getAllPendingAds(ADS_PAGE_ITEM, skip);
    this.setState({pendingData: results, totalPendingCount: count});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  renderRedirectTo() {
    if (this.state.redirectToPath) {
      return (
        <Redirect to={this.state.redirectToPath} />
      );
    }
  }

  renderDeleteDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowDeleteDialog}
        dialogContent={t('view_my_ads_delete_desc')}
        dialogTitle={t('view_my_ads_delete_title')}
        onCancelClick={() => {
          this.setState({isShowDeleteDialog: false});
        }}
        onClick={() => {
          this.setState({isShowDeleteDialog: false});
          this.deleteAds();
        }} />
    );
  }

  renderEditDisapproveWarningDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowEditDisapproveWarningDialog}
        dialogContent={t('view_my_ads_edit_disapprove_warning')}
        dialogTitle={t('view_my_ads_table_action_edit')}
        onCancelClick={() => {
          this.setState({isShowEditDisapproveWarningDialog: false});
        }}
        onClick={() => {
          this.setState({
            isShowEditDisapproveWarningDialog: false,
            redirectToPath: this.editPath
          });
        }} />
    );
  }

  async deleteAds() {
    await this.setStateAsync({isShowLoadingDialog: true});
    if (this.deletePendingAdsId) {
      // delete pending ads
      await ParseUtil.deletePendingAds(this.deletePendingAdsId);
      await this.loadPendingData(this.state.currentPendingPage * ADS_PAGE_ITEM);
    } else if (this.deleteAdsId) {
      // delete normal ads
      if (ParseUtil.isUserAdmin()) {
        await ParseUtil.deleteAds(this.deleteAdsId);
      } else {
        await ParseUtil.deleteAdsForUserByCloudCode(this.deleteAdsId);
      }
      await this.loadApprovedData(this.state.currentPage * ADS_PAGE_ITEM);

    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  renderApprovedAds() {
    return this.renderAds(this.state.approvedData, false);
  }

  renderPendingAds() {
    return this.renderAds(this.state.pendingData, true);
  }

  renderAds(data, isPending) {
    if (!data) {
      return;
    }
    const {t} = this.props;
    let dataContentCells = [];
    let disapproveReasonTitleCell;
    if (isPending) {
      disapproveReasonTitleCell = (
        <TableCell style={styles.tableCell}>{t('view_my_ads_disapprove_reason')}</TableCell>
      );
    }

    for (let i = 0; i < data.length; i++) {
      let rowStyle = i % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;
      let object = data[i];

      let countryLocation = object.get('countryLocation');
      let country = ParseUtil.getNameValuesForDisplay(countryLocation, 'name', LANG_CODES);
      let adsLink = object.get('link');
      let image = object.get('image');
      let imageUrl = image ? image.url() : '';
      if (window.location.hostname?.indexOf('gogoeasy') > -1) {
        // gogoeasy
        imageUrl = imageUrl.replace('trip.atomin', 'api.gogo');
        imageUrl = imageUrl.replace('vention', 'easy');
      } else {
        // wisely go
        imageUrl = imageUrl.replace('trip.atomin', 'api.wisely');
        imageUrl = imageUrl.replace('vention', 'go');
      }
      let title = object.get('title');
      let description = object.get('description');
      let disapproveReason = object.get('disapproveReason');
      let createDate = object.get('createdAt');
      let NinetyDaysMillis = 90 * 24 * 60 * 60 * 1000;
      let expiryTimeMillis = new Date(createDate).getTime() + NinetyDaysMillis;
      let expiryDate = isPending ? '---' : new Date(expiryTimeMillis);

      let deleteLink = (
        <Link onClick={() => {
          if (isPending) {
            this.deletePendingAdsId = object.id;
            this.deleteAdsId = null;
          } else {
            this.deleteAdsId = object.id;
            this.deletePendingAdsId = null;
          }
          this.setState({isShowDeleteDialog: true});
        }}>
          {t('view_my_ads_table_action_delete')}
        </Link>
      );
      let editLink;
      // user without role (i.e. self reg) can edit ads
      if (this.state.isUserSelfPublisher) {
        let editPath = '/ads/edit/' + (isPending ? 1 : 0) + '/' + object.id + '/' + REDIRECT_TO_MY_EVENT;
        if (isPending) {
          editLink = (
            <div>
              <Link to={editPath}>
                {t('view_my_ads_table_action_edit')}
              </Link>
              <br /><br />
            </div>
          );
        } else {
          editLink = (
            <div>
              <Link onClick={() => {
                this.editPath = editPath;
                this.setState({isShowEditDisapproveWarningDialog: true});
              }}>
                {t('view_my_ads_table_action_edit')}
              </Link>
              <br /><br />
            </div>
          );
        }
      }

      let disapproveReasonCell;
      if (isPending) {
        disapproveReasonCell = (
          <TableCell style={{...styles.tableCell, color: '#ff4714'}}>{disapproveReason}</TableCell>
        );
      }

      dataContentCells.push(
        <TableRow style={rowStyle}>
          <TableCell style={styles.tableCell}>
            <a href={adsLink} target='_blank' rel="noopener noreferrer">
              <span dangerouslySetInnerHTML={{__html: adsLink}} />
            </a>
          </TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: country}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: title}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: description}} /></TableCell>
          <TableCell style={styles.tableCell}>
            <img style={styles.adsIcon} src={imageUrl} />
          </TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: expiryDate}} /></TableCell>
          {disapproveReasonCell}

          <TableCell style={styles.tableCell}>
            <div>
              <Link onClick={() => {
                if (isPending) {
                  this.setState({previewPendingAdsId: object.id, previewAdsId: null});
                } else {
                  this.setState({previewAdsId: object.id, previewPendingAdsId: null});
                }
              }}>
                {t('view_my_ads_table_action_preview')}
              </Link>
              <br /><br />
              {/* {editLink} */}
              {deleteLink}
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableContainer component={Paper} style={styles.table} ref={(ref) => {
        if (isPending) {
          this.pendingAdsTableRef = ref;
        } else {
          this.AdsTableRef = ref;
        }
      }}>
        <Table stickyHeader aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>{t('add_ads_link')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_ads_location_country')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_ads_ads_title')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_ads_description')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_ads_image')}</TableCell>
              <TableCell style={styles.tableCell}>{t('view_my_ads_expiry_date')}</TableCell>
              {disapproveReasonTitleCell}
              <TableCell style={styles.tableCell}>{t('view_my_ads_table_action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContentCells}
          </TableBody>
          {this.renderAdsTableFooter(data, isPending)}
        </Table>
      </TableContainer>
    );
  }

  renderAdsTableFooter(data, isPending) {
    let unknownCount = 99999999;
    let count = isPending ? this.state.totalPendingCount : this.state.totalCount;
    if (count === -1) {
      if (data.length % ADS_PAGE_ITEM === 0) {
        // there may be more than one page
        count = unknownCount;
      } else {
        // we know the exact count by calculation
        count = this.state.currentPage * ADS_PAGE_ITEM + data.length;
      }
    }
    let page = isPending ? this.state.currentPendingPage : this.state.currentPage;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[ADS_PAGE_ITEM]}
            colSpan={3}
            count={count}
            rowsPerPage={ADS_PAGE_ITEM}
            page={page}
            labelDisplayedRows={({from, to, count}) => {
              if (count === unknownCount) {
                return `Page: ${page + 1}, Row: ${from}-${to === -1 ? count : to}`;
              } else {
                return `Page: ${page + 1}, Row: ${from}-${to === -1 ? count : to} of ${count}`;
              }
            }}
            onChangePage={async (ads, page) => {
              await this.setStateAsync({isShowLoadingDialog: true});
              if (isPending) {
                this.setState({currentPendingPage: page});
                await this.loadPendingData(page * ADS_PAGE_ITEM);
              } else {
                this.setState({currentPage: page});
                await this.loadApprovedData(page * ADS_PAGE_ITEM);
              }
              await this.setStateAsync({isShowLoadingDialog: false});
              await this.scrollToTable(isPending);
            }}
          />
        </TableRow>
      </TableFooter>
    );
  }

  async scrollToTable(isPending) {
    await new Promise(r => setTimeout(r, 100));
    if (this.pendingAdsTableRef && this.AdsTableRef) {
      if (isPending) {
        this.pendingAdsTableRef.scrollIntoView({behavior: 'smooth', block: 'start'});
      } else {
        this.AdsTableRef.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }

  renderAdsPreview() {
    return (
      <div>
        <Dialog open={this.state.previewPendingAdsId || this.state.previewAdsId}>
          <AdsPreviewView pendingAdsId={this.state.previewPendingAdsId}
            adsId={this.state.previewAdsId}
            viewOnly={true}
            onCloseClick={() => {
              this.setState({previewPendingAdsId: null, previewAdsId: null});
            }} />
        </Dialog>
      </div>
    );
  }
}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  addNewBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  addNewBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  tableRowOdd: {
    backgroundColor: '#f5f5f5',
  },
  tableRowEven: {
    backgroundColor: '#f6ffff'
  },
  tableCell: {
    borderWidth: 1,
    borderColor: '#efefef',
    borderStyle: 'solid',
    minWidth: 35
  },
  adsIcon: {
    height: 50
  }
};

export default withTranslation()(ViewMyAdsScreen);
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AccountCircle, FacebookBox, Google, Login} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import LoadingDialog from '../ui/LoadingDialog';
import TermsDialog from '../ui/TermsDialog';
import UsernameLoginDialog from '../ui/UsernameLoginDialog';
import UsernameRegisterDialog from '../ui/UsernameRegisterDialog';
import AccountUtil from '../util/AccountUtil';
import AppConfig from '../util/AppConfig';
import AppLog from '../util/AppLog';
import EventBus from '../util/EventBus';
import ParseUtil from '../util/ParseUtil';


const ENABLE_SSO = true;
const ENABLE_EMAIL_REGISTER = false;

class LoginScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      termsDialogOpened: false,
      privacyDialogOpened: false,
      usernameDialogOpened: false,
      redirectToHome: false,
      isShowErrorDialog: false,
      errorDialogContent: null,
      isShowLoadingDialog: false,
      isCheckingSession: true,
      usernameRegisterDialogOpened: false,
      loginAppNameRes: 'login_app_name'
    };
  }

  render() {
    if (this.state.isCheckingSession) {
      return (<div></div>);
    }
    const {t} = this.props;
    let appName = t(this.state.loginAppNameRes);
    if (AppConfig.isDev()) {
      appName = appName + ' (DEV server)';
    }
    return (
      <div style={styles.rootContainer}>
        <div style={styles.contentContainer}>
          <img style={styles.appLogo} src={require('../res/img/app_logo.jpg')} alt='' />
          <br />
          <span style={styles.appName}>{appName}</span>
          <span style={styles.appTitle}>{t('login_title')}</span>
          <span style={styles.appLoginReg}>{t('login_register_login')}</span>

          {this.renderSsoLoginMethods()}

          <Button style={styles.loginButton} variant="contained" onClick={this.openUsernameDialog.bind(this)}
            startIcon={<Login />}>{t('login_username')}
          </Button>

          {this.renderUsernameRegisterMethod()}

          {this.renderTermsButton()}
        </div>

        <TermsDialog open={this.state.termsDialogOpened} onConfirm={this.closeTermsDialog.bind(this)} />
        <TermsDialog isPrivacy={true} open={this.state.privacyDialogOpened}
          onConfirm={this.closePrivacyDialog.bind(this)} />

        {this.renderUsernameLoginDialog()}
        {this.renderUsernameRegisterDialog()}

        {this.renderRedirectToHome()}

        {this.renderErrorDialog()}
        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
      </div>
    );
  }

  renderUsernameRegisterMethod() {
    if (!ENABLE_EMAIL_REGISTER) {
      return;
    }
    return (
      <Button style={{...styles.loginButton, backgroundColor: '#ff9514', marginTop: 48}} variant="contained"
        onClick={this.openUsernameRegisterDialog.bind(this)}
        startIcon={<AccountCircle />}>{this.props.t('login_register')}
      </Button>
    );
  }

  renderUsernameRegisterDialog() {
    return (
      <UsernameRegisterDialog open={this.state.usernameRegisterDialogOpened}
        onCancel={this.closeUsernameRegisterDialog.bind(this)}
        onConfirm={(email, password) => {
          this.closeUsernameRegisterDialog();
          // todo register
        }} />
    );
  }

  renderUsernameLoginDialog() {
    return (
      <UsernameLoginDialog open={this.state.usernameDialogOpened} onCancel={this.closeUsernameDialog.bind(this)}
        onConfirm={(username, password) => {
          this.closeUsernameDialog();
          this.loginByUsername(username, password);
        }} />
    );
  }

  renderTermsButton() {
    const {t} = this.props;
    return (
      <div style={styles.termsContainer}>
        <span style={styles.terms}>
          <Button onClick={this.openTermsDialog.bind(this)} disableRipple={true} style={styles.termsButton}>
            {t('login_t_and_c')}
          </Button>
        </span>
        <span style={styles.terms}>
          <Button onClick={this.openPrivacyDialog.bind(this)} disableRipple={true} style={styles.termsButton}>
            {t('login_privacy')}
          </Button>
        </span>
      </div>
    );
  }

  renderSsoLoginMethods() {
    if (!ENABLE_SSO || !this.isGoGoEasyDomain()) {
      return;
    }
    const {t} = this.props;
    return (
      <div style={styles.contentContainer}>

        <Button style={{...styles.loginButton, backgroundColor: '#216eff'}}
          onClick={this.onFacebookLoginBtnClick.bind(this)}
          variant="contained" startIcon={<FacebookBox />}>
          Facebook
        </Button>

        <Button style={{...styles.loginButton, backgroundColor: '#eb0d09'}}
          onClick={this.onGoogleLoginBtnClick.bind(this)}
          variant="contained" startIcon={<Google />}>
          Google
        </Button>

        <div style={styles.loginOrContainer}>
          <div style={styles.loginOrSeparator} />
          <span style={styles.loginOr}>{t('login_or')}</span>
          <div style={styles.loginOrSeparator} />
        </div>
      </div >
    );
  }

  async componentDidMount() {
    const {t} = this.props;
    document.title = t('login_title');

    // check user session exist
    if (await ParseUtil.checkLocalSessionAndLogin()) {
      this.updateRoutesForLogin();
      this.openHomeScreen();
    } else {
      // show login ui
      this.setState({
        isCheckingSession: false,
        loginAppNameRes: this.isGoGoEasyDomain() ? 'login_app_name_public' : 'login_app_name'
      });
    }
  }

  isGoGoEasyDomain() {
    return window.location.hostname?.indexOf('gogoeasy') > -1;
  }

  renderRedirectToHome() {
    if (this.state.redirectToHome) {
      return (
        <Redirect to="/home" />
      );
    }
  }

  renderErrorDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowErrorDialog}>
        <DialogTitle>{t('general_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.errorDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async openHomeScreen() {
    await new Promise(r => setTimeout(r, 500));
    this.setState({redirectToHome: true});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async loginByUsername(username, password) {
    AppLog.log(`username: ${username} password: ${password}`);
    await this.setStateAsync({isShowLoadingDialog: true});
    let user = await ParseUtil.login(username, password);
    await this.setStateAsync({isShowLoadingDialog: false});
    this.checkUserAndRedirectLogin(user);
  }

  checkUserAndRedirectLogin(user) {
    if (user) {
      this.updateRoutesForLogin();
      this.openHomeScreen();
    } else {
      // login error
      this.setState({isShowErrorDialog: true, errorDialogContent: this.props.t('login_error_username_password')});
    }
  }

  updateRoutesForLogin() {
    EventBus.post({key: EventBus.getKey().LOGIN_STATE_CHANGED});
  }

  openTermsDialog() {
    this.setState({termsDialogOpened: true});
  }

  closeTermsDialog() {
    this.setState({termsDialogOpened: false});
  }

  openPrivacyDialog() {
    this.setState({privacyDialogOpened: true});
  }

  closePrivacyDialog() {
    this.setState({privacyDialogOpened: false});
  }

  openUsernameDialog() {
    this.setState({usernameDialogOpened: true});
  }

  closeUsernameDialog() {
    this.setState({usernameDialogOpened: false});
  }

  openUsernameRegisterDialog() {
    this.setState({usernameRegisterDialogOpened: true});
  }

  closeUsernameRegisterDialog() {
    this.setState({usernameRegisterDialogOpened: false});
  }

  async onFacebookLoginBtnClick() {
    await this.setStateAsync({isShowLoadingDialog: true});
    try {
      let response = await AccountUtil.loginToParseByFacebook();
      this.checkUserAndRedirectLogin(response.user);
    } catch (e) {
      AppLog.log('fb login failed');
      AppLog.log(e);
      this.setState({isShowErrorDialog: true, errorDialogContent: this.props.t('login_error_try_again')});
    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  async onGoogleLoginBtnClick() {
    await this.setStateAsync({isShowLoadingDialog: true});
    try {
      let response = await AccountUtil.loginToParseByGoogle();
      this.checkUserAndRedirectLogin(response.user);
    } catch (e) {
      AppLog.log('google login failed');
      AppLog.log(e);
      this.setState({isShowErrorDialog: true, errorDialogContent: this.props.t('login_error_try_again')});
    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }

}

const BG_IMAGE = require('../res/img/blur_bg.png');

const styles = {
  rootContainer: {
    minHeight: '100vh',
    display: 'flex',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  appLogo: {
    marginTop: '5vh',
    width: 130,
    height: 130,
    borderRadius: 20
  },
  appName: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  appTitle: {
    fontSize: 18
  },
  appLoginReg: {
    marginTop: 24,
    fontSize: 28,
    marginBottom: 20
  },
  loginOrContainer: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginOr: {
    fontSize: 20,
    color: '#c3c3c3'
  },
  loginOrSeparator: {
    backgroundColor: '#c3c3c3',
    width: '100%',
    maxWidth: 180,
    height: 1,
    marginLeft: 20,
    marginRight: 20
  },
  loginButton: {
    marginTop: 24,
    backgroundColor: '#27c3b0',
    color: 'white',
    width: '70vw',
    maxWidth: 400,
    borderRadius: 24,
    height: 48,
    fontSize: 16
  },
  termsContainer: {
    marginTop: 30,
    marginBottom: 30
  },
  terms: {
    fontSize: 14,
    textDecorationLine: 'underline'
  },
  termsButton: {
    backgroundColor: '#00000000',
    textTransform: 'none'
  }
};
export default withTranslation()(LoginScreen);
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link, Redirect} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import ParseUtil, {LANG_CODES, LANG_CODES_DESC} from '../util/ParseUtil';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import EventBus from '../util/EventBus';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

const BG_IMAGE = require('../res/img/blur_bg.png');

class AdminAddProvinceScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      countryLabelWidth: 0, areaLabelWidth: 0, countryData: null, areaData: null, nameValues: {},
      isShowLoadingDialog: false, isShowErrorDialog: false, errorDialogContent: null, redirectToProvince: false,
      selectedCountryValue: '', selectedAreaValue: ''
    };
    this.countryLabel = null;
    this.areaLabel = null;
    this.editProvinceId = this.props.match.params.id;
  }

  async componentDidMount() {
    if (this.countryLabel) {
      this.setState({countryLabelWidth: this.countryLabel.offsetWidth});
    }
    if (this.areaLabel) {
      this.setState({areaLabelWidth: this.areaLabel.offsetWidth});
    }
    await this.loadCountry();
    await this.loadProvince();
  }

  async loadProvince() {
    let provinceId = this.editProvinceId;
    if (!provinceId) {
      return;
    }
    let provinceObject = await ParseUtil.getProvince(provinceId);
    if (provinceObject) {
      // pre fill items
      let nameValues = {};
      for (let i = 0; i < LANG_CODES.length; i++) {
        let field = 'name' + LANG_CODES[i];
        nameValues[field] = provinceObject.get(field);
      }

      let countryLocation = provinceObject.get('countryLocation');
      let areaLocation = provinceObject.get('areaLocation');

      this.setState({
        nameValues: nameValues,
        selectedCountryValue: countryLocation.id,
        selectedAreaValue: areaLocation.id
      }, async () => {
        await this.loadArea();
      });
    }
  }

  async loadCountry() {
    let results = await ParseUtil.getAllCountry();
    this.setState({countryData: results});
  }

  async loadArea() {
    let results = await ParseUtil.getAreas(this.state.selectedCountryValue);
    this.setState({areaData: results});
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/superadmin/province" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft/>
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t(this.editProvinceId ? 'admin_add_province_edit_title' : 'admin_add_province_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <span style={styles.contentTitle}>
            {t(this.editProvinceId ? 'admin_add_province_edit_title' : 'admin_add_province_title')}
          </span>
          {this.renderForm()}
        </div>

        {this.renderErrorDialog()}
        {this.renderLoadingDialog()}
        {this.renderRedirectToProvince()}
      </div>
    );
  }

  renderRedirectToProvince() {
    if (this.state.redirectToProvince) {
      return (
        <Redirect to="/superadmin/province"/>
      );
    }
  }

  renderForm() {
    return (
      <form noValidate autoComplete="off" style={styles.form}>
        {this.renderNameTextFields()}
        <br/>
        {this.renderCountrySelect()}
        <br/>
        <br/>
        {this.renderAreaSelect()}
        <br/>
        <br/>
        <br/>
        {this.renderSubmitButton()}
      </form>
    );
  }

  renderSubmitButton() {
    const {t} = this.props;
    return (
      <Button
        style={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={this.validateInputAndSubmit.bind(this)}>
        <div style={styles.submitBtnContent}>
          <Plus/>
          {t('admin_add_province_submit')}
        </div>
      </Button>
    );
  }

  validateInputAndSubmit() {
    const {t} = this.props;
    // check name values
    let keys = Object.keys(this.state.nameValues);
    let isMissingName = true;
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.state.nameValues[key]) {
          isMissingName = false;
          break;
        }
      }
    }
    if (isMissingName) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_province_missing_name')});
      return;
    }
    // check country
    if (!this.state.selectedCountryValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_province_missing_country')});
      return;
    }
    // check area
    if (!this.state.selectedAreaValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_province_missing_area')});
      return;
    }

    this.setState({isShowLoadingDialog: true}, async () => {
      // submit to server
      let province = await ParseUtil.saveProvince(this.state.nameValues, this.state.selectedCountryValue,
        this.state.selectedAreaValue, this.editProvinceId);
      if (province) {
        // submit success
        EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
        this.setState({isShowLoadingDialog: false, redirectToProvince: true});
      } else {
        // submit failed
        this.setState({
          isShowLoadingDialog: false, isShowErrorDialog: true,
          errorDialogContent: t('general_add_item_fail')
        });
      }
    });
  }

  renderAreaSelect() {
    const {t} = this.props;
    let menuItems = [];
    if (this.state.areaData) {
      for (let i = 0; i < this.state.areaData.length; i++) {
        let object = this.state.areaData[i];
        menuItems.push(
          <MenuItem value={object.id}>{ParseUtil.getNameForCurrentLocale(object, 'name')}</MenuItem>
        );
      }
    }
    return (
      <FormControl variant="outlined" style={styles.formControl}>
        <InputLabel ref={input => {
          this.areaLabel = input;
        }} id="area_label">{t('admin_add_province_area')}</InputLabel>
        <Select
          labelWidth={this.state.areaLabelWidth}
          labelId="area_label"
          value={this.state.selectedAreaValue}
          onChange={event => {
            this.setState({selectedAreaValue: event.target.value}, async () => {
            });
          }}>
          <MenuItem value="" disabled>{t('general_input_please_select')}</MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }

  renderCountrySelect() {
    const {t} = this.props;
    let menuItems = [];
    if (this.state.countryData) {
      for (let i = 0; i < this.state.countryData.length; i++) {
        let object = this.state.countryData[i];
        menuItems.push(
          <MenuItem value={object.id}>{ParseUtil.getNameForCurrentLocale(object, 'name')}</MenuItem>
        );
      }
    }
    return (
      <FormControl variant="outlined" style={styles.formControl}>
        <InputLabel ref={input => {
          this.countryLabel = input;
        }} id="country_label">{t('admin_add_province_country')}</InputLabel>
        <Select
          labelWidth={this.state.countryLabelWidth}
          labelId="country_label"
          value={this.state.selectedCountryValue}
          onChange={event => {
            this.setState({selectedCountryValue: event.target.value}, async () => {
              await this.loadArea();
            });
          }}>
          <MenuItem value="" disabled>{t('general_input_please_select')}</MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    );
  }

  renderNameTextFields() {
    const {t} = this.props;
    let nameTextFields = [];
    for (let i = 0; i < LANG_CODES.length; i++) {
      let key = 'name' + LANG_CODES[i];
      nameTextFields.push(
        <TextField style={styles.inputField} id={key}
                   InputLabelProps={{shrink: true}} variant="outlined"
                   value={this.state.nameValues[key]}
                   onChange={event => {
                     let values = this.state.nameValues;
                     values[key] = event.target.value;
                     this.setState({nameValues: values});
                   }}
                   label={t('admin_add_province_name') + ' (' + t(LANG_CODES_DESC[i]) + ')'}/>
      );
      nameTextFields.push(<br/>);
      nameTextFields.push(<br/>);
    }
    return nameTextFields;
  }

  renderLoadingDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowLoadingDialog}>
        <DialogContent>
          <DialogContentText>
            {t('general_loading')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowErrorDialog}>
        <DialogTitle>{t('general_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.errorDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  form: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30
  },
  inputField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  }
};

export default withTranslation()(AdminAddProvinceScreen);
import {AppBar, Button, InputLabel, TextField, Toolbar, Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus, Upload} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import FormSelect from '../ui/FormSelect';
import GeneralDialog from '../ui/GeneralDialog';
import ImageUtil from '../util/ImageUtil';
import ParseUtil from '../util/ParseUtil';

const BG_IMAGE = require('../res/img/blur_bg.png');

class AddAdsScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowLoadingDialog: false
    };
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft />
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t(this.editEventId ? 'add_event_edit_title' : 'add_ads_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          {this.renderContent()}
        </div>

        {this.renderErrorDialog()}
        {this.renderLoadingDialog()}
        {this.renderSuccessDialog()}
      </div>
    );
  }

  async componentDidMount() {
    await this.loadCountry();
  }

  async loadCountry() {
    let results = await ParseUtil.getAllCountry();
    this.setState({countryData: results});
  }

  renderContent() {
    const {t} = this.props;
    return (
      <div style={styles.contentDiv}>
        <div style={styles.contentTitle}>{t('add_ads_input_info')}</div>
        {this.renderLocationCountry()}
        <br />
        {this.renderAdsLinkField()}
        <br />
        {this.renderAdsTitleField()}
        <br />
        {this.renderPromotionalTextField()}
        <br />
        {this.renderPhotoUpload()}
        <br />
        <br />
        <br />
        {this.renderSubmitButton()}
      </div>
    );
  }

  renderSubmitButton() {
    const {t} = this.props;
    return (
      <Button
        style={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={this.validateInputAndSubmit.bind(this)}>
        <div style={styles.submitBtnContent}>
          <Plus />
          {t('add_ads_submit')}
        </div>
      </Button>
    );
  }

  validateInputAndSubmit() {
    const {t} = this.props;
    if (!this.state.adsLink?.trim()) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_ads_missing_link')});
      return;
    }
    if (!this.state.adsTitle?.trim()) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_ads_missing_title')});
      return;
    }
    if (!this.state.adsImageFile) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_ads_missing_image')});
      return;
    }
    if (!this.state.selectedCountryValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_ads_missing_country')});
      return;
    }

    // validate link
    let link = this.state.adsLink.toLowerCase();
    try {
      let hostname = new URL(link).hostname.split(".").slice(-2).join(".");
      let isLinkValid = link.startsWith('http') && hostname?.length > 3;
      if (!isLinkValid) {
        throw new Error('Ads link not valid');
      }
    } catch (e) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_ads_invalid_link')});
      return;
    }

    this.setState({isShowLoadingDialog: true}, async () => {
      // submit to server
      let file = this.state.adsImageFile[0];
      let imageBase64 = await this._resizeImage(file);

      let adsInfo = {
        title: this.state.adsTitle?.trim(),
        link: this.state.adsLink?.trim(),
        countryId: this.state.selectedCountryValue,
        description: this.state.promotionalText?.trim(),
        imageBase64: imageBase64
      };
      let ads = await ParseUtil.saveAds(adsInfo);
      if (ads) {
        // submit success
        this.setState({isShowLoadingDialog: false, isShowSuccessDialog: true});
      } else {
        // submit failed
        this.setState({
          isShowLoadingDialog: false, isShowErrorDialog: true,
          errorDialogContent: t('general_add_item_fail')
        });
      }
    });
  }

  _resizeImage(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve();
        return;
      }
      let img = new Image();
      img.onload = () => {
        let data = ImageUtil.scaleImage(img, {maxWidth: 500, quality: 0.8});
        resolve(data);
      };
      img.src = window.URL.createObjectURL(file);
    });
  }

  renderSuccessDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowSuccessDialog}
        dialogContent={t('general_operation_success')}
        dialogTitle={''}
        onClick={() => {
          window.location.reload();
        }}
      />
    );
  }

  renderLoadingDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowLoadingDialog}>
        <DialogContent>
          <DialogContentText>
            {t('general_loading')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowErrorDialog}>
        <DialogTitle>{t('general_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.errorDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderPhotoUpload() {
    const {t} = this.props;

    let displayName = t('add_ads_image_upload');
    if (this.state.adsImageFile) {
      displayName = this.state.adsImageFile[0].name;
    }
    return (
      <div>
        <input
          ref={(adsImageFileUploadBtn) => {
            this.adsFileUploadBtn = adsImageFileUploadBtn;
          }}
          type='file'
          accept="image/*"
          style={{visibility: 'hidden'}}
          onChange={(event) => {
            this.setState({adsImageFile: event.target.files});
          }} />
        <InputLabel>{t('add_ads_image')}</InputLabel>
        <Button
          style={styles.uploadBtn}
          variant="contained"
          color="secondary"
          onClick={() => {
            this.adsFileUploadBtn.click();
          }}>
          <div style={styles.uploadBtnContent}>
            <Upload />
            {displayName}
          </div>
        </Button>
      </div>
    );
  }

  renderAdsLinkField() {
    const {t} = this.props;
    return (
      <div>
        <br />
        {/* <InputLabel>{this.getSupportedLinkTitle()}</InputLabel> */}
        <br />
        <TextField key={'adsLink'}
          style={styles.inputField} id={'adsLink'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.adsLink}
          onChange={event => {
            this.setState({adsLink: event.target.value});
          }}
          label={t('add_ads_link')} />
      </div>
    );
  }

  renderAdsTitleField() {
    const {t} = this.props;
    return (
      <div>
        <TextField key={'adsTitle'}
          style={styles.inputField} id={'adsLink'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.adsTitle}
          onChange={event => {
            this.setState({adsTitle: event.target.value});
          }}
          inputProps={{
            maxLength: 100
          }}
          label={t('add_ads_ads_title')} />
      </div>
    );
  }

  renderPromotionalTextField() {
    const {t} = this.props;
    return (
      <div>
        <TextField style={styles.inputField} id={'promotionalText'}
          InputLabelProps={{shrink: true}} variant="outlined"
          label={t('add_ads_description')}
          value={this.state.promotionalText}
          multiline
          inputProps={{
            maxLength: 500
          }}
          onChange={event => {
            this.setState({promotionalText: event.target.value});
          }} />
      </div>
    );
  }

  renderLocationCountry() {
    const {t} = this.props;
    return (
      <div>
        <InputLabel>{t('add_ads_location_desc')}</InputLabel>
        <br />
        <FormSelect selectParseData={this.state.countryData}
          fieldName={'name'}
          labelTitle={t('add_ads_location_country')}
          selectedValue={this.state.selectedCountryValue}
          onChange={async (value) => {
            this.setState({selectedCountryValue: value});
          }}
        />
      </div>
    );
  }

  getSupportedLinkTitle() {
    return `${this.props.t('add_ads_link_supported')}: ${this.getSupportedDomain().join(', ')}`;
  }

  getSupportedDomain() {
    return [
      'youtube.com',
      'facebook.com',
      'instagram.com'
    ];
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    width: '100%'
  },
  contentDiv: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    marginBottom: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
  },
  inputField: {
    width: '100%'
  },
  uploadBtn: {
    width: '100%',
    marginTop: 8
  },
  uploadBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none'
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  }
};

export default withTranslation()(AddAdsScreen);
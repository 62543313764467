import {AppBar, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Toolbar, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {Email, Home, Logout} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import GeneralDialog from '../ui/GeneralDialog';
import LoadingDialog from '../ui/LoadingDialog';
import EventBus from '../util/EventBus';
import ParseUtil from '../util/ParseUtil';

const BG_IMAGE = require('../res/img/blur_bg.png');
const ADD_EVENT_IMAGE = require('../res/img/home_add_event_bg.jpg');
const VIEW_EVENT_IMAGE = require('../res/img/home_view_event_bg.jpg');
const APPROVE_EVENT_IMAGE = require('../res/img/home_approve_event.jpg');
const VIEW_PLACES_IMAGE = require('../res/img/home_view_places.jpg');
const VIEW_CATE_IMAGE = require('../res/img/home_view_cate.jpg');
const ADD_ADS_IMAGE = require('../res/img/home_add_event_bg.jpg');
const VIEW_ADS_IMAGE = require('../res/img/home_view_event_bg.jpg');
const APPROVE_ADS_IMAGE = require('../res/img/home_approve_event.jpg');

class HomeScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isParseUserAdmin: ParseUtil.isUserAdmin(), redirectToFront: false, isShowLoadingDialog: false,
      userPublicData: null
    };
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <Home />
            </IconButton>
            <Typography variant="h6" style={styles.appBarTitle}>
              {t('home_title')}
            </Typography>

            {this.renderLogoutBtn()}
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          {this.renderUserName()}
          {this.renderUserActions()}
          {this.renderEmailDialog()}
          {this.renderEmailInvalidDialog()}
          <span style={styles.contentTitle}>{t('home_event_management_system')}</span>

          <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
          {this.renderNormalFunctions()}
          {this.renderSuperAdminUis()}
          {this.renderRedirectToFrontPage()}
        </div>
      </div>
    );
  }

  renderUserName() {
    const {t} = this.props;
    let name = '';
    if (this.state.userPublicData) {
      name = t('home_welcome') + this.state.userPublicData.get('displayName');
    }
    return (
      <div>
        <br />
        <span style={styles.contentTitle}>{name}</span>
      </div>
    );
  }

  renderEmailDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowEmailDialog}
        onClose={() => {
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('home_register_email')}</DialogTitle>
        <DialogContent>
          <div>
            <TextField style={styles.dialogInputField} id="email" label={t('home_email')}
              value={this.state.setEmailValue}
              onChange={(event) => {
                this.setState({setEmailValue: event.target.value});
              }} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({isShowEmailDialog: false})} color="primary" autoFocus>
            {t('general_cancel')}
          </Button>
          <Button onClick={this.updateUserEmailToServer.bind(this)} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async updateUserEmailToServer() {
    // validate
    this.setState({isShowEmailDialog: false});
    let email = this.state.setEmailValue?.trim();
    if (!this.validateEmail(email)) {
      this.setState({isShowEmailInvalidDialog: true});
      return;
    }
    try {
      await this.setStateAsync({isShowLoadingDialog: true});
      await ParseUtil.setUserEmail(email);
      await ParseUtil.refreshUser();
      await this.setStateAsync({isShowLoadingDialog: false});
      EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: this.props.t('general_operation_success')}});
    } catch (e) {
      await ParseUtil.refreshUser();
      await this.setStateAsync({isShowLoadingDialog: false});
      EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: this.props.t('general_operation_fail')}});
    }
  }

  renderEmailInvalidDialog() {
    return (
      <GeneralDialog isShowDialog={this.state.isShowEmailInvalidDialog}
        dialogContent={this.props.t('home_email_invalid')}
        dialogTitle={this.props.t('general_error')}
        onClick={() => this.setState({isShowEmailInvalidDialog: false})} />
    );
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  renderUserActions() {
    // now we don't need send email anymore, just hide it
    return;
    // if (!this.state.isUserSelfPublisher) {
    //   return;
    // }
    // let email = ParseUtil.getUser()?.get('email') || this.props.t('home_email_empty');
    // return (
    //   <div style={styles.userActionsContainer}>
    //     <span style={styles.emailTitle}>{this.props.t('home_email') + ': ' + email}</span>
    //     <br />
    //     <br />
    //     <Button
    //       variant="contained"
    //       color="primary"
    //       onClick={() => this.setState({isShowEmailDialog: true})}>
    //       <div style={styles.emailBtnContent}>
    //         <Email />
    //         {this.props.t('home_register_email')}
    //       </div>
    //     </Button>
    //   </div>
    // );
  }

  renderLogoutBtn() {
    const {t} = this.props;
    return (
      <Button color="inherit" style={{textTransform: 'none'}} onClick={this.onLogoutClick.bind(this)}>
        <Logout />
        <Typography variant="h6">{t('home_logout')}</Typography>
      </Button>
    );
  }

  renderNormalFunctions() {
    const {t} = this.props;
    return (
      <div style={styles.itemsContainer}>
        <Link to="/event/add" style={styles.link}>
          {this.getNewItemButton(ADD_EVENT_IMAGE, t('home_item_new_event'), undefined, t('home_item_new_event_desc'))}
        </Link>
        <Link to="/event/view" style={styles.link}>
          {this.getNewItemButton(VIEW_EVENT_IMAGE, t('home_item_view_my_event'))}
        </Link>
        <Link to="/ads/add" style={styles.link}>
          {this.getNewItemButton(ADD_ADS_IMAGE, t('home_item_new_ads'), undefined, t('home_item_new_ads_desc'))}
        </Link>
        <Link to="/ads/view" style={styles.link}>
          {this.getNewItemButton(VIEW_ADS_IMAGE, t('home_item_view_my_ads'))}
        </Link>
      </div>
    );
  }

  renderSuperAdminUis() {
    if (!this.state.isParseUserAdmin) {
      return;
    }
    const {t} = this.props;
    return (
      <div>
        <span style={{...styles.contentTitle, marginTop: 0}}>{t('home_super_admin_functions')}</span>

        <div style={styles.itemsContainer}>
          <Link to="/superadmin/approve" style={styles.link}>
            {this.getNewItemButton(APPROVE_EVENT_IMAGE, t('home_item_approve_event'))}
          </Link>
          <Link to="/superadmin/approveads" style={styles.link}>
            {this.getNewItemButton(APPROVE_ADS_IMAGE, t('home_item_approve_ads'))}
          </Link>
          <Link to="/superadmin/event" style={styles.link}>
            {this.getNewItemButton(VIEW_CATE_IMAGE, t('home_item_view_all_events'))}
          </Link>
          <Link to="/superadmin/area" style={styles.link}>
            {this.getNewItemButton(VIEW_PLACES_IMAGE, t('home_item_view_area'))}
          </Link>
          <Link to="/superadmin/province" style={styles.link}>
            {this.getNewItemButton(VIEW_PLACES_IMAGE, t('home_item_view_provinces'))}
          </Link>
          <Link to="/superadmin/category" style={styles.link}>
            {this.getNewItemButton(VIEW_CATE_IMAGE, t('home_item_view_category'))}
          </Link>
        </div>
      </div>
    );
  }

  getNewItemButton(image, text, onClick, desc) {
    // todo
    return (
      <div style={{...styles.itemContainer, backgroundImage: `url(${image})`}}>
        <Button style={styles.itemButton} onClick={onClick}>
          <span style={styles.itemText}>{text}</span>
        </Button>
        <span style={styles.itemDesc}>{desc}</span>
      </div>
    );
  }

  async componentDidMount() {
    let user = ParseUtil.getUser();
    if (user) {
      let userData = await ParseUtil.getUserPublicData(user.id);
      if (!userData) {
        // no user data, token invalid
        await this.onLogoutClick();
        return;
      }
      let isUserSelfPublisher = await ParseUtil.isUserSelfPublisher();
      this.setState({userPublicData: userData, isUserSelfPublisher: isUserSelfPublisher});
    }
  }

  renderRedirectToFrontPage() {
    if (this.state.redirectToFront) {
      return (
        <Redirect to="/" />
      );
    }
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async onLogoutClick() {
    await this.setStateAsync({isShowLoadingDialog: true});
    await ParseUtil.logout();
    EventBus.post({key: EventBus.getKey().LOGIN_STATE_CHANGED});
    this.setState({redirectToFront: true});
    await this.setStateAsync({isShowLoadingDialog: false});
  }
}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  appBarTitle: {
    flexGrow: 1
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    width: '100%',
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  itemsContainer: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 20,
    marginBottom: 30
  },
  itemContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '20vmin',
    maxHeight: 150,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    marginBottom: 12,
    borderRadius: 16
  },
  itemText: {
    fontSize: '4.5vmin',
    color: '#ffffff',
    fontWeight: 'bold'
  },
  itemDesc: {
    fontSize: '2vmin',
    color: '#ffffff',
    whiteSpace: 'pre-line',
  },
  itemButton: {
    display: 'flex',
    width: '100%',
    height: '100%',
    borderRadius: 16,
    textTransform: 'none',
  },
  link: {
    textDecoration: 'none'
  },
  userActionsContainer: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 15,
    marginBottom: 15
  },
  emailBtnContent: {
    color: '#ffffff',
    display: 'flex',
  },
  dialogInputField: {
    flex: 1,
    width: '100%'
  },
  emailTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#737373',
  },
};

export default withTranslation()(HomeScreen);
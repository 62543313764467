import React, {Component} from 'react';
import './App.css';
import {Router} from 'react-router-dom';
import Route from './route';
import history from './service/history';
import './i18n';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import EventBus from './util/EventBus';
import AccountUtil from './util/AccountUtil';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#27c3b0'
    },
    secondary: {
      main: '#3984ff'
    },
  },
});

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {isShowSnackbar: false, snackbarMessage: ''};
  }

  componentDidMount() {
    AccountUtil.initFacebookSdk();
    AccountUtil.initGoogleSdk();
    this.snackbarSub = EventBus.subscribe(EventBus.getKey().SNACKBAR, (value) => {
      this.setState({isShowSnackbar: true, snackbarMessage: value.message});
    });
  }

  componentWillUnmount() {
    if (this.snackbarSub) {
      this.snackbarSub.unsubscribe();
    }
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <div>
            <Route />
            {this.renderSnackbar()}
          </div>
        </Router>
      </ThemeProvider>
    );
  }

  renderSnackbar() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.isShowSnackbar}
        autoHideDuration={6000}
        message={<span>{this.state.snackbarMessage}</span>}
        onClose={() => {
          this.setState({isShowSnackbar: false});
        }} />
    );
  }
}

export default App;

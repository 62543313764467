import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import {Link, Redirect} from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus, Upload} from 'mdi-material-ui';
import TextField from '@material-ui/core/TextField';
import ParseUtil, {LANG_CODES, LANG_CODES_DESC} from '../util/ParseUtil';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import InputLabel from '@material-ui/core/InputLabel';
import AppLog from '../util/AppLog';
import EventBus from '../util/EventBus';
import ImageUtil from '../util/ImageUtil';

const BG_IMAGE = require('../res/img/blur_bg.png');

class AdminAddCategoryScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nameValues: {}, isShowLoadingDialog: false,
      isShowErrorDialog: false, errorDialogContent: null,
      redirectToCate: false, order: null, cateIconFile: null
    };
    this.editCateId = this.props.match.params.id;
    this.iconFileUploadBtn = null;
  }

  async componentDidMount() {
    await this.loadCountry();
    await this.loadCategory();
  }

  async loadCategory() {
    let cateId = this.editCateId;
    if (!cateId) {
      return;
    }
    let cateObject = await ParseUtil.getCategory(cateId);
    if (cateObject) {
      // pre fill items
      let nameValues = {};
      for (let i = 0; i < LANG_CODES.length; i++) {
        let field = 'name' + LANG_CODES[i];
        nameValues[field] = cateObject.get(field);
      }
      let order = cateObject.get('order') || null;

      this.setState({
        nameValues: nameValues,
        order: order
      });
    }
  }

  async loadCountry() {
    let results = await ParseUtil.getAllCountry();
    this.setState({countryData: results});
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/superadmin/category" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft/>
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t(this.editCateId ? 'admin_add_category_edit_title' : 'admin_add_category_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <span style={styles.contentTitle}>
            {t(this.editCateId ? 'admin_add_category_edit_title' : 'admin_add_category_title')}
          </span>
          {this.renderForm()}
        </div>

        {this.renderErrorDialog()}
        {this.renderLoadingDialog()}
        {this.renderRedirectToCate()}
      </div>
    );
  }

  renderForm() {
    return (
      <form noValidate autoComplete="off" style={styles.form}>
        {this.renderNameTextFields()}
        <br/>
        {this.renderOrder()}
        <br/>
        {this.renderIconUpload()}
        <br/>
        <br/>
        <br/>
        {this.renderSubmitButton()}
      </form>
    );
  }

  renderIconUpload() {
    const {t} = this.props;

    let displayName = t('admin_add_category_icon_upload');
    if (this.state.cateIconFile) {
      displayName = this.state.cateIconFile[0].name;
    }
    return (
      <div>
        <input
          ref={(iconFileUploadBtn) => {
            this.iconFileUploadBtn = iconFileUploadBtn;
          }}
          type='file'
          accept="image/*"
          style={{visibility: 'hidden'}}
          onChange={(event) => {
            AppLog.log(event.target.files);
            this.setState({cateIconFile: event.target.files});
          }}/>
        <InputLabel>{t('admin_add_category_icon')}</InputLabel>
        <Button
          style={styles.uploadBtn}
          variant="contained"
          color="secondary"
          onClick={() => {
            this.iconFileUploadBtn.click();
          }}>
          <div style={styles.uploadBtnContent}>
            <Upload/>
            {displayName}
          </div>
        </Button>
      </div>
    );
  }

  renderOrder() {
    const {t} = this.props;
    return (
      <TextField style={styles.inputField} id={'order'}
                 InputLabelProps={{shrink: true}} variant="outlined"
                 type='number'
                 value={this.state.order}
                 onChange={event => {
                   let value = event.target.value;
                   this.setState({order: value});
                 }}
                 label={t('admin_add_category_order')}/>
    );
  }

  renderSubmitButton() {
    const {t} = this.props;
    return (
      <Button
        style={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={this.validateInputAndSubmit.bind(this)}>
        <div style={styles.submitBtnContent}>
          <Plus/>
          {t('admin_add_category_submit')}
        </div>
      </Button>
    );
  }

  validateInputAndSubmit() {
    const {t} = this.props;
    // check name values
    let keys = Object.keys(this.state.nameValues);
    let isMissingName = true;
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        if (this.state.nameValues[key]) {
          isMissingName = false;
          break;
        }
      }
    }
    if (isMissingName) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_category_missing_name')});
      return;
    }
    if (!this.state.order) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_category_missing_order')});
      return;
    }
    if (!this.state.cateIconFile && !this.editCateId) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('admin_add_category_missing_icon')});
      return;
    }

    this.setState({isShowLoadingDialog: true}, async () => {
      // submit to server
      let base64;
      if (this.state.cateIconFile) {
        let file = this.state.cateIconFile[0];
        base64 = await this._resizeImage(file);
      }
      let category = await ParseUtil.saveCategory(this.state.nameValues, this.state.order, null, base64, this.editCateId);
      if (category) {
        // submit success
        EventBus.post({key: EventBus.getKey().SNACKBAR, value: {message: t('general_operation_success')}});
        this.setState({isShowLoadingDialog: false, redirectToCate: true});
      } else {
        // submit failed
        this.setState({
          isShowLoadingDialog: false, isShowErrorDialog: true,
          errorDialogContent: t('general_add_item_fail')
        });
      }
    });
  }

  _resizeImage(file) {
    return new Promise((resolve, reject) => {
      if (!file) {
        resolve();
        return;
      }
      let img = new Image();
      img.onload = () => {
        let data = ImageUtil.scaleImage(img, {maxWidth: 500, quality: 0.8});
        resolve(data);
      };
      img.src = window.URL.createObjectURL(file);
    });
  }

  renderRedirectToCate() {
    if (this.state.redirectToCate) {
      return (
        <Redirect to="/superadmin/category"/>
      );
    }
  }

  renderLoadingDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowLoadingDialog}>
        <DialogContent>
          <DialogContentText>
            {t('general_loading')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

  renderErrorDialog() {
    const {t} = this.props;
    return (
      <Dialog open={this.state.isShowErrorDialog}>
        <DialogTitle>{t('general_error')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.state.errorDialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} color="primary" autoFocus>
            {t('general_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderNameTextFields() {
    const {t} = this.props;
    let nameTextFields = [];
    for (let i = 0; i < LANG_CODES.length; i++) {
      let key = 'name' + LANG_CODES[i];
      nameTextFields.push(
        <TextField style={styles.inputField} id={key}
                   InputLabelProps={{shrink: true}} variant="outlined"
                   value={this.state.nameValues[key]}
                   onChange={event => {
                     let values = this.state.nameValues;
                     values[key] = event.target.value;
                     this.setState({nameValues: values});
                   }}
                   label={t('admin_add_category_name') + ' (' + t(LANG_CODES_DESC[i]) + ')'}/>
      );
      nameTextFields.push(<br/>);
      nameTextFields.push(<br/>);
    }
    return nameTextFields;
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 700,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  form: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30
  },
  inputField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  latLngFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  uploadBtn: {
    width: '100%',
    marginTop: 8
  },
  uploadBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
    textTransform: 'none'
  }
};

export default withTranslation()(AdminAddCategoryScreen);
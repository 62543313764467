import {AppBar, Toolbar, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import {REDIRECT_TO_MY_EVENT} from '../screen/AddEventScreen';
import EventPreviewView from '../ui/EventPreviewView';
import GeneralDialog from '../ui/GeneralDialog';
import LoadingDialog from '../ui/LoadingDialog';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';

const BG_IMAGE = require('../res/img/blur_bg.png');
const EVENT_PAGE_ITEM = 10;

class ViewMyEventScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowDeleteDialog: false,
      isShowLoadingDialog: false,
      totalPendingCount: 0,
      currentPendingPage: 0,
      totalCount: 0,
      currentPage: 0,
      isUserSelfPublisher: false,
      isShowEditDisapproveWarningDialog: false,
      redirectToPath: undefined
    };
    this.deleteEventId = null;
    this.deletePendingEventId = null;
    this.editPath = null;
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft />
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t('view_my_event_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <div style={styles.titleContainer}>
            <span style={styles.contentTitle}>{t('view_my_event_pending')}</span>

            <Button
              style={styles.addNewBtn}
              variant="contained"
              color="primary">
              <Link to="/event/add" style={styles.link}>
                <div style={styles.addNewBtnContent}>
                  <Plus />
                  {t('view_my_event_add_new')}
                </div>
              </Link>
            </Button>
          </div>

          {this.renderPendingEvents()}
          <span style={styles.contentTitle}>{t('view_my_event_approved')}</span>
          {this.renderApprovedEvents()}
        </div>

        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
        {this.renderDeleteDialog()}
        {this.renderEditDisapproveWarningDialog()}
        {this.renderEventPreview()}
        {this.renderRedirectTo()}
      </div>
    );
  }

  async componentDidMount() {
    this.setState({isUserSelfPublisher: await ParseUtil.isUserSelfPublisher()});
    await this.loadPendingData(0);
    await this.loadApprovedData(0);
  }

  async loadApprovedData(skip) {
    let count = await ParseUtil.getEventCount(true);
    let results = await ParseUtil.getAllEvent(EVENT_PAGE_ITEM, skip);
    this.setState({approvedData: results, totalCount: count});
  }

  async loadPendingData(skip) {
    let count = await ParseUtil.getEventPendingCount(true);
    let results = await ParseUtil.getAllPendingEvent(EVENT_PAGE_ITEM, skip);
    this.setState({pendingData: results, totalPendingCount: count});
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  renderRedirectTo() {
    if (this.state.redirectToPath) {
      return (
        <Redirect to={this.state.redirectToPath} />
      );
    }
  }

  renderDeleteDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowDeleteDialog}
        dialogContent={t('view_my_event_delete_desc')}
        dialogTitle={t('view_my_event_delete_title')}
        onCancelClick={() => {
          this.setState({isShowDeleteDialog: false});
        }}
        onClick={() => {
          this.setState({isShowDeleteDialog: false});
          this.deleteEvent();
        }} />
    );
  }

  renderEditDisapproveWarningDialog() {
    const {t} = this.props;
    return (
      <GeneralDialog isShowDialog={this.state.isShowEditDisapproveWarningDialog}
        dialogContent={t('view_my_event_edit_disapprove_warning')}
        dialogTitle={t('view_my_event_table_action_edit')}
        onCancelClick={() => {
          this.setState({isShowEditDisapproveWarningDialog: false});
        }}
        onClick={() => {
          this.setState({
            isShowEditDisapproveWarningDialog: false,
            redirectToPath: this.editPath
          });
        }} />
    );
  }

  async deleteEvent() {
    await this.setStateAsync({isShowLoadingDialog: true});
    if (this.deletePendingEventId) {
      // delete pending event
      await ParseUtil.deletePendingEvent(this.deletePendingEventId);
      await this.loadPendingData(this.state.currentPendingPage * EVENT_PAGE_ITEM);
    } else if (this.deleteEventId) {
      // delete normal event
      if (ParseUtil.isUserAdmin()) {
        await ParseUtil.deleteEvent(this.deleteEventId);
      } else {
        await ParseUtil.deleteEventForUserByCloudCode(this.deleteEventId);
      }
      await this.loadApprovedData(this.state.currentPage * EVENT_PAGE_ITEM);

    }
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  renderApprovedEvents() {
    return this.renderEvents(this.state.approvedData, false);
  }

  renderPendingEvents() {
    return this.renderEvents(this.state.pendingData, true);
  }

  renderEvents(data, isPending) {
    if (!data) {
      return;
    }
    const {t} = this.props;
    let dataContentCells = [];
    let disapproveReasonTitleCell;
    if (isPending && this.state.isUserSelfPublisher) {
      disapproveReasonTitleCell = (
        <TableCell style={styles.tableCell}>{t('view_my_event_disapprove_reason')}</TableCell>
      );
    }

    for (let i = 0; i < data.length; i++) {
      let rowStyle = i % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;
      let object = data[i];

      let names = ParseUtil.getNameValuesForDisplay(object, 'eventName', LANG_CODES);
      let startAddresses = ParseUtil.getNameValuesForDisplay(object, 'startAddress', LANG_CODES);
      let endAddresses = ParseUtil.getNameValuesForDisplay(object, 'endAddress', LANG_CODES);
      let provinceLocation = object.get('provinceLocation');
      let province = provinceLocation ? ParseUtil.getNameValuesForDisplay(provinceLocation, 'name', LANG_CODES) : '';
      let disapproveReason = object.get('disapproveReason');

      let deleteLink;
      if (isPending || this.state.isUserSelfPublisher) {
        deleteLink = (
          <Link onClick={() => {
            if (isPending) {
              this.deletePendingEventId = object.id;
              this.deleteEventId = null;
            } else {
              this.deleteEventId = object.id;
              this.deletePendingEventId = null;
            }
            this.setState({isShowDeleteDialog: true});
          }}>
            {t('view_my_event_table_action_delete')}
          </Link>
        );
      }
      let editLink;
      // user without role (i.e. self reg) can edit event
      if (this.state.isUserSelfPublisher) {
        let editPath = '/event/edit/' + (isPending ? 1 : 0) + '/' + object.id + '/' + REDIRECT_TO_MY_EVENT;
        if (isPending) {
          editLink = (
            <div>
              <Link to={editPath}>
                {t('view_my_event_table_action_edit')}
              </Link>
              <br /><br />
            </div>
          );
        } else {
          editLink = (
            <div>
              <Link onClick={() => {
                this.editPath = editPath;
                this.setState({isShowEditDisapproveWarningDialog: true});
              }}>
                {t('view_my_event_table_action_edit')}
              </Link>
              <br /><br />
            </div>
          );
        }
      }

      let disapproveReasonCell;
      if (isPending && this.state.isUserSelfPublisher) {
        disapproveReasonCell = (
          <TableCell style={{...styles.tableCell, color: '#ff4714'}}>{disapproveReason}</TableCell>
        );
      }

      dataContentCells.push(
        <TableRow style={rowStyle}>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: names}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: startAddresses}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: endAddresses}} /></TableCell>
          <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: province}} /></TableCell>
          {disapproveReasonCell}

          <TableCell style={styles.tableCell}>
            <div>
              <Link onClick={() => {
                if (isPending) {
                  this.setState({previewPendingEventId: object.id, previewEventId: null});
                } else {
                  this.setState({previewEventId: object.id, previewPendingEventId: null});
                }
              }}>
                {t('view_my_event_table_action_preview')}
              </Link>
              <br /><br />
              {editLink}
              {deleteLink}
            </div>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableContainer component={Paper} style={styles.table} ref={(ref) => {
        if (isPending) {
          this.pendingEventTableRef = ref;
        } else {
          this.eventTableRef = ref;
        }
      }}>
        <Table stickyHeader aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>{t('add_event_name')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_start_location_address')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_end_location_address')}</TableCell>
              <TableCell style={styles.tableCell}>{t('add_event_location_province')}</TableCell>
              {disapproveReasonTitleCell}
              <TableCell style={styles.tableCell}>{t('view_my_event_table_action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContentCells}
          </TableBody>
          {this.renderEventTableFooter(data, isPending)}
        </Table>
      </TableContainer>
    );
  }

  renderEventTableFooter(data, isPending) {
    let unknownCount = 99999999;
    let count = isPending ? this.state.totalPendingCount : this.state.totalCount;
    if (count === -1) {
      if (data.length % EVENT_PAGE_ITEM === 0) {
        // there may be more than one page
        count = unknownCount;
      } else {
        // we know the exact count by calculation
        count = this.state.currentPage * EVENT_PAGE_ITEM + data.length;
      }
    }
    let page = isPending ? this.state.currentPendingPage : this.state.currentPage;

    return (
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[EVENT_PAGE_ITEM]}
            colSpan={3}
            count={count}
            rowsPerPage={EVENT_PAGE_ITEM}
            page={page}
            labelDisplayedRows={({from, to, count}) => {
              if (count === unknownCount) {
                return `Page: ${page + 1}, Row: ${from}-${to === -1 ? count : to}`;
              } else {
                return `Page: ${page + 1}, Row: ${from}-${to === -1 ? count : to} of ${count}`;
              }
            }}
            onChangePage={async (event, page) => {
              await this.setStateAsync({isShowLoadingDialog: true});
              if (isPending) {
                this.setState({currentPendingPage: page});
                await this.loadPendingData(page * EVENT_PAGE_ITEM);
              } else {
                this.setState({currentPage: page});
                await this.loadApprovedData(page * EVENT_PAGE_ITEM);
              }
              await this.setStateAsync({isShowLoadingDialog: false});
              await this.scrollToTable(isPending);
            }}
          />
        </TableRow>
      </TableFooter>
    );
  }

  async scrollToTable(isPending) {
    await new Promise(r => setTimeout(r, 100));
    if (this.pendingEventTableRef && this.eventTableRef) {
      if (isPending) {
        this.pendingEventTableRef.scrollIntoView({behavior: 'smooth', block: 'start'});
      } else {
        this.eventTableRef.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }

  renderEventPreview() {
    return (
      <div>
        <Dialog open={this.state.previewPendingEventId || this.state.previewEventId}>
          <EventPreviewView pendingEventId={this.state.previewPendingEventId}
            eventId={this.state.previewEventId}
            viewOnly={true}
            onCloseClick={() => {
              this.setState({previewPendingEventId: null, previewEventId: null});
            }} />
        </Dialog>
      </div>
    );
  }
}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  addNewBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  addNewBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  tableRowOdd: {
    backgroundColor: '#f5f5f5',
  },
  tableRowEven: {
    backgroundColor: '#f6ffff'
  },
  tableCell: {
    borderWidth: 1,
    borderColor: '#efefef',
    borderStyle: 'solid',
    minWidth: 35
  },
};

export default withTranslation()(ViewMyEventScreen);
class PrivacyTc {

  getContent() {
    return `<h2>
	隱私政策
</h2>

<p>
	您的隱私對我們非常重要。因此，我們為了讓您了解我們如何收集，使用，交流，公開和利用個人信息編寫了這個政策。下面簡單介紹一下我們的隱私政策。
</p>

<ul>
	<li>
		在收集個人信息時，我們將​​確定哪些信息被收集的目的。
	</li>
	<li>
		我們將收集和使用個人信息只與滿足我們指定的用途和其他相關的目的，除非我們獲得的有關個人或法律規定的同意才會用作其他用途。	
	</li>
	<li>
		我們只會有必要地保留個人信息用於指定的用途。
	</li>
	<li>
		我們會以合法及公平的方式收集個人信息，並在適當情況下，與有關用戶徵詢其同意。
	</li>
	<li>
		個人數據應該是與收集目的相關，並且確保資料的應準確，完整，和最新的。
	</li>
	<li>
		我們將通過合理的安全保障措施，防止丟失或被盜，以及未經授權的訪問，披露，複製，使用或修改保護個人信息。
	</li>
	<li>
		我們將隨時提供給我們的用戶關於個人信息的管理政策和做法的客戶信息。
	</li>
</ul>

<p>
	我們致力於開展我們的業務，並根據這些原則，以確保個人信息的保密性得到保護和保存。
</p>		

`;
  }

}

let _instance = new PrivacyTc();

export default _instance;


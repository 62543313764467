import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {ArrowLeft, Plus} from 'mdi-material-ui';
import {Link} from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import Button from '@material-ui/core/Button';

const BG_IMAGE = require('../res/img/blur_bg.png');

class AdminCategoryScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {data: null};
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft/>
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t('admin_category_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          <div style={styles.titleContainer}>
            <span style={styles.contentTitle}>{t('admin_category_current')}</span>

            <Button
              style={styles.addNewBtn}
              variant="contained"
              color="primary">
              <Link to="/superadmin/category/add" style={styles.link}>
                <div style={styles.addNewBtnContent}>
                  <Plus/>
                  {t('admin_category_add_new')}
                </div>
              </Link>
            </Button>
          </div>

          {this.renderTable()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    let results = await ParseUtil.getAllCategory();
    this.setState({data: results});
  }

  renderTable() {
    const {t} = this.props;

    let dataContentCells = [];
    if (this.state.data) {
      for (let i = 0; i < this.state.data.length; i++) {
        let rowStyle = i % 2 === 0 ? styles.tableRowEven : styles.tableRowOdd;

        let object = this.state.data[i];

        let names = ParseUtil.getNameValuesForDisplay(object, 'name', LANG_CODES);
        let order = object.get('order');
        let icon = object.get('icon');
        let iconUrl = icon ? icon.url() : '';

        dataContentCells.push(
          <TableRow style={rowStyle}>
            <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: names}}/></TableCell>
            <TableCell style={styles.tableCell}>
              <img style={styles.cateIcon} src={iconUrl}/>
            </TableCell>
            <TableCell style={styles.tableCell}><span dangerouslySetInnerHTML={{__html: order}}/></TableCell>
            <TableCell style={styles.tableCell}>
              <Link to={'/superadmin/category/edit/' + object.id}>
                {t('admin_category_table_action_edit')}
              </Link>
            </TableCell>
          </TableRow>
        );
      }
    }
    return (
      <TableContainer component={Paper} style={styles.table}>
        <Table stickyHeader aria-label="category table">
          <TableHead>
            <TableRow>
              <TableCell style={styles.tableCell}>{t('admin_category_table_name')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_category_table_icon')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_category_table_order')}</TableCell>
              <TableCell style={styles.tableCell}>{t('admin_category_table_action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataContentCells}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1050,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  contentTitle: {
    marginTop: 30,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#737373',
    marginLeft: 30
  },
  table: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  tableRowOdd: {
    backgroundColor: '#f5f5f5',
  },
  tableRowEven: {
    backgroundColor: '#f6ffff'
  },
  tableCell: {
    borderWidth: 1,
    borderColor: '#efefef',
    borderStyle: 'solid',
    minWidth: 35
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between'
  },
  addNewBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end'
  },
  addNewBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  cateIcon: {
    height: 50
  }
};

export default withTranslation()(AdminCategoryScreen);
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import ParseUtil, {LANG_CODES} from '../util/ParseUtil';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class AddEventSimilarEventsDialog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {t} = this.props;
    let similarEvents = [];
    for (let i = 0; i < this.props.similarEvents.length; i++) {
      let object = this.props.similarEvents[i];
      let displayNames = ParseUtil.getNameValuesForDisplay(object, 'eventName', LANG_CODES);
      similarEvents.push(
        <span dangerouslySetInnerHTML={{__html: displayNames}}/>
      );
      similarEvents.push(<hr/>);
      similarEvents.push(<br/>);
    }

    let actions = [];
    if (this.props.showOkOnly) {
      actions.push(
        <Button onClick={() => {
          if (this.props.onOk) {
            this.props.onOk();
          }
        }} color="primary" autoFocus key={'okBtn'}>
          {t('general_ok')}
        </Button>
      );
    } else {
      actions.push(
        <Button onClick={() => {
          if (this.props.onCancel) {
            this.props.onCancel();
          }
        }} color="primary" autoFocus key={'cancelBtn'}>
          {t('add_event_similar_event_dialog_cancel')}
        </Button>
      );
      actions.push(
        <Button onClick={() => {
          if (this.props.onContinue) {
            this.props.onContinue();
          }
        }} color="primary" autoFocus key={'continueBtn'}>
          {t('add_event_similar_event_dialog_continue')}
        </Button>
      );
    }

    return (
      <Dialog open={this.props.isShowSimilarEventsDialog}>
        <DialogTitle>{t('add_event_similar_event_dialog_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span dangerouslySetInnerHTML={{__html: t('add_event_similar_event_dialog_desc')}}/>
            <br/>
            <br/>
            {similarEvents}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    );
  }

}

const styles = {};

export default withTranslation()(AddEventSimilarEventsDialog);
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class LoadingDialog extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {};
  }

  render() {
    const {t} = this.props;
    return (
      <Dialog open={this.props.isShowLoadingDialog || false}>
        <DialogContent>
          <DialogContentText>
            {t('general_loading')}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    );
  }

}

const styles = {};

export default withTranslation()(LoadingDialog);
import DateFnsUtils from '@date-io/date-fns';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ArrowLeft, Check, Delete} from 'mdi-material-ui';
import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import {withTranslation} from 'react-i18next';
import {Link, Redirect} from 'react-router-dom';
import AddEventSimilarEventsDialog from '../ui/AddEventSimilarEventsDialog';
import ErrorDialog from '../ui/ErrorDialog';
import FormSelect from '../ui/FormSelect';
import GeneralDialog from '../ui/GeneralDialog';
import LoadingDialog from '../ui/LoadingDialog';
import AppLog from '../util/AppLog';
import ImageUtil from '../util/ImageUtil';
import MapsUtil from '../util/MapsUtil';
import ParseUtil, {LANG_CODES, LANG_CODES_DESC} from '../util/ParseUtil';

const BG_IMAGE = require('../res/img/blur_bg.png');

export const REDIRECT_TO_MY_EVENT = '0';
export const REDIRECT_TO_APPROVE = '1';
export const REDIRECT_TO_ALL_EVENT = '2';

class AddEventScreen extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0, nameValues: {}, isShowErrorDialog: false, errorDialogContent: null, isShowIntroDialog: false,
      isShowLoadingDialog: false, similarEvents: null, isShowSimilarEventsDialog: false,
      startLocationAddressValues: {}, startAddressLat: null, startAddressLng: null,
      endLocationAddressValues: {}, endAddressLat: null, endAddressLng: null,
      isEndAddressSameAsStart: true, selectedCountryValue: null, selectedAreaValue: null,
      selectedProvinceValue: null, eventEndDateValue: null, eventStartDateValue: null,
      selectedCateValue: new Set([]), redirectToViewMyEvent: false, redirectToApproveEvent: false,
      redirectToAllEvent: false, checkName: '', isShowCheckNameSuccessDialog: false, isShowCheckNameFailDialog: false,
      eventDescValues: {}, isUserSelfPublisher: false, linksValues: [], isUserAdmin: false
    };
    this.isEditPendingEvent = this.props.match.params.isPending === '1';
    this.editEventId = this.props.match.params.id;
    this.redirectTo = this.props.match.params.redirectTo;
  }

  render() {
    const {t} = this.props;
    return (
      <div style={styles.rootContainer}>
        <AppBar style={styles.appBar} position="static">
          <Toolbar>
            <Link to="/home" style={styles.link}>
              <IconButton edge="start" color="inherit" aria-label="menu">
                <ArrowLeft />
              </IconButton>
            </Link>
            <Typography variant="h6">
              {t(this.editEventId ? 'add_event_edit_title' : 'add_event_title')}
            </Typography>
          </Toolbar>
        </AppBar>

        <div style={styles.contentContainer}>
          {this.renderStepper()}
          {this.renderStepContent()}
        </div>

        <ErrorDialog isShowErrorDialog={this.state.isShowErrorDialog}
          errorDialogContent={this.state.errorDialogContent}
          onClick={() => {
            this.setState({isShowErrorDialog: false});
          }} />
        <LoadingDialog isShowLoadingDialog={this.state.isShowLoadingDialog} />
        <GeneralDialog isShowDialog={this.state.isShowIntroDialog}
          dialogContent={t('add_event_intro_dialog_desc')}
          dialogTitle={t('add_event_intro_dialog_title')}
          onClick={() => {
            this.setState({isShowIntroDialog: false});
          }} />
        <GeneralDialog isShowDialog={this.state.isShowSuccessDialog}
          dialogContent={t(this.editEventId ? 'add_event_save_success_edit_desc' : 'add_event_save_success_desc')}
          dialogTitle={t('add_event_save_success_title')}
          onClick={() => {
            this.setState({isShowSuccessDialog: false});
            if (this.redirectTo === REDIRECT_TO_APPROVE) {
              this.setState({redirectToApproveEvent: true});
            } else if (this.redirectTo === REDIRECT_TO_ALL_EVENT) {
              this.setState({redirectToAllEvent: true});
            } else if (this.redirectTo === REDIRECT_TO_MY_EVENT) {
              this.setState({redirectToViewMyEvent: true});
            } else {
              window.location.reload();
            }
          }} />
        {this.renderRedirectToHome()}
        {this.renderRedirectToViewMyEvent()}
        {this.renderRedirectToApproveEvent()}
        {this.renderRedirectToAllEvent()}
      </div>
    );
  }

  async componentDidMount() {
    await this.setStateAsync({isShowLoadingDialog: true});
    await this.loadCountry();
    await this.loadCategory();
    await this.loadEvent();
    await this.setStateAsync({
      isUserSelfPublisher: await ParseUtil.isUserSelfPublisher(),
      isUserAdmin: ParseUtil.isUserAdmin()
    });
    await this.setStateAsync({isShowLoadingDialog: false});
    /* we don't show intro dialog now
    if (!this.editEventId) {
      this.setState({isShowIntroDialog: true});
    }
     */
  }

  async loadEvent() {
    if (!this.editEventId) {
      return;
    }
    AppLog.log('isEditPendingEvent: ' + this.isEditPendingEvent);
    AppLog.log('editEventId: ' + this.editEventId);
    let eventObject;
    let eventFiles;
    if (this.isEditPendingEvent) {
      eventObject = await ParseUtil.getPendingEvent(this.editEventId);
      eventFiles = await ParseUtil.getEventPendingFiles(this.editEventId);
    } else {
      eventObject = await ParseUtil.getEvent(this.editEventId);
      eventFiles = await ParseUtil.getEventFiles(this.editEventId);
    }
    // pre fill name
    let nameValues = {};
    for (let i = 0; i < LANG_CODES.length; i++) {
      let nameKey = 'eventName' + LANG_CODES[i];
      let nameValue = eventObject.get(nameKey);
      nameValues[nameKey] = nameValue || '';
    }

    // pre fill location
    let provinceLocation = eventObject.get('provinceLocation');
    let areaLocation = provinceLocation.get('areaLocation');
    let countryLocation = provinceLocation.get('countryLocation');
    let selectedCountryValue = countryLocation.id;
    let selectedAreaValue = areaLocation.id;
    let selectedProvinceValue = provinceLocation.id;

    let areaData = await ParseUtil.getAreas(selectedCountryValue);
    let provinceData = await ParseUtil.getProvinces(selectedAreaValue);

    let startLocationAddressValues = {};
    let endLocationAddressValues = {};
    for (let i = 0; i < LANG_CODES.length; i++) {
      let startKey = 'startAddress' + LANG_CODES[i];
      let startValue = eventObject.get(startKey);
      startLocationAddressValues[startKey] = startValue || '';

      let endKey = 'endAddress' + LANG_CODES[i];
      let endValue = eventObject.get(endKey);
      endLocationAddressValues[endKey] = endValue || '';
    }
    let startLocation = eventObject.get('startLocation').get('location');
    let startAddressLat = startLocation.latitude;
    let startAddressLng = startLocation.longitude;

    let endLocation = eventObject.get('endLocation').get('location');
    let endAddressLat = endLocation.latitude;
    let endAddressLng = endLocation.longitude;

    // pre fill date
    let eventStartDateValue = eventObject.get('eventStartDate');
    if (eventStartDateValue && eventStartDateValue.toLocaleString().indexOf('1988') !== -1) {
      eventStartDateValue = null;
    }
    let eventEndDateValue = eventObject.get('eventEndDate');
    if (eventEndDateValue && eventEndDateValue.toLocaleString().indexOf('2199') !== -1) {
      eventEndDateValue = null;
    }
    let selectedMinStayTimeValue = eventObject.get('minStayTime');
    let selectedMaxStayTimeValue = eventObject.get('maxStayTime');
    let selectedEventStartTimeValue = eventObject.get('eventStartTime');
    let selectedEventEndTimeValue = eventObject.get('eventEndTime');

    // pre fill content
    let selectedIndoorValue = eventObject.get('isIndoor');
    let selectedIncludeMealValue = eventObject.get('isIncludeMeal');
    let selectedStaminaValue = eventObject.get('stamina');
    let selectedSeasonValue = eventObject.get('bestSeason');
    let selectedCateValue = new Set([]);
    let cates = eventObject.get('cates');
    for (let i = 0; i < cates.length; i++) {
      let cate = cates[i];
      selectedCateValue.add(cate.id);
    }
    let wikiLinkValue = eventObject.get('wikiLink');
    let instagramPostLinkValue = eventObject.get('instagramPostLink');
    let linksValues = eventObject.get('linksLocal') || [];

    // event desc
    let eventDescValues = {};
    for (let i = 0; i < LANG_CODES.length; i++) {
      let descKey = 'eventDesc' + LANG_CODES[i];
      let descValue = eventObject.get(descKey);
      eventDescValues[descKey] = descValue || '';
    }

    // photos
    let selectedPhotos = [];
    let selectedEditPhotos = eventFiles;

    // finally set state
    this.setState({
      nameValues,
      selectedCountryValue,
      selectedAreaValue,
      selectedProvinceValue,
      areaData,
      provinceData,
      startLocationAddressValues,
      endLocationAddressValues,
      startAddressLat,
      startAddressLng,
      endAddressLat,
      endAddressLng,
      eventStartDateValue,
      eventEndDateValue,
      selectedMinStayTimeValue,
      selectedMaxStayTimeValue,
      selectedEventStartTimeValue,
      selectedEventEndTimeValue,
      selectedIndoorValue,
      selectedIncludeMealValue,
      selectedStaminaValue,
      selectedSeasonValue,
      selectedCateValue,
      selectedPhotos,
      selectedEditPhotos,
      wikiLinkValue,
      instagramPostLinkValue,
      eventDescValues,
      linksValues
    });
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async loadCountry() {
    let results = await ParseUtil.getAllCountry();
    this.setState({countryData: results});
  }

  async loadCategory() {
    let results = await ParseUtil.getAllCategory();
    this.setState({cateData: results});
  }

  renderRedirectToHome() {
    if (this.state.redirectToHome) {
      return (
        <Redirect to="/home" />
      );
    }
  }

  renderRedirectToViewMyEvent() {
    if (this.state.redirectToViewMyEvent) {
      return (
        <Redirect to="/event/view" />
      );
    }
  }

  renderRedirectToApproveEvent() {
    if (this.state.redirectToApproveEvent) {
      return (
        <Redirect to="/superadmin/approve" />
      );
    }
  }

  renderRedirectToAllEvent() {
    if (this.state.redirectToAllEvent) {
      return (
        <Redirect to="/superadmin/event" />
      );
    }
  }

  renderStepper() {
    const {t} = this.props;
    let steps = ['add_event_step_name', 'add_event_step_location', 'add_event_step_date_info', 'add_event_step_content_info'];
    return (
      <Stepper activeStep={this.state.activeStep} alternativeLabel style={styles.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    );
  }

  renderStepContent() {
    switch (this.state.activeStep) {
      case 0:
        return this.renderStepNameForm();
      case 1:
        return this.renderStepLocationForm();
      case 2:
        return this.renderStepDateInfoForm();
      case 3:
        return this.renderStepContentInfoForm();
      default:
        break;
    }
  }

  renderStepContentInfoForm() {
    const {t} = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" style={styles.form}>
          {this.renderIndoorSelect()}
          <br /><br />
          {this.renderIncludeMealSelect()}
          <br /><br /><br />
          {this.renderCategory()}
          <br /><br />
          {this.renderStamina()}
          <br /><br />
          {this.renderEventSeason()}
          <br /><br /><br />
          {this.renderPhotoUpload()}
          <br /><br />
          {this.renderEventDescFields()}
          <br /><br />
          {this.renderUrlLinkFields()}
          <br /><br />
          {this.renderWikiLinkTextField()}
          <br /><br />
          {this.renderInstagramPostLinkField()}
          <br /><br /><br />
          <div style={styles.submitBtnsContainer}>
            {this.renderSubmitButton(t('add_event_prev_step'), this.backToPrevStep.bind(this), (<ArrowLeft />))}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.renderSubmitButton(t('add_event_finish_submit'), this.submitContentInfoForm.bind(this))}
          </div>
        </form>
      </div>
    );
  }

  renderInstagramPostLinkField() {
    if (!this.state.isUserSelfPublisher && !this.state.isUserAdmin) {
      return;
    }
    return (
      <div>
        <TextField style={styles.inputField} id={'instagram_post_link_textfield'}
          InputLabelProps={{shrink: true}} variant="outlined"
          label={this.props.t('add_event_info_instagram_post')}
          value={this.state.instagramPostLinkValue}
          onChange={event => {
            this.setState({instagramPostLinkValue: event.target.value});
          }} />
      </div>
    );
  }

  renderUrlLinkFields() {
    if (!this.state.isUserSelfPublisher && !this.state.isUserAdmin) {
      return;
    }
    let linkFields = [];
    linkFields.push(<InputLabel>{this.props.t('add_event_info_url_link')}</InputLabel>);
    linkFields.push(this.renderUrlLinkField(0));
    linkFields.push(this.renderUrlLinkField(1));
    linkFields.push(this.renderUrlLinkField(2));
    return linkFields;
  }

  renderUrlLinkField(linkIndex) {
    let fields = [];
    fields.push(<br />);
    fields.push(<br />);
    fields.push(
      <TextField style={styles.inputField} id={'link_title_' + linkIndex}
        InputLabelProps={{shrink: true}} variant="outlined"
        label={this.props.t('add_event_info_url_link_title') + `(${linkIndex + 1})`}
        value={this.state.linksValues[linkIndex]?.title || ''}
        onChange={event => {
          let values = this.state.linksValues;
          let linkObject = values[linkIndex] || {};
          linkObject.title = event.target.value;
          values[linkIndex] = linkObject;
          this.setState({linksValues: values});
        }} />
    );
    fields.push(<br />);
    fields.push(<br />);
    fields.push(
      <TextField style={styles.inputField} id={'link_url_' + linkIndex}
        InputLabelProps={{shrink: true}} variant="outlined"
        label={this.props.t('add_event_info_url_link_url') + `(${linkIndex + 1})`}
        value={this.state.linksValues[linkIndex]?.link || ''}
        onChange={event => {
          let values = this.state.linksValues;
          let linkObject = values[linkIndex] || {};
          linkObject.link = event.target.value;
          values[linkIndex] = linkObject;
          this.setState({linksValues: values});
        }} />
    );
    return fields;
  }

  renderEventDescFields() {
    if (!this.state.isUserSelfPublisher && !this.state.isUserAdmin) {
      return;
    }
    const {t} = this.props;
    let textAreas = [];
    textAreas.push(<br />);
    for (let i = 0; i < LANG_CODES.length; i++) {
      let key = 'eventDesc' + LANG_CODES[i];
      let eventNameKey = 'eventName' + LANG_CODES[i];
      let eventNameValue = this.state.nameValues[eventNameKey];
      if (eventNameValue && eventNameValue.length > 0) {
        // name exist, can have event desc for this language
        let label = t('add_event_info_event_desc') + ' (' + t(LANG_CODES_DESC[i]) + ')';
        textAreas.push(
          <TextField style={styles.inputField} id={key}
            InputLabelProps={{shrink: true}} variant="outlined"
            label={label}
            value={this.state.eventDescValues[key] || ''}
            multiline
            inputProps={{
              maxLength: 1000
            }}
            onChange={event => {
              let values = this.state.eventDescValues;
              values[key] = event.target.value;
              this.setState({eventDescValues: values});
            }} />
        );
        textAreas.push(<br />);
        textAreas.push(<br />);
      }
    }
    return textAreas;
  }

  async submitContentInfoForm() {
    const {t} = this.props;
    if (this.state.selectedIndoorValue === undefined || this.state.selectedIncludeMealValue === undefined ||
      this.state.selectedCateValue.size <= 0 || !this.state.selectedStaminaValue ||
      this.state.selectedSeasonValue === undefined) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_item_missing')});
      return;
    }

    let isMissingPhoto = true;
    if (this.state.selectedPhotos && this.state.selectedPhotos.length > 0) {
      isMissingPhoto = false;
    }
    if (this.state.selectedEditPhotos && this.state.selectedEditPhotos.length > 0) {
      isMissingPhoto = false;
    }
    if (isMissingPhoto) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_photo_missing')});
      return;
    }

    // validate wiki
    let wikiLink = this.state.wikiLinkValue?.trim();
    if (wikiLink && wikiLink.indexOf('wikipedia.org') === -1) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_wiki_wrong_format')});
      return;
    }

    // validate instagram post
    let instagramPostLink = this.state.instagramPostLinkValue?.trim();
    if (instagramPostLink && instagramPostLink.indexOf('instagram.com') === -1) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_instagram_post_wrong_format')});
      return;
    }

    // validate url links
    for (let i = 0; i < this.state.linksValues.length; i++) {
      let linksValue = this.state.linksValues[i];
      let title = linksValue?.title?.trim();
      let link = linksValue?.link?.trim();
      if (!linksValue || (!title && !link)) {
        // no input value
        continue;
      }
      if (!title || !link) {
        this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_link_missing_info')});
        return;
      }
      link = link.toLowerCase();
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_link_invalid')});
        return;
      }
    }

    let updateEventReady = true;
    if (!ParseUtil.isUserAdmin() && this.editEventId && !this.isEditPendingEvent) {
      // editing a normal event but user is not admin, need to disapprove first then update the pending event
      updateEventReady = false;
      try {
        await this.disapproveEventForEditing();
        updateEventReady = true;
      } catch (e) {
        AppLog.log(e);
        this.setState({
          isShowLoadingDialog: false,
          isShowErrorDialog: true,
          errorDialogContent: this.props.t('add_event_save_fail')
        });
      }
    }
    if (updateEventReady) {
      await this.addOrUpdateEventToServer();
    }
  }

  async disapproveEventForEditing() {
    AppLog.log('Disapprove the event for editing');
    await this.setStateAsync({isShowLoadingDialog: true});
    let event = await ParseUtil.getEvent(this.editEventId);
    let pendingEvent = await ParseUtil.disapproveEvent(event);
    // update edit event id and change to editing pending event
    this.editEventId = pendingEvent.id;
    this.isEditPendingEvent = true;
    await this.setStateAsync({isShowLoadingDialog: false});
  }

  renderWikiLinkTextField() {
    if (this.state.isUserSelfPublisher) {
      return;
    }
    const {t} = this.props;
    return (
      <div>
        <TextField style={styles.inputField} id={'wiki_link_textfield'}
          InputLabelProps={{shrink: true}} variant="outlined"
          label={t('add_event_info_wiki')}
          value={this.state.wikiLinkValue}
          onChange={event => {
            this.setState({wikiLinkValue: event.target.value});
          }} />
      </div>
    );
  }

  renderPhotoUpload() {
    const {t} = this.props;
    let addedFiles = [];
    let addedFilesSection = null;

    // edit photos
    if (this.state.selectedEditPhotos && this.state.selectedEditPhotos.length > 0) {
      for (let i = 0; i < this.state.selectedEditPhotos.length; i++) {
        let file = this.state.selectedEditPhotos[i].get('image');
        addedFiles.push(
          <li key={file.name()}>
            {file.name()}
          </li>
        );
      }
    }
    // add photos
    if (this.state.selectedPhotos && this.state.selectedPhotos.length > 0) {
      for (let i = 0; i < this.state.selectedPhotos.length; i++) {
        let file = this.state.selectedPhotos[i];
        addedFiles.push(
          <li key={file.name}>
            {file.name} - {file.size} bytes
          </li>
        );
      }
    }
    if (addedFiles.length > 0) {
      addedFilesSection = (
        <aside>
          <br />
          <div style={styles.addedFilesTitleContainer}>
            <span>{t('add_event_drop_added_files')}</span>
            <Button style={styles.clearPhotoBtn} variant="contained"
              onClick={() => {
                this.setState({selectedPhotos: null, selectedEditPhotos: null});
              }}>
              <div style={styles.clearPhotoBtnContent}>
                <Delete />
                {t('add_event_drop_clear_files')}
              </div>
            </Button>
          </div>
          <ul>{addedFiles}</ul>
        </aside>
      );
    }
    return (
      <div style={styles.eventPhotoContainer}>
        <InputLabel>{t('add_event_info_event_photo')}</InputLabel>
        <br />
        <Dropzone accept={['image/png', 'image/jpg', 'image/jpeg']}
          minSize={10}
          maxSize={10000000}
          onDrop={(files) => {
            let finalFiles = this.state.selectedPhotos;
            if (finalFiles) {
              finalFiles = finalFiles.concat(files);
            } else {
              finalFiles = files;
            }
            // maximum 5 photos, take the first 5
            if (finalFiles.length > 5) {
              finalFiles = finalFiles.slice(0, 5);
            }
            this.setState({selectedPhotos: finalFiles});
          }}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps({className: 'dropzone'})} style={styles.dropZoneContainer}>
                <input {...getInputProps()} />
                <p>{t('add_event_drop_files')}</p>
              </div>
              {addedFilesSection}
            </section>
          )}
        </Dropzone>
      </div>
    );
  }

  renderEventSeason() {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push(<MenuItem value={0}>{t('add_event_info_event_season_na')}</MenuItem>);
    menuItems.push(<MenuItem value={1}>{t('add_event_info_event_season_spring')}</MenuItem>);
    menuItems.push(<MenuItem value={2}>{t('add_event_info_event_season_summer')}</MenuItem>);
    menuItems.push(<MenuItem value={3}>{t('add_event_info_event_season_autumn')}</MenuItem>);
    menuItems.push(<MenuItem value={4}>{t('add_event_info_event_season_winter')}</MenuItem>);
    menuItems.push(<MenuItem value={5}>{t('add_event_info_event_season_spring_summer')}</MenuItem>);
    menuItems.push(<MenuItem value={6}>{t('add_event_info_event_season_summer_autumn')}</MenuItem>);
    menuItems.push(<MenuItem value={7}>{t('add_event_info_event_season_autumn_winter')}</MenuItem>);
    menuItems.push(<MenuItem value={8}>{t('add_event_info_event_season_winter_spring')}</MenuItem>);
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_event_season')}
        selectedValue={this.state.selectedSeasonValue}
        onChange={async (value) => {
          this.setState({selectedSeasonValue: value});
        }}
      />
    );
  }

  renderStamina() {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push(<MenuItem value={10}>{t('add_event_info_stamina_small')}</MenuItem>);
    menuItems.push(<MenuItem value={50}>{t('add_event_info_stamina_medium')}</MenuItem>);
    menuItems.push(<MenuItem value={90}>{t('add_event_info_stamina_large')}</MenuItem>);
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_stamina_factor')}
        selectedValue={this.state.selectedStaminaValue}
        onChange={async (value) => {
          this.setState({selectedStaminaValue: value});
        }}
      />
    );
  }

  renderCategory() {
    const {t} = this.props;
    let formControls = [];
    if (this.state.cateData && this.state.cateData.length > 0) {
      // skip the first one, which is 'All'
      for (let i = 1; i < this.state.cateData.length; i++) {
        let object = this.state.cateData[i];
        formControls.push(
          <Grid item xs={'auto'}>
            <FormControlLabel
              control={<Checkbox checked={this.state.selectedCateValue.has(object.id)}
                onChange={(event) => {
                  let set = this.state.selectedCateValue;
                  let value = event.target.value;
                  if (event.target.checked) {
                    set.add(value);
                  } else {
                    set.delete(value);
                  }
                  this.setState({selectedCateValue: set});
                }} value={object.id} />}
              label={ParseUtil.getNameForCurrentLocale(object, 'name')}
            />
          </Grid>
        );
      }
    }

    return (
      <FormControl component="fieldset" style={styles.formControl}>
        <FormLabel component="legend">{t('add_event_info_category')}</FormLabel>
        <br />
        <FormGroup>
          <Grid container spacing={1}>
            {formControls}
          </Grid>
        </FormGroup>
      </FormControl>
    );
  }

  renderStepDateInfoForm() {
    const {t} = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" style={styles.form}>
          {this.renderEventStartDate()}
          <br />
          {this.renderEventEndDate()}
          <br /><br />
          {this.renderMinimumStayTime()}
          <br /><br />
          {this.renderMaximumStayTime()}
          <br /><br />
          {this.renderEventStartTime()}
          <br /><br />
          {this.renderEventEndTime()}
          <br /><br /><br />
          <div style={styles.submitBtnsContainer}>
            {this.renderSubmitButton(t('add_event_prev_step'), this.backToPrevStep.bind(this), (<ArrowLeft />))}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.renderSubmitButton(t('add_event_next_step'), this.submitDateInfoForm.bind(this))}
          </div>
        </form>
      </div>
    );
  }

  submitDateInfoForm() {
    const {t} = this.props;
    if (!this.state.selectedMinStayTimeValue || !this.state.selectedMaxStayTimeValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_stay_time_missing')});
      return;
    }
    let minTime = this.state.selectedMinStayTimeValue;
    let maxTime = this.state.selectedMaxStayTimeValue;
    if (minTime > -1 && maxTime > -1 && minTime > maxTime) {
      // min time cannot larger than max time
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_info_min_time_larger_than_max_time')});
      return;
    }

    if (!this.state.selectedEventStartTimeValue || !this.state.selectedEventEndTimeValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_event_date_missing')});
      return;
    }
    let startTime = this.state.selectedEventStartTimeValue;
    let endTime = this.state.selectedEventEndTimeValue;
    if (startTime > -1 && endTime > -1 && startTime > endTime) {
      // start time cannot larger than end time
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_info_start_time_larger_than_end_time')});
      return;
    }

    let startDate = this.state.eventStartDateValue;
    let endDate = this.state.eventEndDateValue;
    if (startDate && endDate && startDate > endDate) {
      // start date cannot larger than end date
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_info_start_date_larger_than_end_date')});
      return;
    }

    this.setState({activeStep: 3}, () => {
      window.scrollTo(0, 0);
    });
  }

  renderEventEndDate() {
    const {t} = this.props;
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker label={t('add_event_info_event_end_date')} style={styles.datePicker}
            variant="dialog" format="dd/MM/yyyy" margin="normal" clearable={true}
            clearLabel={t('general_clear')}
            value={this.state.eventEndDateValue}
            emptyLabel={t('general_input_please_select')}
            onChange={(value) => {
              this.setState({eventEndDateValue: value});
            }} />
        </MuiPickersUtilsProvider>
      </div>
    );
  }

  renderEventStartDate() {
    const {t} = this.props;
    return (
      <div>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker label={t('add_event_info_event_start_date')} style={styles.datePicker}
            variant="dialog" format="dd/MM/yyyy" margin="normal" clearable={true}
            clearLabel={t('general_clear')}
            value={this.state.eventStartDateValue}
            emptyLabel={t('general_input_please_select')}
            onChange={(value) => {
              this.setState({eventStartDateValue: value});
            }} />
        </MuiPickersUtilsProvider>
      </div>
    );
  }

  getEventTimeMenuItems(naTextRes) {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push((<MenuItem value={-1}>{t(naTextRes)}</MenuItem>));
    for (let i = 0; i < 49; i++) {
      let value = i * 0.5 * 60 * 60;
      let hours = (0.5 * i) + '';
      let display = hours.replace('.5', '') + (hours.indexOf('.5') > -1 ? ':30' : ':00');
      menuItems.push((
        <MenuItem key={i} value={value}>{display}</MenuItem>));
    }
    return menuItems;
  }

  renderEventEndTime() {
    const {t} = this.props;
    let menuItems = this.getEventTimeMenuItems('add_event_info_event_end_time_na');
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_event_end_time')}
        selectedValue={this.state.selectedEventEndTimeValue}
        onChange={(value) => {
          this.setState({
            selectedEventEndTimeValue: value
          });
        }}
      />
    );
  }

  renderEventStartTime() {
    const {t} = this.props;
    let menuItems = this.getEventTimeMenuItems('add_event_info_event_start_time_na');
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_event_start_time')}
        selectedValue={this.state.selectedEventStartTimeValue}
        onChange={(value) => {
          this.setState({
            selectedEventStartTimeValue: value
          });
        }}
      />
    );
  }

  getStayTimeMenuItems(naTextRes) {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push((<MenuItem value={-1}>{t(naTextRes)}</MenuItem>));
    for (let i = 1; i < 49; i++) {
      menuItems.push((
        <MenuItem value={i * 0.5 * 60 * 60}>
          {0.5 * i + ' ' + t((0.5 * i > 1.0) ? 'add_event_time_hours' : 'add_event_time_hour')}
        </MenuItem>));
    }
    return menuItems;
  }

  renderMaximumStayTime() {
    const {t} = this.props;
    let menuItems = this.getStayTimeMenuItems('add_event_info_max_stay_time_na');
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_max_stay_time')}
        selectedValue={this.state.selectedMaxStayTimeValue}
        onChange={(value) => {
          this.setState({
            selectedMaxStayTimeValue: value
          });
        }}
      />
    );
  }

  renderMinimumStayTime() {
    const {t} = this.props;
    let menuItems = this.getStayTimeMenuItems('add_event_info_min_stay_time_na');
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_min_stay_time')}
        selectedValue={this.state.selectedMinStayTimeValue}
        onChange={(value) => {
          this.setState({
            selectedMinStayTimeValue: value
          });
        }}
      />
    );
  }

  renderIncludeMealSelect() {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push(<MenuItem value={0}>{t('add_event_info_no_meal')}</MenuItem>);
    menuItems.push(<MenuItem value={1}>{t('add_event_info_meal_lunch')}</MenuItem>);
    menuItems.push(<MenuItem value={2}>{t('add_event_info_meal_dinner')}</MenuItem>);
    menuItems.push(<MenuItem value={3}>{t('add_event_info_meal_lunch_dinner')}</MenuItem>);
    menuItems.push(<MenuItem value={4}>{t('add_event_info_meal_breakfast')}</MenuItem>);
    menuItems.push(<MenuItem value={5}>{t('add_event_info_meal_breakfast_lunch')}</MenuItem>);
    menuItems.push(<MenuItem value={6}>{t('add_event_info_meal_breakfast_dinner')}</MenuItem>);
    menuItems.push(<MenuItem value={7}>{t('add_event_info_meal_breakfast_lunch_dinner')}</MenuItem>);
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_include_meal')}
        selectedValue={this.state.selectedIncludeMealValue}
        onChange={(value) => {
          this.setState({
            selectedIncludeMealValue: value
          });
        }}
      />
    );
  }

  renderIndoorSelect() {
    const {t} = this.props;
    let menuItems = [];
    menuItems.push(<MenuItem value={0}>{t('add_event_info_outdoor')}</MenuItem>);
    menuItems.push(<MenuItem value={1}>{t('add_event_info_indoor')}</MenuItem>);
    return (
      <FormSelect menuItems={menuItems}
        labelTitle={t('add_event_info_indoor_or_outdoor')}
        selectedValue={this.state.selectedIndoorValue}
        onChange={async (value) => {
          this.setState({
            selectedIndoorValue: value
          });
        }}
      />
    );
  }

  renderLocationProvince() {
    const {t} = this.props;
    return (
      <FormSelect selectParseData={this.state.provinceData}
        fieldName={'name'}
        labelTitle={t('add_event_location_province')}
        selectedValue={this.state.selectedProvinceValue}
        onChange={(value) => {
          this.setState({selectedProvinceValue: value});
        }}
      />
    );
  }

  renderLocationArea() {
    const {t} = this.props;
    return (
      <FormSelect selectParseData={this.state.areaData}
        fieldName={'name'}
        labelTitle={t('add_event_location_area')}
        selectedValue={this.state.selectedAreaValue}
        onChange={async (value) => {
          await this.setStateAsync({isShowLoadingDialog: true});
          let result = await ParseUtil.getProvinces(value);
          await this.setStateAsync({isShowLoadingDialog: false});
          this.setState({
            selectedAreaValue: value, provinceData: result,
            selectedProvinceValue: ''
          });
        }}
      />
    );
  }

  renderLocationCountry() {
    const {t} = this.props;
    return (
      <FormSelect selectParseData={this.state.countryData}
        fieldName={'name'}
        labelTitle={t('add_event_location_country')}
        selectedValue={this.state.selectedCountryValue}
        onChange={async (value) => {
          await this.setStateAsync({isShowLoadingDialog: true});
          let result = await ParseUtil.getAreas(value);
          await this.setStateAsync({isShowLoadingDialog: false});
          this.setState({
            selectedCountryValue: value, areaData: result,
            selectedProvinceValue: '', provinceData: null
          });
        }}
      />
    );
  }

  renderStepLocationForm() {
    const {t} = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" style={styles.form}>
          {this.renderLocationCountry()}
          <br /><br />
          {this.renderLocationArea()}
          <br /><br />
          {this.renderLocationProvince()}
          <br /><br /><br />
          <InputLabel>{t('add_event_start_location_address')}</InputLabel>
          <br /><br />
          {this.renderStartLocationAddressTextFields()}
          <br />
          {this.renderStartAddressLatLng()}
          <br /><br />
          <hr />
          <br /><br />
          {this.renderEndLocationAddressTitle()}
          <br />
          {this.renderEndLocationFields()}
          <br />
          <div style={styles.submitBtnsContainer}>
            {this.renderSubmitButton(t('add_event_prev_step'), this.backToPrevStep.bind(this), (<ArrowLeft />))}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.renderSubmitButton(t('add_event_next_step'), this.submitLocationForm.bind(this))}
          </div>
        </form>
      </div>
    );
  }

  backToPrevStep() {
    this.setState({activeStep: this.state.activeStep - 1}, () => {
      window.scrollTo(0, 0);
    });
  }

  submitLocationForm() {
    const {t} = this.props;
    /*
    // check start address values
    if (!this.validateAddress('startAddress', this.state.startLocationAddressValues)) {
      return;
    }
    // check end address values
    if (!this.state.isEndAddressSameAsStart &&
      !this.validateAddress('endAddress', this.state.endLocationAddressValues)) {
      return;
    }
    */
    // check start lat lng
    if (!this.state.startAddressLat || !this.state.startAddressLng) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_address_missing_lat_lng')});
      return;
    }
    // check end lat lng
    if (!this.state.isEndAddressSameAsStart && (!this.state.endAddressLat || !this.state.endAddressLng)) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_address_missing_lat_lng')});
      return;
    }
    // check province
    if (!this.state.selectedProvinceValue) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_location_missing_province')});
      return;
    }
    this.setState({activeStep: 2}, () => {
      window.scrollTo(0, 0);
    });
  }

  validateAddress(fieldName, addressValues) {
    const {t} = this.props;
    let isMissingName = false;
    let isNameTooShort = false;
    for (let i = 0; i < LANG_CODES.length; i++) {
      let eventNameKey = 'eventName' + LANG_CODES[i];
      let eventNameValue = this.state.nameValues[eventNameKey];
      if (eventNameValue && eventNameValue.length > 0) {
        // name exist, can have location address for this language
        let key = fieldName + LANG_CODES[i];
        let value = addressValues[key];
        let address = value ? value.trim() : null;
        if (!address || address.length <= 0) {
          isMissingName = true;
          break;
        } else if (address.length < 5) {
          isNameTooShort = true;
          break;
        }
      }
    }
    if (isMissingName) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_address_missing_name')});
      return;
    }
    if (isNameTooShort) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_address_too_short')});
      return;
    }
    return true;
  }

  renderEndLocationFields() {
    if (this.state.isEndAddressSameAsStart) {
      return;
    }
    return (
      <div>
        <br />
        {this.renderEndLocationAddressTextFields()}
        <br />
        {this.renderEndAddressLatLng()}
        <br />
      </div>
    );
  }

  renderEndLocationAddressTitle() {
    const {t} = this.props;
    return (
      <div style={styles.endLocationTitleContainer}>
        <InputLabel>{t('add_event_end_location_address')}</InputLabel>
        <div>
          <Checkbox
            checked={this.state.isEndAddressSameAsStart}
            onChange={() => {
              this.setState({isEndAddressSameAsStart: !this.state.isEndAddressSameAsStart});
            }}
            value="primary"
            inputProps={{'aria-label': 'primary checkbox'}}
          />
          <span>{t('add_event_end_location_same')}</span>
        </div>
      </div>
    );
  }

  getAddressLatLngAutoMenuItems(addressValues) {
    let menuItem = [];
    let keys = Object.keys(addressValues);
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let value = addressValues[key];
      if (!value || value.length <= 0) {
        continue;
      }
      menuItem.push(
        <MenuItem value={value}>{value}</MenuItem>
      );
    }
    return menuItem;
  }

  renderAddressLatLngAutoSelect(addressValues, addressLatState, addressLngState) {
    const {t} = this.props;
    return (
      <FormSelect
        selectId="add_event_address_lat_lng_auto_select"
        menuItems={this.getAddressLatLngAutoMenuItems(addressValues)}
        labelTitle={t('add_event_address_lat_lng_auto_get')}
        onChange={async (value) => {
          let latLng = await this.getLatLngFromAddress(value);
          if (latLng) {
            this.setState({[addressLatState]: latLng.lat, [addressLngState]: latLng.lng});
          }
        }}
      />
    );
  }

  renderStartAddressLatLng() {
    const {t} = this.props;
    let select = this.renderAddressLatLngAutoSelect(this.state.startLocationAddressValues,
      'startAddressLat', 'startAddressLng');
    return (
      <div>
        <InputLabel>{t('add_event_start_location_address_lat_lng')}</InputLabel>
        <br />
        {select}
        <br />
        <br />
        {this.renderGoogleMaps(this.state.startAddressLat, this.state.startAddressLng)}
        <br />
        {
          this.renderLatLngTextField(this.state.startAddressLat, this.state.startAddressLng,
            'add_event_location_latitude', 'add_event_location_longitude',
            'startAddressLat', 'startAddressLng')
        }
      </div>
    );
  }

  renderEndAddressLatLng() {
    const {t} = this.props;
    let select = this.renderAddressLatLngAutoSelect(this.state.endLocationAddressValues,
      'endAddressLat', 'endAddressLng');
    return (
      <div>
        <InputLabel>{t('add_event_end_location_address_lat_lng')}</InputLabel>
        <br />
        {select}
        <br />
        <br />
        {this.renderGoogleMaps(this.state.endAddressLat, this.state.endAddressLng)}
        <br />
        {
          this.renderLatLngTextField(this.state.endAddressLat, this.state.endAddressLng,
            'add_event_location_latitude', 'add_event_location_longitude',
            'endAddressLat', 'endAddressLng')
        }
      </div>
    );
  }

  renderLatLngTextField(latValue, lngValue, latTextRes, lngTextRes, latState, lngState) {
    const {t} = this.props;
    return (
      <div style={styles.latLngFieldContainer}>
        <TextField style={styles.inputField} id={latTextRes + latState}
          InputLabelProps={{shrink: true}} variant="outlined"
          label={t(latTextRes)}
          type='number'
          value={latValue}
          onChange={event => {
            this.setState({[latState]: event.target.value});
          }} />
        <span>&nbsp;&nbsp;&nbsp;</span>
        <TextField style={styles.inputField} id={lngTextRes + lngState}
          InputLabelProps={{shrink: true}} variant="outlined"
          label={t(lngTextRes)}
          type='number'
          value={lngValue}
          onChange={event => {
            this.setState({[lngState]: event.target.value});
          }} />
      </div>
    );
  }

  renderGoogleMaps(addressLat, addressLng) {
    let lat = 22.396428;
    let lng = 114.109497;

    if (addressLat && addressLng) {
      lat = addressLat;
      lng = addressLng;
    }
    let src = 'https://www.google.com/maps/embed/v1/place?q=' + lat + '%2C' + lng +
      '&key=AIzaSyDMNoNm0VKMp9LI-hrBOQEFbhTILN2QM1M';
    return (
      <div>
        <iframe width="100%" height="300" title="googleMaps" frameBorder="0" src={src}></iframe>
      </div>
    );
  }

  async getLatLngFromAddress(address) {
    try {
      return await MapsUtil.getGoogleLatLng(address);
    } catch (e) {
      AppLog.log(e);
    }
  }

  renderStartLocationAddressTextFields() {
    return this.renderLocationAddressTextFields('startAddress', this.state.startLocationAddressValues,
      'startLocationAddressValues', 'add_event_start_location_address');
  }

  renderEndLocationAddressTextFields() {
    return this.renderLocationAddressTextFields('endAddress', this.state.endLocationAddressValues,
      'endLocationAddressValues', 'add_event_end_location_address');
  }

  renderLocationAddressTextFields(fieldName, addressValues, addressState, labelTextRes) {
    const {t} = this.props;
    let nameTextFields = [];
    for (let i = 0; i < LANG_CODES.length; i++) {
      let eventNameKey = 'eventName' + LANG_CODES[i];
      let eventNameValue = this.state.nameValues[eventNameKey];
      if (eventNameValue && eventNameValue.length > 0) {
        // name exist, can have location address for this language
        let key = fieldName + LANG_CODES[i];
        nameTextFields.push(
          <TextField style={styles.inputField} id={key}
            InputLabelProps={{shrink: true}} variant="outlined"
            value={addressValues[key] || ''}
            onChange={event => {
              let values = addressValues;
              values[key] = event.target.value;
              this.setState({[addressState]: values});
            }}
            label={t(labelTextRes) + ' (' + t(LANG_CODES_DESC[i]) + ')'} />
        );
        nameTextFields.push(<br />);
        nameTextFields.push(<br />);
      }
    }
    return nameTextFields;
  }

  renderStepNameForm() {
    const {t} = this.props;
    return (
      <div>
        <form noValidate autoComplete="off" style={styles.form}>
          {this.renderCheckName()}
          <br />
          <InputLabel>{t('add_event_name_title')}</InputLabel>
          <br />
          <br />
          {this.renderNameTextFields()}
          <br />
          {this.renderSubmitButton(t('add_event_next_step'), this.submitNameForm.bind(this))}
        </form>
        {this.renderSimilarEventsDialog()}
        {this.renderSimilarEventsDialogForCheck()}
        <GeneralDialog isShowDialog={this.state.isShowCheckNameSuccessDialog}
          dialogContent={t('add_event_no_similar_event_dialog_desc')}
          dialogTitle={t('add_event_no_similar_event_dialog_title')}
          onClick={() => {
            this.setState({isShowCheckNameSuccessDialog: false});
          }} />
      </div>
    );
  }

  renderCheckName() {
    const {t} = this.props;
    return (
      <div>
        <InputLabel>{t('add_event_check_exist_title')}</InputLabel>
        <br />
        <TextField key={'checkName'}
          style={styles.inputField} id={'checkName'}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.checkName}
          onChange={event => {
            this.setState({checkName: event.target.value});
          }}
          label={t('add_event_check_exist_name')} />
        <br /><br />
        {this.renderSubmitButton(t('add_event_check_button'), async () => {
          let checkName = this.state.checkName;
          checkName = checkName ? checkName.trim() : null;
          if (!checkName) {
            return;
          }
          let checkValues = {};
          for (let i = 0; i < LANG_CODES.length; i++) {
            let key = 'eventName' + LANG_CODES[i];
            checkValues[key] = checkName;
          }
          this.setState({isShowLoadingDialog: true});
          let similarEvents = await ParseUtil.getEventsForNames(checkValues, this.editEventId);
          this.setState({isShowLoadingDialog: false});
          if (similarEvents && similarEvents.length > 0) {
            this.setState({similarEvents: similarEvents, isShowCheckNameFailDialog: true});
          } else {
            this.setState({isShowCheckNameSuccessDialog: true});
          }
        })}
        <br /><br /><br />
      </div>
    );
  }

  renderSimilarEventsDialogForCheck() {
    if (!this.state.similarEvents || !this.state.isShowCheckNameFailDialog) {
      return;
    }
    return (
      <AddEventSimilarEventsDialog
        similarEvents={this.state.similarEvents}
        isShowSimilarEventsDialog={this.state.isShowCheckNameFailDialog}
        showOkOnly={true}
        onOk={() => {
          this.setState({isShowCheckNameFailDialog: false});
        }}
      />
    );
  }

  renderSimilarEventsDialog() {
    if (!this.state.similarEvents || this.state.isShowCheckNameFailDialog) {
      return;
    }
    return (
      <AddEventSimilarEventsDialog
        similarEvents={this.state.similarEvents}
        isShowSimilarEventsDialog={this.state.isShowSimilarEventsDialog}
        onCancel={() => {
          this.setState({isShowSimilarEventsDialog: false});
        }}
        onContinue={() => {
          this.setState({isShowSimilarEventsDialog: false, activeStep: 1}, () => {
            window.scrollTo(0, 0);
          });
        }}
      />
    );
  }

  renderNameTextFields() {
    const {t} = this.props;
    let nameTextFields = [];
    for (let i = 0; i < LANG_CODES.length; i++) {
      let key = 'eventName' + LANG_CODES[i];
      nameTextFields.push(
        <TextField key={'name_' + i}
          style={styles.inputField} id={key}
          InputLabelProps={{shrink: true}} variant="outlined"
          value={this.state.nameValues[key]}
          onChange={event => {
            let values = this.state.nameValues;
            values[key] = event.target.value;
            this.setState({nameValues: values});
          }}
          label={t('add_event_name') + ' (' + t(LANG_CODES_DESC[i]) + ')'} />
      );
      nameTextFields.push(<br key={'name_br_1_' + i} />);
      nameTextFields.push(<br key={'name_br_2_' + i} />);
    }
    return nameTextFields;
  }

  async submitNameForm() {
    const {t} = this.props;
    // check name values
    let keys = Object.keys(this.state.nameValues);
    let isMissingName = true;
    let isNameTooShort4 = false;
    let isNameTooShort2 = false;
    if (keys && keys.length > 0) {
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i];
        let value = this.state.nameValues[key].trim();
        if (value) {
          isMissingName = false;
        }
        // check name length
        if (key.indexOf('Zh') === -1 && key.indexOf('Ja') === -1 && key.indexOf('Ko') === -1) {
          // other language name < 4
          if (value.length > 0 && value.length < 4) {
            isNameTooShort4 = true;
            isMissingName = false;
            break;
          }
        } else if (value.length > 0 && value.length < 2) {
          // chinese japanese korean name < 2
          isNameTooShort2 = true;
          isMissingName = false;
          break;
        }
      }
    }
    if (isMissingName) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_name_title')});
      return;
    }
    if (isNameTooShort4) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_name_too_short')});
      return;
    }
    if (isNameTooShort2) {
      this.setState({isShowErrorDialog: true, errorDialogContent: t('add_event_name_too_short')});
      return;
    }

    // search if have similar result
    this.setState({isShowLoadingDialog: true});
    let similarEvents = await ParseUtil.getEventsForNames(this.state.nameValues, this.editEventId);
    this.setState({isShowLoadingDialog: false});
    if (similarEvents && similarEvents.length > 0) {
      this.setState({similarEvents: similarEvents, isShowSimilarEventsDialog: true});
      return;
    }
    this.setState({activeStep: 1}, () => {
      window.scrollTo(0, 0);
    });
  }

  renderSubmitButton(text, submitFunction, icon) {
    if (!icon) {
      icon = (<Check />);
    }
    return (
      <Button
        style={styles.submitBtn}
        variant="contained"
        color="primary"
        onClick={submitFunction}>
        <div style={styles.submitBtnContent}>
          {icon}
          {text}
        </div>
      </Button>
    );
  }

  async addOrUpdateEventToServer() {
    await this.setStateAsync({isShowLoadingDialog: true});

    let eventInfo = {};
    // images
    if (this.state.selectedPhotos && this.state.selectedPhotos.length > 0) {
      eventInfo.base64Images = await ImageUtil.resizeImages(this.state.selectedPhotos);
    }
    eventInfo.editPhotos = this.state.selectedEditPhotos;
    // name info
    eventInfo.eventNames = this.state.nameValues;
    // location info
    let isCopyStartInfo = false;
    if (this.state.isEndAddressSameAsStart) {
      isCopyStartInfo = true;
    }
    eventInfo.provinceId = this.state.selectedProvinceValue;
    eventInfo.startAddressValues = this.state.startLocationAddressValues;
    eventInfo.startAddressLat = this.state.startAddressLat;
    eventInfo.startAddressLng = this.state.startAddressLng;
    let endAddressValues = this.state.endLocationAddressValues;
    if (isCopyStartInfo) {
      endAddressValues = [];
      let keys = Object.keys(eventInfo.startAddressValues);
      keys.forEach((item, index, array) => {
        let key = item.replace('start', 'end');
        endAddressValues[key] = eventInfo.startAddressValues[item];
      });
    }
    eventInfo.endAddressValues = endAddressValues;
    eventInfo.endAddressLat = isCopyStartInfo ? eventInfo.startAddressLat : this.state.endAddressLat;
    eventInfo.endAddressLng = isCopyStartInfo ? eventInfo.startAddressLng : this.state.endAddressLng;
    // date info
    eventInfo.startDate = this.state.eventStartDateValue ? this.state.eventStartDateValue : new Date('December 11, 1988 00:00:00');
    eventInfo.endDate = this.state.eventEndDateValue ? this.state.eventEndDateValue : new Date('December 11, 2199 00:00:00');
    eventInfo.minStayTime = this.state.selectedMinStayTimeValue;
    eventInfo.maxStayTime = this.state.selectedMaxStayTimeValue;
    eventInfo.startTime = this.state.selectedEventStartTimeValue;
    eventInfo.endTime = this.state.selectedEventEndTimeValue;
    // content info
    eventInfo.indoor = this.state.selectedIndoorValue;
    eventInfo.includeMeal = this.state.selectedIncludeMealValue;
    eventInfo.categoryIds = this.state.selectedCateValue;
    eventInfo.stamina = this.state.selectedStaminaValue;
    eventInfo.season = this.state.selectedSeasonValue;
    eventInfo.wiki = this.state.wikiLinkValue?.trim();
    eventInfo.instagramPost = this.state.instagramPostLinkValue?.trim();
    // event desc field
    eventInfo.eventDescValues = this.state.eventDescValues;
    eventInfo.eventLinks = this.state.linksValues.filter(value => {
      let title = value?.title?.trim();
      return title !== undefined && title.length > 0;
    }).map((value) => {
      return {title: value.title.trim(), link: value.link.trim()};
    });

    // save event
    let parseEventObject;
    try {
      parseEventObject = await ParseUtil.saveEvent(eventInfo, this.editEventId, this.isEditPendingEvent);
    } catch (e) {
      AppLog.log(e);
    }
    if (parseEventObject) {
      // success
      this.setState({isShowLoadingDialog: false, isShowSuccessDialog: true});
    } else {
      // failed
      this.setState({
        isShowLoadingDialog: false,
        isShowErrorDialog: true,
        errorDialogContent: this.props.t('add_event_save_fail')
      });
    }
  }

}

const styles = {
  appBar: {
    color: '#ffffff'
  },
  rootContainer: {
    minHeight: '100vh',
    backgroundImage: `url(${BG_IMAGE})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 800,
    width: '100%',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff'
  },
  stepper: {
    backgroundColor: '#00000000',
  },
  form: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
    marginBottom: 30
  },
  inputField: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  datePicker: {
    width: '100%'
  },
  submitBtn: {
    width: '100%'
  },
  submitBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  latLngFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  endLocationTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dropZoneContainer: {
    height: 80,
    backgroundColor: '#e9e9e9',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    display: 'flex',
    borderWidth: 4,
    borderColor: '#c3c3c3',
    borderStyle: 'dashed'
  },
  eventPhotoContainer: {
    padding: 12,
    borderWidth: 1,
    borderColor: '#e2e2e2',
    borderStyle: 'solid'
  },
  addedFilesTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  clearPhotoBtn: {
    alignSelf: 'flex-end',
    justifySelf: 'flex-end',
    justifyContent: 'flex-end',
    backgroundColor: '#ff0000'
  },
  clearPhotoBtnContent: {
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'row',
  },
  submitBtnsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  }
};

export default withTranslation()(AddEventScreen);